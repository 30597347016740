import React from "react";

const DotIcon = ({ width, color }) => {
  return (
    <svg
      width={width}
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.15723" cy="5.31592" r="5.27148" fill={color} />
    </svg>
  );
};

export default DotIcon;
