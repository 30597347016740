import React from "react";
import { Col, Row } from "antd";
import ClientImage from "../../assets/img/forClient.webp";

const ForClient = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2 bg-[#E0EFE2] rounded-lg overflow-hidden md:pl-8">
        <Row className="w-full pl-2">
          <Col
            span={24}
            md={{ span: 10 }}
            className="w-full flex flex-col items-start justify-center gap-3 z-10 pb-10 md:py-0"
          >
            <div className="bg-primaryDark rounded-lg mb-2 lg:mb-5 mt-10">
              <p className="text-xl md:text-[24px] font-normal text-white px-2">
                For Client
              </p>
            </div>

            <h1 className="title-level2 custom-font md:w-[500px] max-w-[500px]">
              Get fast Help,
              <br />
              The way you want it!
            </h1>

            <div className="border-b-[2px] border-black w-60 mt-2"></div>

            <p className="text-lg md:text-xl font-normal mt-5 w-full md:w-[500px] max-w-[500px] pb-5 md:pb-10">
              JobsNinja makes finding a worker the fastest by direct contact,
              finding workers nearby and quick dial favourites. You can even
              find workers who speak your native language!
            </p>
          </Col>

          <Col
            span={24}
            md={{ span: 14 }}
            className="flex items-center justify-center md:items-end md:justify-end w-full"
          >
            <div className="w-full lg:max-w-[590px]">
              <img src={ClientImage} alt="client" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForClient;
