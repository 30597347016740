import React from "react";

const SearchButton = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="34" height="34" rx="6" fill="#2C8B52" />
      <path
        d="M23.7487 23.3837L20.0365 19.5476C20.8059 18.5674 21.2233 17.357 21.2218 16.1109C21.2218 15.0012 20.8927 13.9164 20.2762 12.9936C19.6596 12.0709 18.7833 11.3518 17.7581 10.9271C16.7328 10.5024 15.6047 10.3913 14.5163 10.6078C13.4279 10.8243 12.4281 11.3587 11.6434 12.1434C10.8587 12.9281 10.3243 13.9279 10.1078 15.0163C9.89132 16.1047 10.0024 17.2328 10.4271 18.2581C10.8518 19.2833 11.5709 20.1596 12.4936 20.7762C13.4164 21.3927 14.5012 21.7218 15.6109 21.7218C16.857 21.7233 18.0674 21.3059 19.0476 20.5365L22.7528 24.3797C22.818 24.4454 22.8956 24.4976 22.981 24.5332C23.0665 24.5688 23.1582 24.5871 23.2507 24.5871C23.3433 24.5871 23.435 24.5688 23.5205 24.5332C23.6059 24.4976 23.6835 24.4454 23.7487 24.3797C23.8145 24.3145 23.8666 24.2369 23.9022 24.1514C23.9378 24.0659 23.9562 23.9743 23.9562 23.8817C23.9562 23.7891 23.9378 23.6974 23.9022 23.612C23.8666 23.5265 23.8145 23.4489 23.7487 23.3837ZM11.4027 16.1109C11.4027 15.2786 11.6495 14.465 12.1119 13.773C12.5743 13.0809 13.2316 12.5416 14.0005 12.2231C14.7694 11.9045 15.6156 11.8212 16.4319 11.9836C17.2482 12.146 17.998 12.5467 18.5865 13.1353C19.175 13.7238 19.5758 14.4736 19.7382 15.2899C19.9006 16.1062 19.8172 16.9523 19.4987 17.7213C19.1802 18.4902 18.6409 19.1475 17.9488 19.6099C17.2568 20.0723 16.4432 20.3191 15.6109 20.3191C14.4948 20.3191 13.4245 19.8757 12.6353 19.0865C11.8461 18.2973 11.4027 17.227 11.4027 16.1109Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchButton;
