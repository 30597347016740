import React from "react";
import PlayStore from "../../assets/img/playStore.png";
import AppleStore from "../../assets/img/appleStore.png";
import GreenLogo from "../../assets/svg/GreenLogo";
import GreenPhone from "../../assets/img/greenPhone.webp";
import YellowPhone from "../../assets/img/yellowPhone.webp";
import DotIcon from "../../assets/svg/DotIcon";
import YellowLogo from "../../assets/svg/YellowLogo";
import { useNavigate } from "react-router-dom";

const PowerAt = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full justify-center items-center mt-16 md:mt-24">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2">
        <h1 className="title-level2 text-center">Power at your fingertips</h1>
        <p className="text-base font-normal text-center pt-10">
          Our apps will change how you get work done and how you work.
        </p>

        <div className="flex justify-center items-center py-10">
          <div className="flex flex-row gap-2">
            <img
              src={PlayStore}
              className="w-[130px] sm:w-[160px] md:w-[193px]"
            />
            <img
              src={AppleStore}
              className="w-[130px] sm:w-[160px] md:w-[193px]"
            />
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col md:flex-row gap-7">
            <div
              className="relative shadow-lg bg-gradient-to-tl from-transparent to-gray-100 rounded-lg md:max-w-[350px] xl:max-w-[400px] overflow-hidden cursor-pointer"
              onClick={() => {
                navigate("../client");
              }}
            >
              <div className="absolute -left-56 -top-32 bg-primaryLightest rounded-full w-[400px] h-[400px] z-0"></div>
              <div className="absolute -left-44 -top-24 bg-[#a7e7c1] rounded-full w-[300px] h-[300px] z-0"></div>

              <div className="relative z-10 flex flex-col justify-center items-center py-5 md:p-10">
                <GreenLogo />
                <h1 className="text-[24px] font-semibold">To get jobs done</h1>

                <div className="py-5">
                  <img src={GreenPhone} className="w-[167px] h-[335px]" />
                </div>

                <p className="w-[300px] text-center text-base font-normal leading-[20px]">
                  The fastest, easiest way to get work done. Find the best
                  talent around where you live or further.
                </p>

                <div className="pt-5 text-base font-semibold">
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#40D37B" />
                    <p>Save favourite workers </p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#40D37B" />
                    <p>View Detailed Profiles</p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#40D37B" />
                    <p>In-app Chat or Direct Call</p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#40D37B" />
                    <p>AI voice search</p>
                  </div>
                </div>

                <div className="flex flex-col gap-2 pt-5">
                  <button
                    className="bg-thirdColor text-white rounded-2xl"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en",
                        "_blank"
                      );
                    }}
                  >
                    <p className="px-5 py-1">
                      Get the <span className="font-bold">Android</span> app
                    </p>
                  </button>

                  <button
                    className="bg-thirdColor text-white rounded-2xl"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://apps.apple.com/app/jobsninja/id6451492483",
                        "_blank"
                      );
                    }}
                  >
                    <p className="px-5 py-1">
                      Get the <span className="font-bold">iOS</span> app
                    </p>
                  </button>
                </div>
              </div>
            </div>

            <div
              className="relative shadow-lg bg-gradient-to-tl from-transparent to-gray-100 rounded-lg  max-w-[350px] xl:max-w-[400px] overflow-hidden cursor-pointer"
              onClick={() => {
                navigate("../worker");
              }}
            >
              <div className="absolute -right-56 -top-32 bg-[#fcebd1] rounded-full w-[400px] h-[400px] z-0"></div>
              <div className="absolute -right-44 -top-24 bg-[#f9dcb0] rounded-full w-[300px] h-[300px] z-0"></div>

              <div className="relative z-10 flex flex-col justify-center items-center py-5 md:p-10">
                <div className="my-4">
                  <YellowLogo />
                </div>
                <h1 className="text-[24px] font-semibold">To find work</h1>

                <div className="py-5">
                  <img src={YellowPhone} className="w-[167px] h-[335px]" />
                </div>

                <p className="w-[300px] text-center text-base font-normal leading-[20px]">
                  Find the work when you are ready to work, either closer to you
                  or anywhere in the world!
                </p>

                <div className="pt-5 text-base font-semibold">
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#F7BA5D" />
                    <p>Lead management</p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#F7BA5D" />
                    <p>Sharable Profile</p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#F7BA5D" />
                    <p>Job Calendar</p>
                  </div>

                  <div className="flex flex-row gap-2 justify-center items-center">
                    <DotIcon width="12" color="#F7BA5D" />
                    <p>Build Clients</p>
                  </div>
                </div>

                <div className="flex flex-col gap-2 pt-5">
                  <button
                    className="bg-thirdColor text-white rounded-2xl"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://play.google.com/store/apps/details?id=au.jobninja.trady&hl=en",
                        "_blank"
                      );
                    }}
                  >
                    <p className="px-5 py-1">
                      Get the <span className="font-bold">Android</span> app
                    </p>
                  </button>

                  <button
                    className="bg-thirdColor text-white rounded-2xl"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://apps.apple.com/app/jobsninja-work/id6451492994",
                        "_blank"
                      );
                    }}
                  >
                    <p className="px-5 py-1">
                      Get the <span className="font-bold">iOS</span> app
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerAt;
