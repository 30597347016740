import axios from "axios";
import { createContext, useState } from "react";

export const SubscriptionContext = createContext({});

export function SubscriptionContextProvider({ children }) {
  const [subscriptionSteps, setSubscriptionSteps] = useState(0);

  const [currentPackageDetails, setCurrentPackageDetails] = useState();

  const [nextPackageDetails, setNextPackageDetails] = useState();

  const [changedBillingAddres, setChangedBillingAddress] = useState();

  const [paymentSucessStatus, setPaymentSuccessStatus] = useState("status");

  const [openClosePaymentSubscripDrawer, setOpenClosePaymentSubscripDrawer] =
    useState(false);

  const handleOpenClosePaymentSubscripDrawer = () => {
    setOpenClosePaymentSubscripDrawer(false);
  };

  const [openCloseChangeBillingAddress, setOpenCloseChangeBillingAddress] =
    useState(false);

  const handleOpenCloseChangeBillingAddress = () => {
    setOpenCloseChangeBillingAddress(false);
  };

  const [userLocation, setUserLocation] = useState("lk");

  const fetchCountryCode = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json/");
      if (data && data.country_code) {
        setUserLocation(data.country_code.toLowerCase());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionSteps,
        setSubscriptionSteps,

        currentPackageDetails,
        setCurrentPackageDetails,

        paymentSucessStatus,
        setPaymentSuccessStatus,

        nextPackageDetails,
        setNextPackageDetails,

        changedBillingAddres,
        setChangedBillingAddress,

        openClosePaymentSubscripDrawer,
        setOpenClosePaymentSubscripDrawer,
        handleOpenClosePaymentSubscripDrawer,

        openCloseChangeBillingAddress,
        setOpenCloseChangeBillingAddress,
        handleOpenCloseChangeBillingAddress,

        userLocation,
        setUserLocation,
        fetchCountryCode,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
