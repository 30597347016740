import React from "react";

const CountryIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66669 9.26211C4.66669 5.9961 7.27003 3.33325 10.5 3.33325C13.73 3.33325 16.3334 5.9961 16.3334 9.26211C16.3334 10.8388 15.884 12.5319 15.0897 13.9945C14.2963 15.4553 13.1371 16.7247 11.687 17.4025C10.9337 17.7546 10.0663 17.7546 9.31301 17.4025C7.86293 16.7247 6.70377 15.4553 5.91039 13.9945C5.11604 12.5319 4.66669 10.8388 4.66669 9.26211ZM10.5 4.33325C7.83898 4.33325 5.66669 6.53157 5.66669 9.26211C5.66669 10.6602 6.06844 12.1902 6.78916 13.5172C7.51085 14.8461 8.5307 15.933 9.73645 16.4966C10.2214 16.7233 10.7787 16.7233 11.2636 16.4966C12.4693 15.933 13.4892 14.8461 14.2109 13.5172C14.9316 12.1902 15.3334 10.6602 15.3334 9.26211C15.3334 6.53157 13.1611 4.33325 10.5 4.33325ZM10.5 7.66659C9.67159 7.66659 9.00002 8.33816 9.00002 9.16659C9.00002 9.99501 9.67159 10.6666 10.5 10.6666C11.3284 10.6666 12 9.99501 12 9.16659C12 8.33816 11.3284 7.66659 10.5 7.66659ZM8.00002 9.16659C8.00002 7.78587 9.11931 6.66659 10.5 6.66659C11.8807 6.66659 13 7.78587 13 9.16659C13 10.5473 11.8807 11.6666 10.5 11.6666C9.11931 11.6666 8.00002 10.5473 8.00002 9.16659Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default CountryIcon;
