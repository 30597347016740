import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import { Col, Divider, Row, Spin } from "antd";
import VerifyIcon from "../assets/svg/VerifyIcon";
import LocationIconFull from "../assets/svg/LocationIconFull";
import LanguageFullIcon from "../assets/svg/LanguageFullIcon";
import ViewsIcon from "../assets/svg/ViewsIcon";
import CompleteIcon from "../assets/svg/CompleteIcon";
import HintIcon from "../assets/svg/HintIcon";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import { decryptSecureData } from "../helpers/encryptHelpers/encryption";
import { getDateDifference } from "../utils/dateDifference";
import UserColorProfile from "../components/userColorProfile/UserColorProfile";
import WorkSampleImageList from "../components/workSampleImageList/WorkSampleImageList";
import HeaderConfig from "../helpers/HeaderConfig";
import LoginServices from "../services/LoginServices";

const MyProfile = () => {
  let { currentUserData, setCurrentUserData } = useContext(AuthContext);
  let { openNotification, handleError } = useContext(NotificationContext);
  const { configAuth } = HeaderConfig();
  const { getAllAuthUserData } = LoginServices();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAuthUserData();
    window.scrollTo(0, 0);
  }, []);

  const getAuthUserData = async () => {
    setLoading(true);
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setCurrentUserData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <NavBar />

      {loading || currentUserData === undefined ? (
        <div className="mt-80 flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center items-center mt-28 mb-28">
          <p className="bg-primaryLightest text-primaryColor flex flex-row justify-center items-center gap-2 p-1 rounded-xl text-xxs md:text-xs font-medium mb-4">
            <HintIcon />
            You can view your public profile here. You must log in to the mobile
            app if you need to update or edit your public profile.
          </p>

          <div className="max-w-[1073px] xl:w-[1073px] mx-2 w-full">
            <div className="w-full admin-page">
              <div className="px-1 md:px-5">
                <div className="flex flex-col md:flex-row gap-5 justify-start w-full">
                  <div className="w-full md:w-[30%]">
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                        {currentUserData?.profile_picture === null ? (
                          <UserColorProfile
                            name={
                              decryptSecureData(currentUserData?.first_name) ||
                              "N Z"
                            }
                            color={"#3bb96e"}
                            size="100px"
                            textSize="40px"
                          />
                        ) : (
                          <img
                            src={currentUserData?.profile_picture}
                            alt=""
                            className="flex w-[100px] h-[100px] rounded-full border-[2px]"
                          />
                        )}
                      </div>

                      {currentUserData?.has_verified === 1 && (
                        <div className="hidden md:flex flex-row gap-3 justify-start items-center pt-2">
                          <VerifyIcon />
                          <p className="text-sm font-medium text-textColorTwo">
                            Verified Worker
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row gap-3">
                        <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                          {decryptSecureData(currentUserData?.first_name)}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="text-xs font-normal text-textColorTwo">
                        {currentUserData?.work_title}
                      </p>
                      <div className="flex flex-row">
                        <LocationIconFull />
                        <p className="text-xs font-semibold text-textColorOne">
                          {currentUserData?.state}
                          {", "} {currentUserData?.suberb}
                          {". "}
                          {currentUserData?.postal_code}
                        </p>
                      </div>

                      {currentUserData?.language && (
                        <div className="flex flex-row">
                          <LanguageFullIcon />
                          <p className="text-xs font-semibold text-textColorOne">
                            {currentUserData?.language}
                          </p>
                        </div>
                      )}
                    </div>

                    <Row className="flex flex-row gap-3 pt-3">
                      <Col className=" md:hidden flex flex-row gap-1 justify-start items-center">
                        <VerifyIcon />
                        <p className="text-sm font-medium text-textColorTwo">
                          Verified Worker
                        </p>
                      </Col>

                      {(currentUserData?.rate < 0 ||
                        currentUserData?.rate === null ||
                        currentUserData?.rate === undefined) &&
                        (currentUserData?.review_count < 0 ||
                          currentUserData?.review_count === null ||
                          currentUserData?.review_count === undefined) && (
                          <Col className="flex flex-row gap-1 justify-start items-center">
                            <ViewsIcon />
                            <p className="text-sm font-medium text-textColorTwo">
                              <span className="text-sm font-bold text-textColorOne">
                                {" "}
                                {currentUserData?.rate}
                              </span>{" "}
                              ({currentUserData?.review_count} Reviews)
                            </p>
                          </Col>
                        )}

                      {(currentUserData?.complete_job_count < 0 ||
                        currentUserData?.complete_job_count === null ||
                        currentUserData?.complete_job_count === undefined) &&
                        (currentUserData?.review_count < 0 ||
                          currentUserData?.review_count === null ||
                          currentUserData?.review_count === undefined) && (
                          <Col className="flex flex-row gap-1 justify-start items-center">
                            <CompleteIcon />
                            <p className="text-sm font-medium text-textColorTwo">
                              <span className="text-sm font-bold text-textColorOne">
                                {" "}
                                {currentUserData?.complete_job_count}+
                              </span>{" "}
                              Completed Tasks
                            </p>
                          </Col>
                        )}
                    </Row>

                    <Row className="flex flex-row gap-1 mt-2">
                      {currentUserData?.job_types?.map((item, index) => {
                        return (
                          <Col key={index}>
                            <p className="border-[1px] border-gray-300 py-1 px-2 rounded-full text-xs font-normal text-primaryColor">
                              {item?.job_type}
                            </p>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>

                <Divider className="my-3 bg-textColorTwo" />

                <div>
                  <p className="text-sm font-bold text-textColorOne">
                    Skills & Experience
                  </p>

                  {currentUserData?.work_description === null ||
                  currentUserData?.work_description === "" ? (
                    <p className="text-sm font-bold text-textColorTwo">_</p>
                  ) : (
                    <p className="text-xs font-normal text-textColorTwo">
                      {currentUserData?.work_description}
                    </p>
                  )}
                </div>

                <Divider className="my-3" />

                {currentUserData?.portfolios?.length !== 0 && (
                  <div className="flex justify-center items-center">
                    <WorkSampleImageList
                      workSamples={currentUserData?.portfolios.map(
                        (item) => item?.image
                      )}
                      listSize="large"
                    />
                  </div>
                )}

                <div className="mt-5">
                  <p className="text-sm font-bold text-textColorOne">
                    Reviews ({currentUserData?.rates.length})
                  </p>

                  {currentUserData?.rates?.map((item, index) => {
                    return (
                      <div
                        className="pt-3 flex flex-row gap-3 w-full justify-center items-center"
                        key={index}
                      >
                        <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                          {item?.profile_picture === null ? (
                            <UserColorProfile
                              name={
                                decryptSecureData(item?.customer_name) || "N Z"
                              }
                              color={"#3bb96e"}
                              size="40px"
                              textSize="16px"
                            />
                          ) : (
                            <img
                              src={item?.profile_picture}
                              alt=""
                              className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                            />
                          )}
                        </div>

                        <div className="w-full">
                          <div className="flex flex-row justify-between items-center w-full">
                            <p className="text-xs font-semibold">
                              {decryptSecureData(item?.customer_name)}{" "}
                              <span className="font-normal text-textColorTwo">
                                {getDateDifference(item?.rated_date_time)}
                              </span>
                            </p>

                            <div className="flex flex-row gap-2">
                              <p className="text-xs font-semibold">
                                {item?.rate}
                              </p>
                              <div className="flex flex-row gap-1">
                                {Array.from({ length: item?.rate }, (_, i) => (
                                  <ViewsIcon key={i} />
                                ))}
                              </div>
                            </div>
                          </div>

                          <p className="text-xxs font-normal text-textColorTwo">
                            {item?.message}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
