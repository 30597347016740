import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.60156C17.74 9.61125 18.6823 9.68841 19.2971 10.3031C20 11.0061 20 12.1374 20 14.4002V15.2002C20 17.4629 20 18.5943 19.2971 19.2972C18.5941 20.0002 17.4627 20.0002 15.2 20.0002H8.8C6.53726 20.0002 5.40589 20.0002 4.70294 19.2972C4 18.5943 4 17.4629 4 15.2002L4 14.4002C4 12.1374 4 11.0061 4.70294 10.3031C5.31765 9.68841 6.25998 9.61125 8 9.60156"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.9999 4L11.9999 14.4M11.9999 14.4L9.5999 11.6M11.9999 14.4L14.3999 11.6"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
