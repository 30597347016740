import React from "react";

const DiscriptionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8.66671C4 6.15255 4 4.89547 4.78105 4.11442C5.5621 3.33337 6.81918 3.33337 9.33333 3.33337H10.6667C13.1808 3.33337 14.4379 3.33337 15.219 4.11442C16 4.89547 16 6.15255 16 8.66671V11.3334C16 13.8475 16 15.1046 15.219 15.8857C14.4379 16.6667 13.1808 16.6667 10.6667 16.6667H9.33333C6.81918 16.6667 5.5621 16.6667 4.78105 15.8857C4 15.1046 4 13.8475 4 11.3334V8.66671Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M7.33301 8.66663H12.6663"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.33301 11.3334H10.6663"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DiscriptionIcon;
