import { useContext } from "react";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { decryptSecureData } from "../encryptHelpers/encryption";
import { getLocalStoragedata } from "../encryptHelpers/storageHelper";

const CreateJobPostHelpers = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setTaskerListCount,
    setJobSummarySteps,
    setPostedJobPostId,
    setJobPostLoading,
  } = useContext(CreateJobPostContext);

  const { getAllTakerCount, postTheJob, postJobAnswer } =
    CreateJobPostServices();

  //get tasker list count............................................................
  const fetchAllTaskerListCount = async (data) => {
    try {
      await getAllTakerCount(data)
        .then((response) => {
          if (response?.data?.success) {
            setTaskerListCount(response?.data?.output?.count);
          } else {
            setTaskerListCount(0);
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //post the tasker job post............................................................
  const postTheTaskerJobPost = async (data) => {
    setJobPostLoading(true);
    try {
      await postTheJob(data)
        .then((response) => {
          if (response?.data?.success) {
            setPostedJobPostId(response?.data?.output?.job_id);
            postTaskerJobPostAnswer(response?.data?.output?.job_id);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //post the tasker job post answer............................................................
  const postTaskerJobPostAnswer = async (jobTypeId) => {
    try {
      await postJobAnswer({
        job_id: jobTypeId,
        customer_name: decryptSecureData(
          getLocalStoragedata("userData")?.first_name
        ),
        is_resubmit: 0,
        questions: [],
      })
        .then((response) => {
          if (response?.data?.success) {
            setJobPostLoading(false);
            setJobSummarySteps(2);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return {
    fetchAllTaskerListCount,
    postTheTaskerJobPost,
    postTaskerJobPostAnswer,
  };
};

export default CreateJobPostHelpers;
