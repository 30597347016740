import React from "react";
import { Divider, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";

const NotificationAlertBox = ({ open, onCancel, content, title }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#000000" />}
      footer={false}
    >
      <div className="flex flex-row justify-start">
        <p className="text-sm font-medium">{title}</p>
      </div>
      <Divider className="mt-2 mb-5 bg-gray-300" />
      {content}
    </Modal>
  );
};

export default NotificationAlertBox;
