import React from "react";

const TickLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1312 7.61645C18.0142 7.55745 17.9012 7.49245 17.7902 7.42145C17.4702 7.21345 17.1762 6.96845 16.9152 6.69145C16.2632 5.95845 16.0192 5.21445 15.9292 4.69345H15.9332C15.8582 4.26045 15.8892 3.98145 15.8942 3.98145H12.9212V15.2704C12.9212 15.4224 12.9212 15.5724 12.9152 15.7204C12.9152 15.7384 12.9132 15.7554 12.9122 15.7754C12.9122 15.7834 12.9122 15.7914 12.9102 15.8004V15.8064C12.8462 16.6294 12.3702 17.3664 11.6402 17.7744C11.2652 17.9834 10.8422 18.0934 10.4102 18.0934C9.02615 18.0934 7.90315 16.9834 7.90315 15.6144C7.90315 14.2444 9.02615 13.1354 10.4102 13.1354C10.6732 13.1344 10.9332 13.1754 11.1822 13.2554L11.1862 10.2824C9.65615 10.0884 8.11415 10.5314 6.93215 11.5044C6.41915 11.9424 5.98915 12.4634 5.65915 13.0464C5.53315 13.2594 5.06015 14.1124 5.00315 15.4984C4.96715 16.2854 5.20715 17.1004 5.32215 17.4364V17.4444C5.39415 17.6424 5.67315 18.3194 6.12915 18.8894C6.49615 19.3474 6.93015 19.7494 7.41615 20.0824V20.0754L7.42315 20.0824C8.86215 21.0434 10.4582 20.9804 10.4582 20.9804C10.7342 20.9694 11.6592 20.9804 12.7102 20.4914C13.8752 19.9484 14.5382 19.1414 14.5382 19.1414C14.9622 18.6584 15.2992 18.1084 15.5352 17.5144C15.8042 16.8204 15.8942 15.9874 15.8942 15.6544V9.66445C15.9302 9.68645 16.4102 9.99845 16.4102 9.99845C16.4102 9.99845 17.1022 10.4334 18.1812 10.7174C18.9562 10.9194 19.9992 10.9614 19.9992 10.9614V8.06345C19.6332 8.10245 18.8912 7.98845 18.1312 7.61645Z"
        fill="#808080"
      />
    </svg>
  );
};

export default TickLogo;
