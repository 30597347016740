import React from "react";

const HelperTextIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6666 9.99992C16.6666 13.6818 13.6818 16.6666 9.99992 16.6666C6.31802 16.6666 3.33325 13.6818 3.33325 9.99992C3.33325 6.31802 6.31802 3.33325 9.99992 3.33325C13.6818 3.33325 16.6666 6.31802 16.6666 9.99992ZM9.99992 7.16658C9.5857 7.16658 9.24992 7.50237 9.24992 7.91658C9.24992 8.19273 9.02606 8.41658 8.74992 8.41658C8.47378 8.41658 8.24992 8.19273 8.24992 7.91658C8.24992 6.95009 9.03342 6.16659 9.99992 6.16659C10.9664 6.16659 11.7499 6.95009 11.7499 7.91658C11.7499 8.39048 11.5609 8.82124 11.2552 9.13589C11.1937 9.19921 11.135 9.25783 11.0791 9.31376C10.9352 9.45752 10.8091 9.58347 10.6985 9.72558C10.5525 9.9132 10.4999 10.0511 10.4999 10.1666V10.6666C10.4999 10.9427 10.2761 11.1666 9.99992 11.1666C9.72378 11.1666 9.49992 10.9427 9.49992 10.6666V10.1666C9.49992 9.72979 9.70326 9.37621 9.90936 9.11139C10.0619 8.91545 10.2535 8.72415 10.4091 8.56889C10.456 8.52206 10.4997 8.47851 10.538 8.4391C10.6696 8.30354 10.7499 8.11995 10.7499 7.91658C10.7499 7.50237 10.4141 7.16658 9.99992 7.16658ZM9.99992 13.3333C10.3681 13.3333 10.6666 13.0348 10.6666 12.6666C10.6666 12.2984 10.3681 11.9999 9.99992 11.9999C9.63173 11.9999 9.33325 12.2984 9.33325 12.6666C9.33325 13.0348 9.63173 13.3333 9.99992 13.3333Z"
        fill="#CDCDCD"
      />
    </svg>
  );
};

export default HelperTextIcon;
