import React from "react";

const PremiumIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1001 9.61633L12.1979 8.70717C12.2501 8.22209 12.2845 7.90177 12.2575 7.69996L12.2669 7.70001C12.7088 7.70001 13.0669 7.34184 13.0669 6.90001C13.0669 6.45818 12.7088 6.10001 12.2669 6.10001C11.8251 6.10001 11.4669 6.45818 11.4669 6.90001C11.4669 7.09983 11.5402 7.28254 11.6613 7.42275C11.4874 7.53007 11.2601 7.75654 10.9179 8.09742L10.9179 8.09742C10.6542 8.36004 10.5224 8.49134 10.3754 8.51168C10.2939 8.52294 10.2109 8.51136 10.1356 8.47823C9.99983 8.41844 9.9093 8.25611 9.72823 7.93146L8.77383 6.22022C8.66213 6.01995 8.56864 5.85232 8.48435 5.71743C8.83014 5.54101 9.06693 5.1815 9.06693 4.76668C9.06693 4.17758 8.58936 3.70001 8.00026 3.70001C7.41116 3.70001 6.93359 4.17758 6.93359 4.76668C6.93359 5.1815 7.17038 5.54101 7.51617 5.71743C7.43187 5.85233 7.3384 6.01993 7.22669 6.22023L6.27229 7.93146C6.09123 8.25611 6.00069 8.41844 5.8649 8.47823C5.78967 8.51136 5.70665 8.52294 5.62517 8.51168C5.47811 8.49134 5.34629 8.36004 5.08266 8.09742C4.74046 7.75655 4.51309 7.53007 4.33921 7.42275C4.46033 7.28254 4.53359 7.09983 4.53359 6.90001C4.53359 6.45818 4.17542 6.10001 3.73359 6.10001C3.29177 6.10001 2.93359 6.45818 2.93359 6.90001C2.93359 7.34184 3.29177 7.70001 3.73359 7.70001L3.74304 7.69996C3.716 7.90177 3.75046 8.22209 3.80263 8.70717L3.90042 9.61632C3.9547 10.121 3.99984 10.6012 4.05513 11.0333H11.9454C12.0007 10.6012 12.0458 10.121 12.1001 9.61633Z"
        fill="#D659D9"
      />
      <path
        d="M7.42003 13.3H8.58049C10.093 13.3 10.8492 13.3 11.3538 12.8484C11.5741 12.6512 11.7135 12.2958 11.8141 11.8333H4.18637C4.28701 12.2958 4.42646 12.6512 4.6467 12.8484C5.15128 13.3 5.90753 13.3 7.42003 13.3Z"
        fill="#D659D9"
      />
    </svg>
  );
};

export default PremiumIcon;
