import { Col, Divider, Row } from "antd";
import React, { useState } from "react";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import VerifyIcon from "../../assets/svg/VerifyIcon";
import ViewsIcon from "../../assets/svg/ViewsIcon";
import CompleteIcon from "../../assets/svg/CompleteIcon";
import { FaAngleRight } from "react-icons/fa";
import NewWorkerIcon from "../../assets/svg/NewWorkerIcon";
import PremiumIcon from "../../assets/svg/PremiumIcon";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
import { getDateDifference } from "../../utils/dateDifference";
import VIewTaskerProfile from "../../sections/createJobPost/VIewTaskerProfile";

const TaskerListCard = ({ taskerList }) => {
  const [openCloseTaskerProfile, setOpenCloseTaskerProfile] = useState(false);
  const [selectedTaskerData, setSelectedTaskerData] = useState();

  const handleOpenCloseTaskerProfile = () => {
    setOpenCloseTaskerProfile(false);
  };
  return (
    <>
      {taskerList?.length !== 0 ? (
        <div>
          {taskerList?.map((item) => {
            return (
              <div className="border-[1px] w-full mt-3 rounded-lg p-2 shadow-lg">
                <Row className="flex flex-row">
                  <Col
                    span={24}
                    md={{ span: 6 }}
                    className="flex flex-col justify-start md:justify-center md:items-center pt-5 md:py-10"
                  >
                    <div className="hidden md:block">
                      <div className="flex flex-row items-center justify-center gap-1">
                        {item?.profile_picture === null ? (
                          <UserColorProfile
                            name={decryptSecureData(item?.first_name) || "N Z"}
                            color={"#3bb96e"}
                            size="100px"
                            textSize="40px"
                          />
                        ) : (
                          <img
                            src={item?.profile_picture}
                            alt=""
                            className="flex w-[100px] h-[100px] rounded-full border-[2px]"
                          />
                        )}
                      </div>

                      <div className="flex flex-col gap-2 mt-2">
                        {item?.has_verified === 1 && (
                          <div className="flex flex-row gap-3 justify-start items-center">
                            <VerifyIcon />
                            <p className="text-sm font-medium text-textColorTwo">
                              Verified Worker
                            </p>
                          </div>
                        )}

                        {item?.rate !== 0 && (
                          <div className="flex flex-row gap-3 justify-start items-center">
                            <ViewsIcon />
                            <p className="text-sm font-medium text-textColorTwo">
                              <span className="text-sm font-bold text-textColorOne">
                                {" "}
                                {item?.rate}
                              </span>{" "}
                              ({item?.review_count} Reviews)
                            </p>
                          </div>
                        )}

                        {item?.job_count !== 0 && (
                          <div className="flex flex-row gap-3 justify-start items-center">
                            <CompleteIcon />
                            <p className="text-sm font-medium text-textColorTwo">
                              <span className="text-sm font-bold text-textColorOne">
                                {" "}
                                {item?.job_count}+
                              </span>{" "}
                              Completed Tasks
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        className="flex flex-row justify-center items-center gap-2 underline text-primaryDarkest mt-5 cursor-pointer"
                        onClick={() => {
                          setOpenCloseTaskerProfile(true);
                          setSelectedTaskerData(item);
                        }}
                      >
                        <p className="text-sm font-medium">View profile</p>
                        <FaAngleRight className="text-primaryDarkest" />
                      </div>
                    </div>

                    <div className="flex md:hidden flex-col sm:flex-row gap-10 w-full">
                      <div className="flex flex-col justify-center items-center">
                        {item?.profile_picture === null ? (
                          <UserColorProfile
                            name={decryptSecureData(item?.first_name) || "N Z"}
                            color={"#3bb96e"}
                            size="100px"
                            textSize="40px"
                          />
                        ) : (
                          <img
                            src={item?.profile_picture}
                            alt=""
                            className="flex max-w-[100px] h-[100px] rounded-full border-[2px]"
                          />
                        )}
                      </div>

                      <div className="flex flex-col sm:flex-row justify-between w-full">
                        <div className="flex flex-col gap-2">
                          {item?.has_verified === 1 && (
                            <div className="flex flex-row gap-3 justify-start items-center">
                              <VerifyIcon />
                              <p className="text-sm font-medium text-textColorTwo">
                                Verified Worker
                              </p>
                            </div>
                          )}

                          {item?.rate !== 0 && (
                            <div className="flex flex-row gap-3 justify-start items-center">
                              <ViewsIcon />
                              <p className="text-sm font-medium text-textColorTwo">
                                <span className="text-sm font-bold text-textColorOne">
                                  {" "}
                                  {item?.rate}
                                </span>{" "}
                                ({item?.review_count} Reviews)
                              </p>
                            </div>
                          )}

                          {item?.job_count !== 0 && (
                            <div className="flex flex-row gap-3 justify-start items-center">
                              <CompleteIcon />
                              <p className="text-sm font-medium text-textColorTwo">
                                <span className="text-sm font-bold text-textColorOne">
                                  {" "}
                                  {item?.job_count}+
                                </span>{" "}
                                Completed Tasks
                              </p>
                            </div>
                          )}

                          <div
                            className="flex flex-row justify-start items-center gap-2 underline text-primaryDarkest cursor-pointer"
                            onClick={() => {
                              setOpenCloseTaskerProfile(true);
                              setSelectedTaskerData(item);
                            }}
                          >
                            <p className="text-sm font-medium">View profile</p>
                            <FaAngleRight className="text-primaryDarkest" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    span={0}
                    md={{ span: 1 }}
                    className="flex justify-center items-center"
                  >
                    <Divider type="vertical" className="bg-gray-300 h-[90%]" />
                  </Col>

                  <Col span={24} md={{ span: 17 }}>
                    <div className="py-3">
                      <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-3">
                          <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                            {decryptSecureData(item?.first_name)}
                          </p>
                          <div
                            className={`border-[2px] ${
                              item?.package_id === 1
                                ? "border-[#E79721] bg-[#E7972133]"
                                : "border-[#D659D9] bg-[#D659D933]"
                            }  px-2 rounded-lg flex flex-row justify-center items-center gap-1`}
                          >
                            {item?.package_id === 1 ? (
                              <NewWorkerIcon />
                            ) : (
                              <PremiumIcon />
                            )}

                            <p
                              className={`text-xxs font-bold ${
                                item?.package_id === 1
                                  ? "text-[#E79721]"
                                  : "text-[#D659D9]"
                              }  hidden xsm:block`}
                            >
                              {item?.package_id === 1
                                ? "NEW WORKER"
                                : " PREMIUM"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="text-xs font-normal text-textColorTwo">
                        {item?.work_title}
                      </p>
                      <div className="flex flex-row">
                        <LocationIconFull />
                        <p className="text-xs font-semibold text-textColorOne">
                          {[item?.state, item?.suberb, item?.postal_code]
                            .filter(Boolean)
                            .join(", ")}{" "}
                        </p>
                      </div>

                      {item?.language !== "" ||
                        item?.language !== null ||
                        (item?.language !== undefined && (
                          <div className="flex flex-row">
                            <LanguageFullIcon />
                            <p className="text-xs font-semibold text-textColorOne">
                              {item?.language}
                            </p>
                          </div>
                        ))}
                    </div>

                    <Row className="flex flex-row gap-1 mt-2">
                      {item?.job_types?.map((item) => {
                        return (
                          <Col>
                            <p className="border-[1px] border-gray-300 py-1 px-2 rounded-full text-xs font-normal text-primaryDarkest">
                              {item?.job_type}
                            </p>
                          </Col>
                        );
                      })}
                    </Row>
                    <Divider className="my-3" />

                    <div>
                      <p className="text-sm font-bold text-textColorOne">
                        Skills & Experience
                      </p>
                      {item?.work_description === null ||
                      item?.work_description === "" ? (
                        <p className="text-sm font-bold text-textColorTwo">_</p>
                      ) : (
                        <p className="text-xs font-normal text-textColorTwo">
                          {item?.work_description}
                        </p>
                      )}
                    </div>
                    <Divider className="my-3" />

                    <div>
                      <p className="text-sm font-bold text-textColorOne">
                        Latest reviews
                      </p>

                      {item?.rates?.length === 0 ? (
                        <p className="text-sm font-bold text-textColorTwo">_</p>
                      ) : (
                        <div className="max-h-[200px] overflow-y-scroll scrollbar-hide">
                          {item?.rates?.map((item) => {
                            return (
                              <div className="pt-3 flex flex-row gap-3 w-full">
                                <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                                  {item?.profile_picture === null ? (
                                    <UserColorProfile
                                      name={
                                        decryptSecureData(
                                          item?.customer_name
                                        ) || "N Z"
                                      }
                                      color={"#3bb96e"}
                                      size="40px"
                                      textSize="16px"
                                    />
                                  ) : (
                                    <img
                                      src={item?.profile_pictur}
                                      alt=""
                                      className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                                    />
                                  )}
                                </div>

                                <div className="w-full">
                                  <div className="flex flex-row justify-between items-center w-full">
                                    <p className="text-xs font-semibold">
                                      {decryptSecureData(item?.customer_name)}{" "}
                                      <span className="font-normal text-textColorTwo">
                                        {getDateDifference(
                                          item?.rated_date_time
                                        )}
                                      </span>
                                    </p>

                                    <div className="flex flex-row gap-2">
                                      <p className="text-xs font-semibold">
                                        {item?.rate}
                                      </p>
                                      <div className="flex flex-row gap-1">
                                        {Array.from(
                                          { length: item?.rate },
                                          (_, i) => (
                                            <ViewsIcon key={i} />
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <p className="text-xxs font-normal text-textColorTwo">
                                    {item?.message}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="border-[1px] w-full mt-3 rounded-lg p-2 shadow-lg">
          <p className="text-center text-sm font-bold text-textColorTwo">
            No Workers
          </p>
        </div>
      )}

      {openCloseTaskerProfile && (
        <VIewTaskerProfile
          open={openCloseTaskerProfile}
          onClose={handleOpenCloseTaskerProfile}
          selectedTaskerData={selectedTaskerData}
        />
      )}
    </>
  );
};

export default TaskerListCard;
