import React from "react";

const ViewsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.31722 0.766758C7.59704 0.20169 8.40296 0.201692 8.68278 0.76676L10.4731 4.38229C10.5841 4.60637 10.798 4.76174 11.0454 4.79802L15.0372 5.38351C15.6611 5.47501 15.9101 6.24149 15.4592 6.68223L12.5738 9.50223C12.395 9.677 12.3133 9.9284 12.3553 10.1749L13.032 14.1523C13.1378 14.7739 12.4858 15.2476 11.9272 14.9549L8.35365 13.0823C8.13217 12.9662 7.86783 12.9662 7.64635 13.0823L4.07276 14.9549C3.51425 15.2476 2.86224 14.7739 2.968 14.1523L3.64472 10.1749C3.68666 9.9284 3.60498 9.677 3.42615 9.50223L0.540837 6.68222C0.0898936 6.24149 0.338938 5.47501 0.962817 5.38351L4.95464 4.79802C5.20204 4.76174 5.41589 4.60637 5.52685 4.38229L7.31722 0.766758Z"
        fill="#FFC261"
      />
    </svg>
  );
};

export default ViewsIcon;
