import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import { useContext } from "react";
import { SubscriptionContext } from "../context/SubscriptionContext";
import { Button, ConfigProvider, Segmented, Spin } from "antd";
import SubscriptionIcon from "../assets/svg/SubscriptionIcon";
import PaymentIcon from "../assets/svg/PaymentIcon";
import PackageDetailCard from "../sections/subuscription/PackageDetailCard";
import PackageSubscription from "../sections/subuscription/PackageSubscription";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../context/NotificationContext";
import CancelSubscriptionModal from "../components/subscription/CancelSubscriptionModal";
import ChangeBillingAddres from "../sections/subuscription/ChangeBillingAddres";
import PaymentHistory from "../sections/subuscription/PaymentHistory";
import { AuthContext } from "../context/AuthContext";
import HeaderConfig from "../helpers/HeaderConfig";
import LoginServices from "../services/LoginServices";
import SubscriptionServices from "../services/SubscriptionServices";

const Subscription = () => {
  let {
    currentPackageDetails,
    setCurrentPackageDetails,

    openClosePaymentSubscripDrawer,
    setOpenClosePaymentSubscripDrawer,
    handleOpenClosePaymentSubscripDrawer,

    openCloseChangeBillingAddress,
    handleOpenCloseChangeBillingAddress,

    setNextPackageDetails,

    setChangedBillingAddress,
  } = useContext(SubscriptionContext);
  let { openNotification, handleError } = useContext(NotificationContext);
  let { setCurrentUserData } = useContext(AuthContext);

  const { configAuth } = HeaderConfig();
  const { getAllAuthUserData } = LoginServices();
  const {
    getSubscriptionDetails,
    getSubscriptionDetailsWeb,
    getKeysByPaymentInitiating,
  } = SubscriptionServices();

  const [selectedSegment, setSelectedSegment] = useState(1);
  const [loading, setLoading] = useState(true);
  const [
    openCloseCancelSubscriptionModal,
    setOpenCloseCancelSubscriptionModal,
  ] = useState(false);

  useEffect(() => {
    handleGetSubscriptionDetails();

    if (getLocalStoragedata("token")) {
      getAuthUserData();
    }
    window.scrollTo(0, 0);
  }, []);

  const handleGetSubscriptionDetails = async () => {
    setLoading(true);
    try {
      await getSubscriptionDetailsWeb(configAuth, {
        trady_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setCurrentPackageDetails(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setCurrentUserData(response?.data?.output);
            setChangedBillingAddress({
              addressline01: response?.data?.output?.address_line1,
              addressline02: response?.data?.output?.address_line2,
              postalCode: response?.data?.output?.postal_code,
              cityorsuburb: response?.data?.output?.suberb,
              stateorprovince: response?.data?.output?.state,
              country: response?.data?.output?.country,
            });
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handlePaymentInitialize = async () => {
    try {
      await getKeysByPaymentInitiating(configAuth, {
        user_id: getLocalStoragedata("userId"),
        country_id: getLocalStoragedata("countryId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setNextPackageDetails(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="flex flex-col justify-center items-center my-5 mt-28">
          <Segmented
            onChange={(e) => {
              setSelectedSegment(e);
            }}
            options={[
              {
                label: (
                  <div className="flex flex-row gap-1 justify-center items-center">
                    <SubscriptionIcon
                      color={selectedSegment === 1 ? "#3bb96e" : "#818181"}
                    />
                    <p
                      className={`text-sm font-semibold ${
                        selectedSegment === 1
                          ? "text-primaryColor"
                          : "text-textColorTwo"
                      } `}
                    >
                      Subscription
                    </p>
                  </div>
                ),
                value: 1,
              },
              {
                label: (
                  <div className="flex flex-row gap-1 justify-center items-center">
                    <PaymentIcon
                      color={selectedSegment === 2 ? "#3bb96e" : "#818181"}
                    />
                    <p
                      className={`text-sm font-semibold ${
                        selectedSegment === 2
                          ? "text-primaryColor"
                          : "text-textColorTwo"
                      } `}
                    >
                      Payment History
                    </p>
                  </div>
                ),
                value: 2,
              },
            ]}
          />
          {selectedSegment === 1 ? (
            <div className="w-full flex flex-col justify-center items-center">
              {loading ? (
                <div className="mt-10">
                  <Spin />
                </div>
              ) : (
                <>
                  <PackageDetailCard />

                  {currentPackageDetails?.package_id === 1 ? (
                    <>
                      {currentPackageDetails?.day_count < 1 ? (
                        <Button
                          type="primary"
                          className="max-w-[400px] w-full mt-5 bg-primaryDark text-sm font-semibold"
                          onClick={() => {
                            setOpenClosePaymentSubscripDrawer(true);
                            handlePaymentInitialize();
                          }}
                        >
                          Subscribe Now
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {currentPackageDetails?.day_count < 1 ? (
                        <>
                          <ConfigProvider
                            theme={{
                              token: {
                                colorPrimary: "#F53F3F",
                                colorInfo: "#F53F3F",
                              },
                            }}
                          >
                            <Button
                              type="primary"
                              className="max-w-[400px] w-full mt-5 bg-[#F53F3F] text-sm font-semibold"
                              onClick={() => {
                                setOpenClosePaymentSubscripDrawer(true);
                                handlePaymentInitialize();
                              }}
                            >
                              Pay Now
                            </Button>
                          </ConfigProvider>

                          {currentPackageDetails?.is_unsubscribe === 0 && (
                            <div
                              className="text-sm font-semibold mt-5 underline cursor-pointer"
                              onClick={() => {
                                setOpenCloseCancelSubscriptionModal(true);
                              }}
                            >
                              <p>Cancel subscription</p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {currentPackageDetails?.is_unsubscribe === 0 && (
                            <div
                              className="text-sm font-semibold mt-5 underline cursor-pointer"
                              onClick={() => {
                                setOpenCloseCancelSubscriptionModal(true);
                              }}
                            >
                              <p>Cancel subscription</p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <PaymentHistory />
          )}
        </div>
      </div>
      {openClosePaymentSubscripDrawer && (
        <PackageSubscription
          open={openClosePaymentSubscripDrawer}
          onClose={handleOpenClosePaymentSubscripDrawer}
        />
      )}

      {openCloseCancelSubscriptionModal && (
        <CancelSubscriptionModal
          open={openCloseCancelSubscriptionModal}
          onCancel={() => {
            setOpenCloseCancelSubscriptionModal(false);
          }}
          handleGetSubscriptionDetails={handleGetSubscriptionDetails}
        />
      )}

      {openCloseChangeBillingAddress && (
        <ChangeBillingAddres
          open={openCloseChangeBillingAddress}
          onClose={handleOpenCloseChangeBillingAddress}
        />
      )}
    </div>
  );
};

export default Subscription;
