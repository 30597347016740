import React from "react";
import PersonIcon from "../../assets/svg/PersonIcon";
import CommissionIcon from "../../assets/svg/CommissionIcon";
import BossIcon from "../../assets/svg/BossIcon";

const InfoCardWorkers = () => {
  const cards = [
    {
      icon: <CommissionIcon />,
      title: "No commission",
      description:
        "Say goodbye to hidden charges and hello to financial freedom with JobsNinja’s no commission policy. With a low fixed subscription fee and unlimited leads, you get the best value",
    },
    {
      icon: <PersonIcon />,
      title: "Sharable profile",
      description:
        "Showcase your best work making it simple for clients to find and contact you. Get jobs directly to the phone free.",
    },
    {
      icon: <BossIcon />,
      title: "You are your Boss",
      description:
        "You have the flexibility to choose where and when you work, ensuring that your job fits seamlessly into your life. We try to match you with your native speakers when possible.",
    },
  ];
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl">
          {cards.map((card, index) => (
            <div
              key={index}
              className="rounded-lg shadow-lg bg-gradient-to-tl from-transparent to-gray-200 max-w-[330px]"
            >
              <div className="flex flex-col items-start p-8 md:py-6 md:px-3 lg:p-8">
                {card.icon}
                <h3 className="text-[24px] font-semibold">{card.title}</h3>
                <p className="text-base font-normal text-gray">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoCardWorkers;
