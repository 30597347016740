import React from "react";

const FavouriteIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2601 49.3964H18.7402C16.8546 49.3964 15.9118 49.3964 15.326 48.8106C14.7402 48.2248 14.7402 47.282 14.7402 45.3964V43.1725C14.7402 42.1355 14.7402 41.617 15.0066 41.1539C15.2729 40.6909 15.6748 40.4572 16.4784 39.9898C21.7694 36.9125 29.2833 35.1802 34.2984 38.1714C34.6353 38.3724 34.9384 38.615 35.1973 38.9059C36.3135 40.1596 36.2322 42.0518 34.9458 43.1746C34.6741 43.4117 34.3845 43.5915 34.093 43.654C34.3326 43.6263 34.5622 43.5944 34.7814 43.5595C36.6042 43.2687 38.1343 42.2947 39.5351 41.2365L43.1495 38.5061C44.4237 37.5436 46.3149 37.5434 47.5893 38.5057C48.7366 39.372 49.0874 40.7985 48.3621 41.9611C47.5162 43.317 46.3244 45.0516 45.1801 46.1115C44.0341 47.1729 42.328 48.1204 40.9352 48.7927C39.3923 49.5374 37.6878 49.9665 35.9541 50.2471C32.4379 50.8163 28.7734 50.7294 25.2929 50.0124C23.3253 49.607 21.2818 49.3964 19.2601 49.3964Z"
        fill="#206CFF"
      />
      <path
        d="M28.4628 13.3463C29.4761 11.5285 29.9827 10.6196 30.7402 10.6196C31.4977 10.6196 32.0044 11.5285 33.0177 13.3463L33.2799 13.8166C33.5678 14.3332 33.7118 14.5915 33.9363 14.7619C34.1608 14.9323 34.4403 14.9956 34.9995 15.1221L35.5086 15.2373C37.4763 15.6825 38.4602 15.9051 38.6943 16.6578C38.9284 17.4105 38.2576 18.1949 36.9162 19.7636L36.5691 20.1694C36.1879 20.6152 35.9973 20.8381 35.9115 21.1138C35.8258 21.3895 35.8546 21.6869 35.9122 22.2817L35.9647 22.8231C36.1675 24.9161 36.2689 25.9626 35.6561 26.4278C35.0433 26.893 34.1221 26.4689 32.2797 25.6206L31.803 25.4011C31.2795 25.16 31.0177 25.0395 30.7402 25.0395C30.4627 25.0395 30.201 25.16 29.6774 25.4011L29.2008 25.6206C27.3584 26.4689 26.4372 26.893 25.8244 26.4278C25.2115 25.9626 25.3129 24.9161 25.5157 22.8232L25.5682 22.2817C25.6259 21.6869 25.6547 21.3895 25.5689 21.1138C25.4832 20.8381 25.2926 20.6152 24.9114 20.1694L24.5643 19.7636C23.2228 18.1949 22.5521 17.4105 22.7862 16.6578C23.0202 15.9051 24.0041 15.6825 25.9719 15.2373L26.4809 15.1221C27.0401 14.9956 27.3197 14.9323 27.5442 14.7619C27.7687 14.5915 27.9127 14.3332 28.2006 13.8166L28.4628 13.3463Z"
        fill="#206CFF"
      />
      <path
        d="M45.6015 21.983C46.1082 21.0741 46.3615 20.6196 46.7402 20.6196C47.119 20.6196 47.3723 21.0741 47.879 21.983L48.01 22.2181C48.154 22.4764 48.226 22.6055 48.3382 22.6908C48.4505 22.776 48.5903 22.8076 48.8699 22.8709L49.1244 22.9284C50.1083 23.1511 50.6002 23.2624 50.7173 23.6387C50.8343 24.0151 50.4989 24.4073 49.8282 25.1916L49.6547 25.3945C49.4641 25.6174 49.3688 25.7289 49.3259 25.8667C49.283 26.0046 49.2974 26.1533 49.3262 26.4507L49.3525 26.7214C49.4539 27.7679 49.5046 28.2911 49.1982 28.5237C48.8918 28.7563 48.4312 28.5443 47.51 28.1201L47.2716 28.0104C47.0099 27.8898 46.879 27.8296 46.7402 27.8296C46.6015 27.8296 46.4706 27.8898 46.2088 28.0104L45.9705 28.1201C45.0493 28.5443 44.5887 28.7563 44.2823 28.5237C43.9759 28.2911 44.0266 27.7679 44.128 26.7214L44.1542 26.4507C44.183 26.1533 44.1974 26.0046 44.1546 25.8667C44.1117 25.7289 44.0164 25.6174 43.8258 25.3945L43.6523 25.1916C42.9815 24.4073 42.6462 24.0151 42.7632 23.6387C42.8802 23.2624 43.3722 23.1511 44.356 22.9284L44.6106 22.8709C44.8902 22.8076 45.03 22.776 45.1422 22.6908C45.2545 22.6055 45.3264 22.4764 45.4704 22.2181L45.6015 21.983Z"
        fill="#206CFF"
      />
      <path
        d="M13.6015 21.983C14.1082 21.0741 14.3615 20.6196 14.7402 20.6196C15.119 20.6196 15.3723 21.0741 15.879 21.983L16.01 22.2181C16.154 22.4764 16.226 22.6055 16.3383 22.6908C16.4505 22.776 16.5903 22.8076 16.8699 22.8709L17.1244 22.9284C18.1083 23.1511 18.6002 23.2624 18.7173 23.6387C18.8343 24.0151 18.4989 24.4073 17.8282 25.1916L17.6547 25.3945C17.4641 25.6174 17.3688 25.7289 17.3259 25.8667C17.283 26.0046 17.2974 26.1533 17.3262 26.4507L17.3525 26.7214C17.4539 27.7679 17.5046 28.2911 17.1982 28.5237C16.8918 28.7563 16.4312 28.5443 15.51 28.1201L15.2716 28.0104C15.0099 27.8898 14.879 27.8296 14.7402 27.8296C14.6015 27.8296 14.4706 27.8898 14.2088 28.0104L13.9705 28.1201C13.0493 28.5443 12.5887 28.7563 12.2823 28.5237C11.9759 28.2911 12.0266 27.7679 12.128 26.7214L12.1542 26.4507C12.183 26.1533 12.1975 26.0046 12.1546 25.8667C12.1117 25.7289 12.0164 25.6174 11.8258 25.3945L11.6523 25.1916C10.9815 24.4073 10.6462 24.0151 10.7632 23.6387C10.8802 23.2624 11.3722 23.1511 12.356 22.9284L12.6106 22.8709C12.8902 22.8076 13.03 22.776 13.1422 22.6908C13.2545 22.6055 13.3264 22.4764 13.4704 22.2181L13.6015 21.983Z"
        fill="#206CFF"
      />
    </svg>
  );
};

export default FavouriteIcon;
