import React, { useContext } from "react";
import { Divider } from "antd";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { getBillingDate } from "../../utils/dateDifference";
import CalendarGreenOutlineIcon from "../../assets/svg/CalendarGreenOutlineIcon";

const PackageDetailCard = () => {
  let { currentPackageDetails } = useContext(SubscriptionContext);

  return (
    <div className="border-[2px] mt-5 p-3 rounded-lg max-w-[400px] w-full">
      <div className="flex flex-row justify-between items-center w-full">
        <p className="text-sm font-normal text-textColorTwo">
          Currant package status
        </p>
        {currentPackageDetails?.package_id === 1 ? (
          <p className="text-xxs font-semibold text-white bg-[#EAA844] rounded-full px-2">
            {currentPackageDetails?.day_count < 1
              ? " Free Trial Expired"
              : " Free Trial"}
          </p>
        ) : (
          <>
            {currentPackageDetails?.day_count < 1 ? (
              <p className="text-xxs font-semibold text-white bg-[#F53F3F] rounded-full px-2">
                Subscription Expired
              </p>
            ) : (
              <p className="text-xxs font-semibold text-white bg-[#1D66F6] rounded-full px-10">
                Active
              </p>
            )}
          </>
        )}
      </div>

      <div className="w-full mt-5">
        <div className="flex flex-row justify-between text-sm font-normal">
          <p>Package</p>
          <p>{currentPackageDetails?.package_name}</p>
        </div>

        <div className="flex flex-row justify-between text-sm font-normal">
          <p>Package price</p>
          <p className="text-sm md:text-base font-medium">
            {currentPackageDetails?.price}{" "}
            {currentPackageDetails?.currency_label}
          </p>
        </div>

        <div className="flex flex-row justify-between text-sm font-normal">
          <p>Package duration</p>
          <p className="text-sm md:text-base font-medium">
            {currentPackageDetails?.total_day_count / 30} Month{" "}
            {currentPackageDetails?.total_day_count % 30 !== 0 &&
              (currentPackageDetails?.total_day_count % 30) + " Days"}
          </p>
        </div>

        <Divider className="my-2 bg-textColorTwo" />

        <div className="flex flex-row justify-between text-sm md:text-base font-normal">
          <p>Final Price</p>
          <p className="text-base md:text-xl font-medium">
            {currentPackageDetails?.price}{" "}
            {currentPackageDetails?.currency_label}
          </p>
        </div>

        <Divider className="my-2 bg-textColorTwo" />

        {currentPackageDetails?.package_id !== 1 &&
          currentPackageDetails?.day_count > 0 && (
            <p className="text-xs font-normal flex flex-row gap-1 justify-start items-center mt-1">
              <CalendarGreenOutlineIcon />
              <div className="text-textColorTwo">Next Billing date : </div>
              <div className="text-textColorThree font-semibold">
                {getBillingDate(currentPackageDetails?.day_count)}
                {/* { dayjs().set('date', 5)} */}
              </div>
            </p>
          )}

        <p className="text-xxs font-normal mt-2">
          You will be charged automatically until you cancel your subscription.
        </p>
      </div>
    </div>
  );
};

export default PackageDetailCard;
