import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const WorkSampleImageList = ({ workSamples, listSize }) => {
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [imageCount, setImageCount] = useState();

  const showModal = (index) => {
    setCurrentImage(index);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePrev = () => {
    setCurrentImage(
      (prev) => (prev - 1 + workSamples.length) % workSamples.length
    );
  };

  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % workSamples.length);
  };

  useEffect(() => {
    if (listSize === "small") {
      if (window.innerWidth < 640) {
        setImageCount(3);
      } else if (window.innerWidth < 768) {
        setImageCount(4);
      } else if (window.innerWidth < 1024) {
        setImageCount(6);
      } else {
        setImageCount(6);
      }
    } else {
      if (window.innerWidth < 640) {
        setImageCount(3);
      } else if (window.innerWidth < 768) {
        setImageCount(4);
      } else if (window.innerWidth < 1024) {
        setImageCount(6);
      } else {
        setImageCount(8);
      }
    }
  }, []);

  return (
    <div className="w-full">
      <p className="text-sm font-bold text-textColorOne md:text-sm  sm:text-lg">
        Work Samples ({workSamples.length})
      </p>

      {workSamples?.length === 0 ? (
        <p className="text-sm font-bold text-textColorTwo">_</p>
      ) : (
        <>
          {listSize === "small" ? (
            <Row className="flex flex-row mt-3 w-full" gutter={32}>
              {workSamples.slice(0, imageCount).map((sample, index) => (
                <Col
                  key={index}
                  className="relative cursor-pointer w-full"
                  onClick={() => showModal(index)}
                  span={
                    imageCount === 6
                      ? 4
                      : imageCount === 6
                      ? 4
                      : imageCount === 4
                      ? 6
                      : 8
                  }
                >
                  <img
                    src={sample}
                    alt={`Work sample ${index + 1}`}
                    className="object-cover rounded-lg md:max-w-[80px] md:max-h-[80px]"
                  />
                  {index === imageCount - 1 &&
                    workSamples.length > imageCount && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center rounded-lg">
                        <p className="text-white text-xl font-bold">
                          +{workSamples.length - imageCount}
                        </p>
                      </div>
                    )}
                </Col>
              ))}
            </Row>
          ) : (
            <Row className="flex flex-row mt-3 w-full" gutter={32}>
              {workSamples.slice(0, imageCount).map((sample, index) => (
                <Col
                  key={index}
                  className="relative cursor-pointer w-full"
                  onClick={() => showModal(index)}
                  span={
                    imageCount === 8
                      ? 3
                      : imageCount === 6
                      ? 4
                      : imageCount === 4
                      ? 6
                      : 8
                  }
                >
                  <img
                    src={sample}
                    alt={`Work sample ${index + 1}`}
                    className="object-cover rounded-lg md:max-w-[100px] md:max-h-[100px]"
                  />
                  {index === imageCount - 1 &&
                    workSamples.length > imageCount && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center rounded-lg">
                        <p className="text-white text-xl font-bold">
                          +{workSamples.length - imageCount}
                        </p>
                      </div>
                    )}
                </Col>
              ))}
            </Row>
          )}

          <Modal
            visible={visible}
            footer={null}
            onCancel={handleCancel}
            centered
          >
            <div className="relative">
              <img
                src={workSamples[currentImage]}
                alt={`Work sample ${currentImage + 1}`}
                className="w-full h-auto"
              />
              {/* <LeftOutlined
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-2xl cursor-pointer"
            onClick={handlePrev}
          />
          <RightOutlined
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-2xl cursor-pointer"
            onClick={handleNext}
          /> */}
            </div>
            <div className="flex overflow-x-auto mt-2">
              {workSamples.map((sample, index) => (
                <img
                  key={index}
                  src={sample}
                  alt={`Thumbnail ${index + 1}`}
                  className={`w-16 h-16 object-cover mx-1 cursor-pointer ${
                    index === currentImage ? "border-2 border-primaryColor" : ""
                  }`}
                  onClick={() => setCurrentImage(index)}
                />
              ))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default WorkSampleImageList;
