import React from "react";

const PremiumGreenIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5756 16.2561L21.7499 14.635C21.843 13.77 21.9044 13.1988 21.8562 12.839L21.873 12.8391C22.6609 12.8391 23.2995 12.2004 23.2995 11.4126C23.2995 10.6247 22.6609 9.98606 21.873 9.98606C21.0852 9.98606 20.4465 10.6247 20.4465 11.4126C20.4465 11.7689 20.5772 12.0947 20.7931 12.3447C20.4831 12.536 20.0777 12.9399 19.4675 13.5477L19.4675 13.5477C18.9974 14.016 18.7623 14.2501 18.5001 14.2864C18.3548 14.3065 18.2068 14.2858 18.0726 14.2267C17.8305 14.1201 17.6691 13.8307 17.3462 13.2518L15.6444 10.2004C15.4452 9.84329 15.2785 9.54439 15.1282 9.30386C15.7448 8.98929 16.167 8.34823 16.167 7.60855C16.167 6.5581 15.3155 5.70654 14.265 5.70654C13.2146 5.70654 12.363 6.5581 12.363 7.60855C12.363 8.34823 12.7852 8.98929 13.4018 9.30386C13.2515 9.54441 13.0848 9.84326 12.8856 10.2004L11.1838 13.2518C10.8609 13.8307 10.6995 14.1201 10.4574 14.2267C10.3232 14.2858 10.1752 14.3065 10.0299 14.2864C9.76768 14.2501 9.53263 14.016 9.06254 13.5477C8.45234 12.9399 8.04692 12.536 7.73687 12.3447C7.95285 12.0947 8.08348 11.7689 8.08348 11.4126C8.08348 10.6247 7.44481 9.98606 6.65697 9.98606C5.86914 9.98606 5.23047 10.6247 5.23047 11.4126C5.23047 12.2004 5.86914 12.8391 6.65697 12.8391L6.67382 12.839C6.62561 13.1988 6.68704 13.77 6.78008 14.635L6.95445 16.2561C7.05124 17.156 7.13173 18.0122 7.23031 18.7828H21.2997C21.3983 18.0122 21.4788 17.156 21.5756 16.2561Z"
        fill="#2C8B52"
      />
      <path
        d="M13.2304 22.8246H15.2996C17.9966 22.8246 19.3451 22.8246 20.2448 22.0193C20.6376 21.6678 20.8862 21.0341 21.0657 20.2094H7.46434C7.64378 21.0341 7.89245 21.6678 8.28517 22.0193C9.1849 22.8246 10.5334 22.8246 13.2304 22.8246Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default PremiumGreenIcon;
