import React from "react";

const LocationIconCard = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 7.67634C5.3335 5.27772 7.42283 3.33325 10.0002 3.33325C12.5775 3.33325 14.6668 5.27772 14.6668 7.67634C14.6668 10.0562 13.1774 12.8332 10.8535 13.8263C10.3118 14.0578 9.68851 14.0578 9.14679 13.8263C6.82294 12.8332 5.3335 10.0562 5.3335 7.67634Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M11.3332 8.00008C11.3332 8.73646 10.7362 9.33341 9.99984 9.33341C9.26346 9.33341 8.6665 8.73646 8.6665 8.00008C8.6665 7.2637 9.26346 6.66675 9.99984 6.66675C10.7362 6.66675 11.3332 7.2637 11.3332 8.00008Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M15.9739 12.3333C16.4174 12.7349 16.6668 13.1876 16.6668 13.6666C16.6668 15.3234 13.6821 16.6666 10.0002 16.6666C6.31826 16.6666 3.3335 15.3234 3.3335 13.6666C3.3335 13.1876 3.58291 12.7349 4.02647 12.3333"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LocationIconCard;
