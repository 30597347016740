import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";

import MobileMenu from "./MobileMenu";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import NavbarProfile from "../navbarProfile/NavbarProfile";
import CommonHeaderMenu from "./CommonHeaderMenu";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { AuthContext } from "../../context/AuthContext";

const NavBar = () => {
  const location = useLocation();
  const { userLocation, fetchCountryCode } = useContext(SubscriptionContext);
  let { setAuthSteps } = useContext(AuthContext);

  useEffect(() => {
    fetchCountryCode();
  }, [fetchCountryCode]);

  // Define menu items
  const menuItemsNotLoggedIn = [
    { name: "Home", url: "/" },
    { name: "Subscription", url: "/subscription-details" },
  ];

  const menuItemsWorker = [
    { name: "Home", url: "/" },
    {
      name: "Subscription",
      url: ["au", "nz", "sg"].includes(userLocation)
        ? "/subscription"
        : "/subscription-details",
    },
  ];

  const menuItemsClient = [{ name: "Home", url: "/" }];

  // const menuItemsClient = [
  //   { name: "Home", url: "/" },
  //   { name: "My Jobs", url: "/my-jobs" },
  // ];

  const signInItems = [
    {
      key: "1",
      label: (
        <Link to="../login" state={{ role: 1, type: "asNew" }}>
          <div className="flex justify-between items-center border-b border-b-white pb-1 text-base font-medium">
            <span className="text-white">As a Worker</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="../login" state={{ role: 2, type: "asNew" }}>
          <div className="flex justify-between items-center pb-1 text-base font-medium">
            <span className="text-white">As a Client</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
  ];

  const signInMenu = (
    <Menu
      items={signInItems}
      style={{
        width: 135,
        background: "#2c8b52",
        color: "white",
        borderRadius: "8px",
      }}
    />
  );

  // Render Menu Based on User Type
  const renderMenu = () => {
    if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 6
    ) {
      return (
        <>
          <CommonHeaderMenu
            menuItems={menuItemsWorker}
            locationPathname={location.pathname}
          />
          <NavbarProfile />
        </>
      );
    }

    if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 2
    ) {
      return (
        <>
          <CommonHeaderMenu
            menuItems={menuItemsClient}
            locationPathname={location.pathname}
          />
          <NavbarProfile />
        </>
      );
    }

    return (
      <>
        <CommonHeaderMenu
          menuItems={menuItemsNotLoggedIn}
          locationPathname={location.pathname}
        />
        <Dropdown overlay={signInMenu} trigger={["click"]}>
          <button
            className="bg-primaryColor text-base font-medium text-white py-2 px-5 rounded-lg w-[135px]"
            onClick={() => {
              setAuthSteps(1);
            }}
          >
            <p className="flex justify-between items-center">
              Sign in <FaAngleDown />
            </p>
          </button>
        </Dropdown>
      </>
    );
  };

  return (
    <div className="flex flex-row gap-2 w-full items-center justify-center border-b-[2px] navbar">
      <div className="max-w-[1280px] w-full my-2 mr-1 xl:mr-0">
        {/* Desktop view */}
        <div className="hidden md:flex flex-row justify-between items-center">
          <Link to="/">
            <NavbarLogo />
          </Link>
          <div className="flex flex-row gap-4 justify-center items-center">
            {renderMenu()}
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden flex">
          <MobileMenu
            menuItemsNotLoggedIn={menuItemsNotLoggedIn}
            menuItemsWorker={menuItemsWorker}
            menuItemsClient={menuItemsClient}
            signInMenu={signInMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
