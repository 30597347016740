import React from "react";

const DiscriIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.0666C8.22091 11.0666 8.4 10.8875 8.4 10.6666V7.4666C8.4 7.24569 8.22091 7.0666 8 7.0666C7.77908 7.0666 7.6 7.24569 7.6 7.4666V10.6666C7.6 10.8875 7.77908 11.0666 8 11.0666Z"
        fill="#939292"
      />
      <path
        d="M8 5.33327C8.29455 5.33327 8.53333 5.57205 8.53333 5.8666C8.53333 6.16115 8.29455 6.39993 8 6.39993C7.70544 6.39993 7.46666 6.16115 7.46666 5.8666C7.46666 5.57205 7.70544 5.33327 8 5.33327Z"
        fill="#939292"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26666 7.99993C2.26666 4.8335 4.83356 2.2666 8 2.2666C11.1664 2.2666 13.7333 4.8335 13.7333 7.99993C13.7333 11.1664 11.1664 13.7333 8 13.7333C4.83356 13.7333 2.26666 11.1664 2.26666 7.99993ZM8 3.0666C5.27539 3.0666 3.06666 5.27533 3.06666 7.99993C3.06666 10.7245 5.27539 12.9333 8 12.9333C10.7246 12.9333 12.9333 10.7245 12.9333 7.99993C12.9333 5.27533 10.7246 3.0666 8 3.0666Z"
        fill="#939292"
      />
    </svg>
  );
};

export default DiscriIcon;
