import React from "react";

const GreenLogo = () => {
  return (
    <svg
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_2550_4661)">
        <rect
          x="18.6572"
          y="10.8159"
          width="50"
          height="50"
          rx="11"
          fill="#E3E3E3"
        />
        <path
          d="M44.7346 53.1971C44.1346 53.2971 43.6346 52.8971 43.7346 52.3971C43.7346 51.2971 43.7346 50.3971 43.7346 49.9971C43.7346 49.7971 43.5346 49.6971 43.4346 49.6971C41.2346 49.6971 39.1346 49.4971 36.2346 49.1971C32.1346 48.6971 28.2346 44.8971 27.3346 38.9971C26.8346 35.5971 26.5346 30.9971 27.3346 26.2971C27.9346 22.6971 30.4346 19.5971 36.2346 18.8971C42.0346 18.1971 44.7346 18.2971 50.9346 18.9971C56.8346 19.5971 59.6346 22.3971 60.1346 26.2971C60.6346 30.0971 60.5346 34.9971 60.1346 38.9971C59.2346 46.5971 51.5346 51.9971 44.7346 53.1971Z"
          fill="#1E1E1E"
        />
        <path
          d="M56.1346 28.6973L54.4346 29.8973C50.7346 32.2973 47.1346 33.0973 43.6346 33.2973C40.1346 33.0973 36.4346 32.2973 32.8346 29.8973L31.1346 28.6973C29.6346 27.6973 28.5346 29.0973 29.1346 31.0973L30.4346 35.9973C30.6346 37.3973 31.6346 38.3973 32.6346 38.7973C33.1346 38.9973 33.8346 39.0973 34.5346 39.0973C37.3346 39.0973 39.8346 36.9973 43.0346 36.9973C46.1346 36.9973 49.6346 39.0973 52.4346 39.0973C53.1346 39.0973 53.8346 38.9973 54.3346 38.7973C55.3346 38.4973 56.3346 37.3973 56.7346 35.9973L58.0346 31.0973C58.7346 29.1973 57.5346 27.6973 56.1346 28.6973Z"
          fill="#40D37B"
        />
        <path
          d="M32.835 34.4975C32.835 34.4975 34.135 34.4975 35.535 34.8975C36.635 35.1975 38.135 36.1975 38.135 36.1975C38.335 36.3975 38.735 36.2975 38.835 35.9975C38.935 35.6975 38.935 35.3975 38.635 35.1975C38.535 35.1975 36.935 34.0975 35.735 33.7975C34.235 33.3975 32.935 33.3975 32.835 33.3975C32.535 33.3975 32.335 33.6975 32.335 33.9975C32.335 34.2975 32.535 34.4975 32.835 34.4975Z"
          fill="#1E1E1E"
        />
        <path
          d="M53.5354 34.4975C53.5354 34.4975 52.2354 34.4975 50.8354 34.8975C49.7354 35.1975 48.2354 36.1975 48.2354 36.1975C48.0354 36.3975 47.6354 36.2975 47.5354 35.9975C47.4354 35.6975 47.4354 35.3975 47.7354 35.1975C47.8354 35.1975 49.4354 34.0975 50.6354 33.7975C52.1354 33.3975 53.5354 33.3975 53.6354 33.3975C53.9354 33.3975 54.1354 33.6975 54.1354 33.9975C54.0354 34.2975 53.8354 34.4975 53.5354 34.4975Z"
          fill="#1E1E1E"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2550_4661"
          x="0.657227"
          y="0.815918"
          width="86"
          height="86"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2550_4661"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_2550_4661"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2550_4661"
            result="effect2_dropShadow_2550_4661"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2550_4661"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default GreenLogo;
