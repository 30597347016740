import React, { useState } from "react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "antd";
import { FaAngleDown } from "react-icons/fa";

import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NavbarProfile from "../navbarProfile/NavbarProfile";
import NavBarFaceLogo from "../../assets/svg/NavBarFaceLogo";
import CommonHeaderMenu from "./CommonHeaderMenu";

const MobileMenu = ({
  menuItemsNotLoggedIn,
  menuItemsWorker,
  menuItemsClient,
  signInMenu,
}) => {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Toggle Mobile Menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close Mobile Menu
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Render Menu Based on User Type
  const renderMenu = () => {
    if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 6
    ) {
      return (
        <CommonHeaderMenu
          menuItems={menuItemsWorker}
          locationPathname={location.pathname}
        />
      );
    }

    if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 2
    ) {
      return (
        <CommonHeaderMenu
          menuItems={menuItemsClient}
          locationPathname={location.pathname}
        />
      );
    }

    return (
      <CommonHeaderMenu
        menuItems={menuItemsNotLoggedIn}
        locationPathname={location.pathname}
      />
    );
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full px-2 my-2">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center justify-center">
            <button className="text-3xl p-2right-0">
              <IoMdMenu
                className="text-primaryColor"
                onClick={() => {
                  toggleMobileMenu();
                }}
              />
            </button>
          </div>
          <Link to="/">
            <NavBarFaceLogo />
          </Link>
        </div>

        {getLocalStoragedata("token") ? (
          <NavbarProfile />
        ) : (
          <>
            <Dropdown overlay={signInMenu} trigger={["click"]}>
              <button className="bg-primaryColor text-base font-medium text-white py-2 px-5 rounded-lg w-[135px]">
                <p className="flex flex-row justify-between items-center">
                  Sign in
                  <FaAngleDown />
                </p>
              </button>
            </Dropdown>
          </>
        )}
      </div>

      <div
        className={`fixed top-0 right-0 w-full h-96 bg-primaryLightest text-black transform z-[99999] ${
          isMobileMenuOpen ? "translate-y-0" : "-translate-y-full"
        } transition-transform duration-300 ease-in-out overflow-hidden`}
      >
        <div className="flex justify-end">
          <button className="text-3xl p-3 mr-2">
            <IoMdClose
              className="text-primaryColor"
              onClick={() => {
                closeMobileMenu();
              }}
            />
          </button>
        </div>

        <div className="flex flex-col items-center h-full">{renderMenu()}</div>
      </div>
    </>
  );
};

export default MobileMenu;
