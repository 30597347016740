import React, { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div classNameName="content">
        <div className="flex flex-col justify-center items-center bg-white w-full h-fit">
          <div className="flex flex-col items-center w-full max-w-[1280px] p-2 px-4 mt-20 mb-10 gap-6">
            <header className=" text-center py-4">
              <h1 className="text-4xl font-bold">Terms and Conditions</h1>
              <p>February 2024 (Version 1.0)</p>
            </header>

            <div className="p-8">
              <div className="mb-8">
                <p>
                  The following provisions govern your use of{" "}
                  <a
                    href="https://www.jobsninja.com/"
                    className="text-blue-500 underline"
                    target="_blank"
                  >
                    JobsNinja.com
                  </a>{" "}
                  (the 'JobsNinja') and all other services made available to you
                  through the JobsNinja online tools including the Website,
                  Client and Worker mobile apps. PLEASE READ THESE TERMS AND
                  CONDITIONS CAREFULLY BEFORE USING THE JobsNinja. By using
                  JobsNinja, you agree to be bound by these Terms and Conditions
                  which constitute a contract between you and JobsNinja, Suite
                  626, Waterman Caribbean Park, 44 Lakeview Drive, Scoresby,
                  Victoria 3179 ('we' or 'us'). If you do not agree to all of
                  the provisions contained in these Terms and Conditions, DO NOT
                  use the JobsNinja website or JobsNinja Client or Worker Mobile
                  App. We reserve the right to revise and update these Terms and
                  Conditions at any time. Please review and accept when you are
                  prompted through in-app notifications. Your continued use of
                  the JobsNinja constitutes your acceptance of and agreement to
                  any revised Terms and Conditions.
                  <br />
                  <br />
                  Please also see our{" "}
                  <a
                    href="https://www.jobsninja.com/privacy"
                    className="text-blue-500 underline"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-bold mt-4">General</h3>
                <p>
                  It is a condition of these Terms and Conditions that all
                  information or content you post and/or submit to be posted or
                  used on the JobsNinja, including all advertisements, photos
                  and creative designs, are either your works or works that you
                  are using with the permission of the owner. Subject to any
                  other clauses of these Terms and Conditions, by submitting
                  information or content (including advertisements, photos and
                  designs) to any part of the JobsNinja you automatically grant
                  to us, or warrant that the owner of such information has
                  expressly granted to us, a royalty-free, perpetual,
                  irrevocable, worldwide non-exclusive license to use,
                  reproduce, create derivative works from, modify, publish,
                  edit, translate, distribute, disseminate, communicate,
                  perform, and display your name and the information alone or as
                  part of other works in any form, media, or technology whether
                  now known or hereafter developed. You warrant that any and all
                  information you post to the JobsNinja (i) complies with all
                  relevant laws; (ii) does not infringe the intellectual
                  property rights (including but not limited to copyright and
                  trade marks) of any person; (iii) is not misleading or
                  deceptive nor likely to mislead or deceive; and (iv) does not
                  violate any privacy laws or regulations or confidentiality
                  restrictions. You warrant that any and all information
                  submitted by you and posted on the JobsNinja, including in any
                  directories, is true, complete and correct.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Ratings</h2>
                <p>
                  We may, from time to time, allow users who engage with Workers
                  through the website, Client and Worker mobile apps to rate
                  their experience with that Worker. JobsNinja entrusts our
                  users to leave their feedback at their discretion. We may
                  verify that feedback comes from users who engaged with
                  JonNinja Client and Worker mobile apps if a dispute or request
                  for review arises. The ratings are a collation of user
                  feedback and do not constitute an endorsement or
                  recommendation by JobsNinja in connection with any Worker.
                  Ratings are not a substitute for making your own inquiries as
                  to the suitability of any Worker that you are considering
                  hiring.
                </p>

                <br />

                <p>
                  You acknowledge that in submitting any rating feedback you
                  will act honestly and fairly and that you will not do anything
                  which might unfairly damage the reputation of a Worker or
                  undermine the operation of the ratings system. We reserve the
                  right to remove feedback from the star rating system that we
                  deem to be inappropriate and deceptive.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">
                  Copyright In Reviews and Comments
                </h2>
                <p>
                  By accepting these Terms and Conditions when registering your
                  account, you hereby assign to us all present and future
                  copyright in all of the original content submitted or posted
                  by you to the JobsNinja including without limitation:
                  comments, forum posts, public messages, reviews, ratings and
                  the compilation of any inspiration board (but not including
                  the contents of any advertisement, advertiser’s profile, or
                  any ‘get a quote’ submission). You also irrevocably authorise
                  us to use the content in any way we please, including in a way
                  that you might consider to infringe your moral rights in the
                  content. For the avoidance of doubt, nothing in this paragraph
                  requires you to create an account in order to be bound by
                  these Terms and Conditions, and your use of the JobsNinja
                  constitutes your agreement to these Terms and Conditions.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Review of Submissions</h2>
                <p>
                  Files that you upload, public messages that you send and your
                  activity in dialogue, discussion, and forums on the JobsNinja
                  are subject to review, modification and deletion without
                  notice by the discussion or forum manager. Files that you
                  upload to a bulletin board are subject to posted limitations
                  on usage, reproduction and/or dissemination and you are
                  responsible for adhering to such limitations. You should be
                  aware that personally identifiable information you choose to
                  disclose on the JobsNinja may be used by third parties and
                  such use is beyond our control.
                </p>
                <br />

                <p>
                  You must not post any information which (i) is libellous,
                  defamatory, obscene, offensive, sexually explicit, fraudulent,
                  false, unlawful, or contrary to the ownership or intellectual
                  property rights of any other person, or (ii) contains any
                  virus, worm, trojan or other code which is contaminating or
                  destructive to the files, data or programs of the JobsNinja or
                  any of our users.
                </p>
                <br />

                <p>
                  We may deny you access to all or part of the JobsNinja and/or
                  suspend or cancel your service account without notice if we,
                  in our reasonable discretion, determine that you have engaged
                  in conduct that violates any law or any provision of these
                  Terms and Conditions or is otherwise inappropriate. We will
                  not be liable for any loss of profit or business opportunity
                  that may result from the suspension or cancellation of your
                  service account.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Copyright and TradeMarks</h2>
                <p>
                  The entire contents and design of the JobsNinja, including all
                  trademarks, text, images and audio and video files, is
                  proprietary to our content providers or us and is protected by
                  copyright laws. The JobsNinja is for your personal,
                  non-commercial use. You may not reproduce, modify, copy,
                  distribute, transmit, communicate, display, publish or use any
                  material contained in the JobsNinja and/or our e-newsletters
                  without our express prior written permission or the permission
                  of the relevant copyright owner. You may not use any of the
                  information or content on the JobsNinja for commercial
                  purposes or to establish, operate or maintain your own product
                  or service offering. You acknowledge that any breach by you of
                  this paragraph may cause damage to us which cannot adequately
                  be remedied by damages and that if you breach or act in a way
                  that threatens to breach this paragraph, we may seek
                  injunctive relief against you.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">General Disclaimer</h2>
                <p>
                  Under the Australian Consumer Law, you are entitled to certain
                  guarantees in relation to our supply of the JobsNinja.com
                  website, Client and Worker mobile apps which cannot be
                  excluded, restricted or modified (Consumer Guarantees). To the
                  extent permitted by law, our liability for failure to comply
                  with a Consumer Guarantee is limited (at our option) to: (a)
                  in the case of goods supplied to you, to the replacement of
                  the goods or the supply of equivalent goods (or the payment of
                  the cost to you of the replacement or supply), or the repair
                  of the goods (or the payment of the cost to you of the repair)
                  and (b) in the case of services supplier to you, the supply of
                  the services again, or the payment of the cost of having the
                  services supplied again
                </p>
                <br />

                <p>
                  Subject to the Consumer Guarantees: (a) the JobsNinja is
                  provided on an 'as is, with all faults and as available' basis
                  and to the extent permitted by law without any warranties of
                  any kind, either expressed or implied, including without
                  limitation any implied warranties of merchantability, fitness
                  for a particular purpose, warranties of title or
                  non-infringement, or warranties arising from course of dealing
                  or custom of trade; (b) we make no representation or warranty
                  that any content of the JobsNinja is accurate, complete,
                  appropriate, reliable or timely; and (c) we also make no
                  representation or warranty that your access to and use of the
                  JobsNinja will be uninterrupted, secure, error-free, free of
                  viruses or unauthorised code or other harmful components. We
                  reserve the right to discontinue operating the JobsNinja at
                  any time without notice.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Use of the JobsNinja</h2>
                <p>
                  Except as required by the Consumer Guarantees, your use of the
                  JobsNinja is at your own risk. You are responsible for taking
                  all precautions you believe necessary or advisable to protect
                  you against any claim, damage, loss or hazard that may arise
                  by virtue of your use of the JobsNinja. Neither we nor anyone
                  else involved in creating, producing or delivering the
                  JobsNinja, our e-newsletters, or the materials contained
                  therein assumes any liability or responsibility for the
                  accuracy, completeness or usefulness of any information
                  provided therein, nor, to the extent permitted by law, shall
                  any of them be liable for any direct, indirect, incidental,
                  special, consequential or punitive damages arising out of your
                  use of, or inability to use, the JobsNinja
                </p>
                <br />

                <p>
                  You must not use any means of automatically searching or
                  mining data from the JobsNinja or in any way interfere or
                  attempt to interfere with the proper operation of the
                  JobsNinja.
                </p>
                <br />

                <p>
                  You agree not to take any action that imposes an unreasonable
                  burden on our infrastructure or otherwise tampers or
                  interferes with the JobsNinja, our systems or data or those of
                  any third party via the JobsNinja.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Scope of Jobs Ninja</h2>
                <h3 className="text-xl font-bold mt-4">Basic Principles</h3>
                <ul className="list-disc pl-8">
                  <li>
                    JobsNinja provides the JobsNinja Platform to enable
                    Customers to publish jobs under predefined categories with
                    the details of their requirements.
                  </li>
                  <li>
                    JobsNinja would notify the Workers based on the search
                    criteria of the available requirement of the customer.
                  </li>
                  <li>
                    Workers may express their Interest in response to a Posted
                    job/task.
                  </li>
                  <li>
                    When a client searches for a Worker or Worker makes an
                    interest in a job , the JobsNinja Platform may share the
                    Worker's contact details with the client.
                  </li>
                  <li>
                    Customers can review the profiles of the Service providers
                    who have expressed their interest in the job posted.
                  </li>
                  <li>
                    Customers and Workers can discuss the job details via an
                    inbuilt messaging service or contact Worker directly to
                    clarify scope of the job, budget, time frames and other
                    job-related information.
                  </li>
                  <li>
                    JobsNinja reserves the right to cancel all Offers on a
                    Posted Task.
                  </li>
                  <li>
                    Workers can remove a job offer from a customer if they no
                    longer wish to continue the job.
                  </li>
                  <li>
                    JobsNinja will not be liable for any deliverables of both
                    Worker and customer parties including but not limited to
                    time frame, scope of work, quality of workmanship, quality
                    of materials and payments.
                  </li>
                  <li>
                    Once the Task or Job is awarded by the Customer, the Worker
                    and Customer may vary the Task or Job scope, time or
                    fees/charges on the JobsNinja Platform. The Customer and
                    Worker are required to use JobsNinja's private messaging
                    system to amend or vary the Task or Job (including the
                    Agreed Price) or to otherwise communicate.
                  </li>
                  <li>
                    Once the Services are complete, both the Customer and the
                    Worker must confirm that on the JobsNinja respective mobile
                    apps.
                  </li>
                  <li>
                    Following the agreed Task or Job has been completed, the
                    Worker Service Fee will be payable by the Customer and
                    JobsNinja shall not be liable for any payment disputes.
                  </li>
                  <li>
                    After the Task or Job Contract is completed, the customer is
                    encouraged to review and provide feedback on the Services
                    provided by the Worker.
                  </li>
                </ul>

                <h3 className="text-xl font-bold mt-4">Worker Listing</h3>
                <ul className="list-disc pl-8">
                  <li>
                    JobsNinja may also provide a Worker Listing feature enabling
                    Workers to publish Offers for Services.
                  </li>
                  <li>
                    JobsNinja may publish Worker Listings from time to time in
                    its absolute discretion.
                  </li>
                  <li>
                    Worker may revoke or modify its Worker Listing at any time
                    before a Client accepts a Worker's offer.
                  </li>
                </ul>

                <h3 className="text-xl font-bold mt-4">
                  JobsNinja's Role & Obligations
                </h3>
                <ul className="list-disc pl-8">
                  <li>
                    JobsNinja provides a platform for the Customers and Workers
                    to find, connect, and communicate inorder to offer Workers
                    services to the customers.
                  </li>
                  <li>
                    JobsNinja only permits individuals over 18 years of age to
                    become Users.
                  </li>
                  <li>
                    Users must be natural persons, but can specify within their
                    account description that they represent a business entity..
                  </li>
                  <li>
                    In its absolute discretion, JobsNinja may refuse to allow
                    any person to become a User or cancel or suspend or modify
                    any existing account including if JobsNinja reasonably forms
                    the view that a User's conduct (including a breach of this
                    Agreement) is detrimental to the operation of the JobsNinja
                    Platform.
                  </li>
                  <li>
                    No charges apply in respect of the following actions on the
                    JobsNinja Platform:
                    <ul className="list-disc pl-8">
                      <li>Registering and creating an account;</li>
                      <li>Creating a Task or Job post;</li>
                      <li>
                        Users accessing and reviewing content on the JobsNinja
                        Platform, including their own posted jobs.
                      </li>
                    </ul>
                  </li>
                  <li>
                    JobsNinja accepts no liability for any aspect of the
                    Customer and Worker interaction, including but not limited
                    to the description, performance or delivery of Services.
                  </li>
                  <li>
                    JobsNinja is not responsible for, and does not warrant the
                    truth or accuracy of any aspect of any information provided
                    by Users, including, but not limited to, the ability of
                    Workers to perform jobs or supply items, or the honesty or
                    accuracy of any information provided by Customers or the
                    Customers' ability to pay for the Services requested.
                  </li>
                  <li>
                    Except for liability in relation to any Non-excludable
                    Condition, the JobsNinja Service is provided on an "as is"
                    basis, and without any warranty or condition, express or
                    implied. To the extent permitted by law, we and our
                    suppliers specifically disclaim any implied warranties of
                    title, merchantability, fitness for a particular purpose and
                    non-infringement.
                  </li>
                  <li>
                    JobsNinja has no obligation to any User to assist or involve
                    itself in any dispute between Users, although may do so to
                    improve the User experience.
                  </li>
                  <li>
                    You understand and agree that:
                    <ul className="list-disc pl-8">
                      <li>
                        JobsNinja does not undertake any investigation in
                        relation to any Worker or third party Worker before they
                        become a User, including criminal checks, verification
                        of qualification or licence held, or any character or
                        other checks of the suitability of a Worker or third
                        party Worker to perform any task which they may claim to
                        be able to provide on the platform;
                      </li>
                      <li>
                        If a Worker requests verification via the JobsNinja app,
                        JobsNinja may initiate a Know Your Customer (KYC)
                        verification process. This may entail submitting
                        government-issued identification documents and other
                        relevant information for identity verification purposes.
                        Workers who successfully complete the KYC process will
                        be labeled as 'verified Worker' on the platform.
                      </li>
                    </ul>
                  </li>
                  <li>
                    You are solely responsible for conducting any appropriate
                    background checks and obtaining references, licences,
                    certifications, or proof of insurance prior to engaging a
                    Worker to perform services;
                  </li>
                  <li>
                    You are solely responsible for making your own evaluations,
                    decisions and assessments about choosing a Worker; and
                    JobsNinja shall not be liable for verification of user
                    credentials such as but not limited to, accreditations,
                    certifications, certifications, licensing requirements
                    clearance etc.
                  </li>
                  <li>
                    Assume all risks and you agree to expressly release,
                    indemnify and hold harmless JobsNinja from any and all loss,
                    liability, injury, death, damage, or costs arising or in any
                    way related to the services.
                  </li>
                </ul>

                <h3 className="text-xl font-bold mt-4">User Obligations</h3>
                <ul className="list-disc pl-8">
                  <li>
                    You will at all times:
                    <ul className="list-disc pl-8">
                      <li>
                        (a) comply with this Agreement (including all Policies)
                        and all applicable laws and regulations
                      </li>
                      <li>
                        (b) only post accurate information on the JobsNinja
                        Platform;
                      </li>
                      <li>
                        (c) ensure that You are aware of any laws that apply to
                        You as a Client or Worker, or in relation to Your use of
                        the JobsNinja Platform.
                      </li>
                    </ul>
                  </li>
                  <li>
                    You agree that any content (whether provided by JobsNinja, a
                    User or a third party) on the JobsNinja Platform may not be
                    used on third party sites or for other business purposes
                    without JobsNinja's prior written permission.
                  </li>
                  <li>
                    You must not use the JobsNinja Platform for any illegal or
                    immoral purpose.
                  </li>
                  <li>
                    You must maintain control of Your JobsNinja account at all
                    times. This includes not allowing others to use Your
                    account, or by transferring or selling Your account or any
                    of its content to another person.
                  </li>
                  <li>
                    You grant JobsNinja an unrestricted, worldwide, royalty-free
                    licence to use, reproduce, modify and adapt any content and
                    information posted on the JobsNinja Platform for the purpose
                    of publishing material on the JobsNinja Platform and as
                    otherwise may be required to provide the JobsNinja Service,
                    for the general promotion of the JobsNinja Service, and as
                    permitted by this Agreement.
                  </li>
                  <li>
                    You agree that any information posted on the JobsNinja
                    Platform must not, in any way whatsoever, be potentially or
                    actually harmful to JobsNinja or any other person. Harm
                    includes, but is not limited to, economic loss that will or
                    may be suffered by JobsNinja.
                  </li>
                  <li>
                    Without limiting any provision of this Agreement, any
                    information You supply to JobsNinja or publish in an Offer
                    or a Posted Task or Job (including as part of an Offer) must
                    be up to date and kept up to date and must not:
                    <ul className="list-disc pl-8">
                      <li>
                        (a) be false, inaccurate or misleading or deceptive;
                      </li>
                      <li>
                        (b) be fraudulent or involve the sale of counterfeit or
                        stolen items;
                      </li>
                      <li>
                        (c) infringe any third party's copyright, patent,
                        trademark, trade secret or other proprietary rights or
                        intellectual property rights, rights of publicity,
                        confidentiality or privacy;
                      </li>
                      <li>
                        (d) violate any applicable law, statute, ordinance or
                        regulation (including, but not limited to, those
                        governing export and import control, consumer
                        protection, unfair competition, criminal law,
                        antidiscrimination and trade practices/fair trading
                        laws);
                      </li>
                      <li>
                        (e) be defamatory, libellous, threatening or harassing;
                      </li>
                      <li>
                        (f) be obscene or contain any material that, in
                        JobsNinja's sole and absolute discretion, is in any way
                        inappropriate or unlawful, including, but not limited to
                        obscene, inappropriate or unlawful images; or
                      </li>
                      <li>
                        (g) contain any malicious code, data or set of
                        instructions that intentionally or unintentionally
                        causes harm or subverts the intended function of any
                        JobsNinja Platform, including, but not limited to
                        viruses, trojan horses, worms, time bombs, cancelbots,
                        easter eggs or other computer programming routines that
                        may damage, modify, delete, detrimentally interfere
                        with, surreptitiously intercept, access without
                        authority or expropriate any system, data or Personal
                        Information.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The JobsNinja Platform may from time to time engage
                    location-based or map-based functionality. The JobsNinja
                    Platform may display the location of Clients and Workers to
                    persons browsing the JobsNinja Platform. A User should never
                    disclose personal details such as the Client's full name,
                    street number, phone number or email address in a Posted
                    Task or Job or in any other public communication on the
                    JobsNinja Platform.
                  </li>
                  <li>
                    Once the job is awarded, JobsNinja will disclose the contact
                    details including phone number, email address.
                  </li>
                  <li>
                    If You are a Worker, You must have the right, license or all
                    other necessary skills (as applicable) to provide the
                    relevant Services contracted under a Task or Job Contract
                    and the right to work in the jurisdiction where the Services
                    are performed. You must comply with tax and regulatory
                    obligations in relation to any payment received under a Task
                    or Job Contracted.
                  </li>
                  <li>
                    If a Worker agrees to pay some costs of completing the
                    Services (such as equipment to complete the Services), the
                    Worker is solely responsible for obtaining any reimbursement
                    from the Client. Correspondence in respect of agreeing to
                    these additional costs should be carried out on the
                    JobsNinja Platform messaging Service. JobsNinja advises
                    Workers not to agree to incur costs in advance of receiving
                    the payment for these costs, unless the Worker is confident
                    the Client will reimburse the costs promptly.
                  </li>
                  <li>
                    For the proper operation of the JobsNinja Platform
                    (including insurance, proper pricing and compliance with
                    Policies), the Worker must ensure that, provided the Client
                    provides its prior written consent to the subcontracting of
                    any part of the performance of the Task or Job Contract to a
                    third party, then that third party must also be a registered
                    User of the JobsNinja Platform.
                  </li>
                  <li>
                    If JobsNinja determines at its sole discretion that you have
                    breached any obligation under this terms and conditions,
                    breached any law/act unlawfully or that you have failed to
                    meet one or more task obligations including, it reserves the
                    rights to remove any content, posted Task or Job or Offer
                    You have submitted to the JobsNinja Service or cancel or
                    suspend Your account and/or any Task or Job Contracts.
                  </li>
                  <li>
                    Our mobile apps leverage Amazon Pinpoint SMS and Voice API
                    resources to facilitate the delivery of SMS messages and
                    voice calls, serving various purposes including account
                    verification, notifications, and promotional communications.
                    Your use of our application implies consent to the
                    utilization of these services. For more information
                    regarding data protection guidelines concerning Amazon
                    Pinpoint SMS and Voice API resources, please refer to the
                    following link:
                    <a
                      href="https://docs.aws.amazon.com/pinpoint/latest/developerguide/security-data-protection.html"
                      target="_blank"
                    >
                      Data protection guidelines for Amazon Pinpoint SMS and
                      Voice API Resources.'
                    </a>
                  </li>
                </ul>

                <h3 className="text-xl font-bold mt-4">Monthly Subscription</h3>
                <ul className="list-disc pl-8">
                  <li>
                    A Subscription Fee is payable to JobsNinja by the Worker in
                    respect of using the platform to find job/task leads.
                  </li>
                  <li>
                    No subscription fee will be charged for Client, and Clients
                    will not incur any charges for using the application.
                  </li>
                  <li>
                    By subscribing to our service, you agree to the terms of the
                    specific subscription plan you select.
                  </li>
                  <li>
                    Our subscription will automatically renew on a monthly
                    basis, starting from the date of your initial purchase,
                    unless canceled in accordance with these terms.
                  </li>
                  <li>
                    Subscription recurring fees are billed on the start date of
                    your next billing cycle. We may change our fees from time to
                    time, and such changes will be communicated to you.
                  </li>
                  <li>
                    You may cancel your subscription at any time through our
                    website. Cancellation will be effective at the end of the
                    current billing period.
                  </li>
                  <li>
                    All subscription payments to JobsNinja are non-cancellable
                    and non-refundable.
                  </li>
                  <li>
                    If your payment method is declined or if you fail to pay any
                    amount due, we may limit your access to the service.
                  </li>
                  <li>
                    JobsNinja reserves the right to amend the amount of any Fees
                    from time to time and any changes will be updated on the
                    JobsNinja website and mobile applications.
                  </li>
                  <li>
                    We strive to provide uninterrupted service but are not
                    liable for any temporary interruptions or outages
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Payment Facility</h2>
                <p>
                  JobsNinja uses a third-party payment Provider to operate the
                  Payment Account.
                </p>
                <p>
                  In so far as it is relevant to the provision of the Payment
                  Account, the terms at{" "}
                  <a
                    href="https://stripe.com/ssa/"
                    target="_blank"
                    className="text-blue-600 underline"
                  >
                    https://stripe.com/ssa/
                  </a>{" "}
                  are incorporated into this Agreement and will prevail over
                  this Agreement to the extent of any inconsistency in relation
                  to the provision of the Payment Account.
                </p>
                <p>
                  If JobsNinja changes its Payment Provider You may be asked to
                  agree to any further additional terms with those providers. If
                  you do not agree to them, you will be given alternative means
                  of payment.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Disclaimer</h2>
                <p>
                  We strive to provide valuable and accurate information. The
                  editors of the material contained in the JobsNinja have
                  consulted sources believed to be reliable in their efforts to
                  provide information that is correct at the time of posting.
                  However, in view of the possibility of error by the authors,
                  editors, or publishers of the works contained in the JobsNinja
                  neither we nor any other party involved in the preparation of
                  material contained in the JobsNinja or in our e-newsletters
                  represents or warrants that the information contained therein
                  is in every respect accurate or complete, and, to the extent
                  permitted by law, they assume no responsibility for any errors
                  or omissions or for the results obtained from the use of such
                  material. You are encouraged to confirm the information
                  contained therein with other sources.
                </p>
                <br />
                <p>
                  Consumers reading articles or other material posted on
                  JobsNinja or e-newsletters should review the information
                  carefully. The information is not intended in any way to be a
                  substitute for professional advice. Neither the content nor
                  any other service offered through JobsNinja or our
                  e-newsletters is intended as professional advice.
                </p>
                <br />

                <p>
                  We do not examine, determine or warrant the certification
                  and/or licensing, competence, solvency or information of any
                  professional or facility listed in our directories. We rely on
                  the professionals or the facilities listed in our directories
                  to provide accurate information and assume no responsibility
                  for verifying the information provided. Use of our directories
                  to locate a professional or facility is wholly voluntary and,
                  to the extent permitted by law, in no event will we or any of
                  our agents be liable for damages to any user of our
                  directories for the selection of a professional or facility or
                  for the services provided by any professional or facility
                  listed therein, or for any other loss or damage which may
                  occur as a result thereof. We recommend that you check the
                  certification and/or licensing of any professional or other
                  Worker with the applicable licensing Board or authority.
                </p>
                <br />

                <p>
                  You should satisfy yourself with the competence, solvency,
                  insurance and licensing of anybody you decide to retain as a
                  result of a connection made through our services, including
                  the post a job / Get Quotes service. Under no circumstances
                  shall we be liable for any damages whatso
                </p>
                <br />

                <p>
                  ever as a result of losses caused to you by a person or entity
                  that you retain as a consequence of using our directory or
                  services.
                </p>
                <br />

                <p>
                  The JobsNinja may contain links to websites, Client and Worker
                  mobile app operated by third parties. The linked sites are not
                  under our control and we are not responsible for the contents
                  of any linked site or any link contained in a linked site or
                  any changes or updates to such sites. The appearance of any
                  product, service or website, Client and Worker mobile apps
                  link on the JobsNinja and/or in our e-newsletters does not
                  imply endorsement, approval or warranty by us and we disclaim
                  all liability concerning any such products, services or
                  website, Client and Worker mobile apps links.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">
                  Designated Copyright Representative
                </h2>
                <p>
                  The Copyright Regulations 1969 (Cth) establish a safe harbour
                  scheme which limits the remedies available against carriage
                  Workers for copyright infringement relating to certain online
                  activities. Our designated representative to receive
                  notifications and notices issued under that scheme in relation
                  to all activities carried out by us as a carriage Worker is:
                </p>
                <br />

                <ul className="list-disc pl-6">
                  <li>
                    Email:
                    <a
                      href="mailto:support@jobsninja.com"
                      className="text-blue-500 underline"
                      target="_blank"
                    >
                      support@jobsninja.com
                    </a>
                  </li>
                  <li>
                    Postal address: Suite 115, Waterman Caribbean Park,44
                    Lakeview Drive Scoresby, Victoria 3179, Australia
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Indemnification</h2>
                <p>
                  You agree to indemnify, defend and hold harmless us and our
                  officers, directors, employees, agents, information providers,
                  partners, advertisers, licensors and suppliers from and
                  against all losses, expenses, damages and costs, including
                  reasonable solicitor's fees, resulting from any violation of
                  these Terms and Conditions or any activity related to your
                  service account (including infringement of third parties
                  intellectual property rights anywhere in the world or
                  negligent or wrongful conduct) by you or any other person
                  accessing the JobsNinja using your service account. This
                  indemnity does not apply to the extent that we acted
                  negligently or wilfully engaged in misconduct.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">
                  Waiver, Release, and Limitation of Liability
                </h2>
                <p>
                  Except in respect of the Consumer Guarantees and to the extent
                  permitted by law, you agree that neither we nor our officers,
                  directors, employees, agents, information providers, partners,
                  advertisers, licensors or suppliers shall have any liability
                  to you under any theory of liability or indemnity in
                  connection with your use of the JobsNinja. Our liability for
                  any claim arising from a breach of any term or condition
                  implied by any law which may not be excluded (including,
                  without limitation, the Consumer Guarantees) will be limited
                  to the maximum extent permissible which, in the case of
                  services, will be to the re-supply of the relevant services or
                  the payment of the cost of having the relevant services
                  re-supplied.
                </p>
                <br />

                <p>
                  Notwithstanding the foregoing paragraph, except in respect of
                  the Consumer Guarantees and to the extent permitted by law,
                  the total liability of us, our officers, directors, employees,
                  agents, information providers, partners, advertisers,
                  licensors and suppliers,. Except where such limitation is
                  prohibited by law, in no event shall we, our officers,
                  directors, employees, agents, information providers, partners,
                  advertisers, licensors or suppliers be liable to you for any
                  loss or damage other than the amount referred to above, and
                  their liability for all other loss or damage, whether direct
                  or indirect, special, incidental, consequential or punitive,
                  arising from any use of the JobsNinja is hereby excluded even
                  if we or our officers, directors, employees, agents,
                  information providers, partners, advertisers, licensors or
                  suppliers have been advised of the possibility of such
                  damages.
                </p>
                <br />

                <p>
                  Except in relation to a Consumer Guarantee, neither we, nor
                  any of our affiliates, directors, officers or employees, nor
                  any third party vendor will be liable or have any
                  responsibility of any kind for any loss or damage that you
                  incur in the event of any failure or interruption of the
                  JobsNinja, or resulting from the act or omission of any other
                  party involved in making the JobsNinja or the information
                  contained therein available to you, or from any other cause
                  relating to your access to or your inability to access the
                  JobsNinja or that information, except to the extent we acted
                  negligently or engaged in wilful misconduct.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Third Party Rights</h2>
                <p>
                  The provisions of Indemnification and the Waiver, Release and
                  Limitation of Liability are for our benefit and the benefit of
                  our officers, directors, employees, agents, partners,
                  advertisers, information providers, licensors and suppliers.
                  Each of these individuals or entities shall have the right to
                  assert and enforce those provisions directly against you on
                  its behalf.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">
                  Mediation And Dispute Resolution
                </h2>
                <p>
                  JobsNinja encourages You to try and cooperate with the other
                  Users to try and make a genuine attempt to resolve disputes
                  (including claims for returns or refunds) with other Users
                  directly. Accordingly, You acknowledge and agree that
                  JobsNinja may, in its absolute discretion, share Your
                  information with other parties involved in the dispute.
                </p>
                <br />

                <p>
                  JobsNinja shall not be part of or liable for any disputes
                  between the Workers and clients. However, JobsNinja may elect
                  to assist Users resolve disputes. Any User may refer a dispute
                  to JobsNinja. You must cooperate with any investigation
                  undertaken by JobsNinja. You may raise your dispute with the
                  other User or JobsNinja's determination in an applicable court
                  or tribunal.
                </p>
                <br />

                <p>
                  JobsNinja users can utilise the services of a Third Party
                  Dispute Service. If such a service is utilised, either party
                  may request the other party to submit to the Third Party
                  Dispute Service if the parties have failed to resolve the
                  dispute directly. Terms and conditions for the Third-Party
                  Dispute Service will be available on request. The Third-Party
                  Dispute Service is a Third Party Service and Users are
                  responsible for paying any costs associated with the Third
                  Party Dispute Service in accordance with the Third Party
                  Dispute Service terms and conditions.
                </p>
                <br />

                <p>
                  Disputes with any Third Party Service provider must proceed
                  pursuant to any dispute resolution process set out in the
                  terms of service of the Third Party Service provider.
                </p>
                <br />

                <p>
                  If You have a complaint about the JobsNinja Service please
                  fill out the Contact Us form in the help section of the app.
                </p>
                <br />

                <p>
                  If JobsNinja provides information about other Users to You for
                  the purposes of resolving disputes under this clause, You
                  acknowledge and agree that such information will be used only
                  for the purpose of resolving the dispute.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold">Miscellaneous</h2>
                <p>
                  Advertisers and sponsors of JobsNinja and/or its e-newsletters
                  do not influence editorial content or presentation.
                </p>
                <br />

                <p>
                  You may not assign or transfer any of your rights or
                  obligations under these Terms and Conditions without our prior
                  written consent.
                </p>
                <br />

                <p>
                  Our relationship with you under these Terms and Conditions is
                  one of independent contractors and not of partnership, joint
                  venture or principal and agent.
                </p>
                <br />

                <p>
                  We will send any legal notices or communications regarding
                  your service account to your nominated email address. Please
                  ensure that a valid email address is recorded at all times.
                  Your primary point of contact with us should be via the
                  'Contact Us' facility on the JobsNinja. Any formal notices or
                  communications should be sent to our postal address set out at
                  the beginning of these Terms and Conditions. A notice sent to
                  you by email or facsimile will be deemed to have been received
                  by you 24 hours after it was sent, regardless of whether or
                  not you have read the notice. Notices sent by mail will be
                  deemed to have been received 3 days after the date of mailing
                  (7 days if sent to or from outside Australia).
                </p>
                <br />

                <p>
                  If any clause or provision of these Terms and Conditions is
                  determined to be illegal, invalid, unenforceable or capable of
                  termination by a party in any jurisdiction in which these
                  Terms and Conditions are to operate, then such clause or
                  provision will be construed, to the extent feasible, to render
                  the clause or provision enforceable. If no feasible
                  interpretation would save such clause or provision, it will be
                  severed from the remainder of these Terms and Conditions
                  without affecting the enforceability of all remaining clauses
                  and provisions.
                </p>
                <br />

                <p>
                  These Terms and Conditions are governed by the laws of
                  Victoria, Australia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
