import React from "react";

const PinLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.501 4.48047C8.082 4.48047 4.5 8.06247 4.5 12.4795C4.5 15.7565 6.47 18.5705 9.289 19.8075C9.265 19.2485 9.284 18.5785 9.427 17.9715C9.582 17.3215 10.457 13.6115 10.457 13.6115C10.457 13.6115 10.202 13.1005 10.202 12.3455C10.202 11.1595 10.889 10.2745 11.744 10.2745C12.473 10.2745 12.823 10.8215 12.823 11.4755C12.823 12.2065 12.357 13.3015 12.118 14.3145C11.917 15.1635 12.543 15.8555 13.379 15.8555C14.896 15.8555 15.917 13.9085 15.917 11.6025C15.917 9.84947 14.736 8.53647 12.588 8.53647C10.161 8.53647 8.65 10.3465 8.65 12.3675C8.65 13.0645 8.854 13.5565 9.177 13.9365C9.324 14.1115 9.345 14.1825 9.291 14.3825C9.253 14.5295 9.165 14.8845 9.128 15.0245C9.075 15.2275 8.911 15.2995 8.728 15.2255C7.61 14.7685 7.09 13.5455 7.09 12.1685C7.09 9.89647 9.007 7.17147 12.807 7.17147C15.862 7.17147 17.873 9.38147 17.873 11.7535C17.873 14.8925 16.127 17.2375 13.555 17.2375C12.691 17.2375 11.879 16.7695 11.601 16.2395C11.601 16.2395 11.136 18.0835 11.038 18.4385C10.868 19.0545 10.536 19.6725 10.233 20.1525C10.969 20.3705 11.733 20.4805 12.501 20.4805C16.919 20.4805 20.5 16.8985 20.5 12.4795C20.5 8.06247 16.919 4.48047 12.501 4.48047Z"
        fill="#808080"
      />
    </svg>
  );
};

export default PinLogo;
