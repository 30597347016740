import React from "react";

const NavBarFaceLogo = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.0256348"
        y="0.721436"
        width="43.5572"
        height="43.5572"
        rx="9.58258"
        fill="#E3E3E3"
      />
      <path
        d="M22.7429 37.6413C22.2202 37.7284 21.7846 37.38 21.8717 36.9444C21.8717 35.9861 21.8717 35.2021 21.8717 34.8536C21.8717 34.6794 21.6975 34.5923 21.6104 34.5923C19.6939 34.5923 17.8645 34.4181 15.3382 34.1567C11.7665 33.7212 8.36901 30.4108 7.58498 25.2711C7.14941 22.3092 6.88806 18.3019 7.58498 14.2075C8.10767 11.0714 10.2855 8.37089 15.3382 7.76109C20.3908 7.15129 22.7429 7.2384 28.144 7.8482C33.2837 8.37089 35.7229 10.8101 36.1585 14.2075C36.5941 17.5179 36.5069 21.7865 36.1585 25.2711C35.3744 31.8918 28.6666 36.5959 22.7429 37.6413Z"
        fill="#074924"
      />
      <path
        d="M32.674 16.2984L31.193 17.3438C27.9698 19.4345 24.8337 20.1314 21.7847 20.3057C18.7357 20.1314 15.5125 19.4345 12.3764 17.3438L10.8954 16.2984C9.5887 15.4273 8.63044 16.6469 9.15313 18.3891L10.2856 22.6577C10.4598 23.8773 11.331 24.7485 12.2021 25.0969C12.6377 25.2712 13.2475 25.3583 13.8573 25.3583C16.2965 25.3583 18.4744 23.5289 21.262 23.5289C23.9626 23.5289 27.0116 25.3583 29.4508 25.3583C30.0606 25.3583 30.6704 25.2712 31.1059 25.0969C31.9771 24.8356 32.8482 23.8773 33.1967 22.6577L34.3292 18.3891C34.939 16.734 33.8936 15.4273 32.674 16.2984Z"
        fill="#40D37B"
      />
      <path
        d="M12.3766 21.3513C12.3766 21.3513 13.5091 21.3513 14.7287 21.6998C15.687 21.9611 16.9937 22.8323 16.9937 22.8323C17.1679 23.0065 17.5164 22.9194 17.6035 22.658C17.6906 22.3967 17.6906 22.1354 17.4292 21.9611C17.3421 21.9611 15.9483 21.0029 14.9029 20.7415C13.5962 20.3931 12.4637 20.3931 12.3766 20.3931C12.1153 20.3931 11.941 20.6544 11.941 20.9158C11.941 21.1771 12.1153 21.3513 12.3766 21.3513Z"
        fill="#074924"
      />
      <path
        d="M30.4097 21.3513C30.4097 21.3513 29.2772 21.3513 28.0576 21.6998C27.0994 21.9611 25.7926 22.8323 25.7926 22.8323C25.6184 23.0065 25.27 22.9194 25.1828 22.658C25.0957 22.3967 25.0957 22.1354 25.3571 21.9611C25.4442 21.9611 26.838 21.0029 27.8834 20.7415C29.1901 20.3931 30.4097 20.3931 30.4968 20.3931C30.7582 20.3931 30.9324 20.6544 30.9324 20.9158C30.8453 21.1771 30.671 21.3513 30.4097 21.3513Z"
        fill="#074924"
      />
    </svg>
  );
};

export default NavBarFaceLogo;
