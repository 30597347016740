import React from "react";

const CalendarIconWhite = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2013_6790)">
        <path
          d="M8.59676 3.79619C8.76847 3.79619 8.94017 3.78828 9.11116 3.79619C9.40644 3.81343 9.53073 3.61801 9.52786 3.38308C9.52067 2.78103 9.52067 2.17899 9.52786 1.57694C9.52786 1.36859 9.4596 1.21772 9.25772 1.14587H8.49977C8.41968 1.1682 8.34951 1.21701 8.30072 1.28433C8.25192 1.35165 8.22737 1.43352 8.23108 1.51659C8.23108 2.15456 8.23108 2.79181 8.23108 3.42978C8.23323 3.6604 8.37045 3.79403 8.59676 3.79619Z"
          fill="white"
        />
        <path
          d="M2.83193 1.95908C2.83193 2.43803 2.83193 2.91699 2.83193 3.39595C2.83193 3.66896 2.95765 3.79468 3.22778 3.7954H3.72279C4.0001 3.7954 4.12439 3.67039 4.12439 3.39164C4.12439 2.786 4.12008 2.18107 4.12439 1.57543C4.12439 1.36565 4.05255 1.21621 3.8521 1.14868H3.09703C2.89515 1.22053 2.81971 1.37212 2.83121 1.57974C2.83767 1.70619 2.83193 1.83335 2.83193 1.95908Z"
          fill="white"
        />
        <path
          d="M11.0508 3.20559C11.0508 3.1826 11.0508 3.16033 11.0508 3.13734C11.0469 2.92188 10.9655 2.71504 10.8213 2.55483C10.6772 2.39461 10.4801 2.29176 10.2663 2.26516C10.1944 2.25654 10.1873 2.2745 10.1873 2.337C10.1873 2.69957 10.1873 3.06238 10.1873 3.42543C10.1912 3.55816 10.1684 3.69033 10.1201 3.81403C10.0718 3.93773 9.99912 4.05044 9.90631 4.1454C9.81349 4.24036 9.70248 4.31564 9.57991 4.36672C9.45734 4.41781 9.32573 4.44367 9.19295 4.44274C8.9925 4.44705 8.79134 4.44777 8.59018 4.44274C8.00681 4.42837 7.59155 4.0009 7.59012 3.41609C7.59012 3.05687 7.59012 2.69766 7.59012 2.33844C7.59012 2.27019 7.57359 2.25366 7.50534 2.25366C6.62741 2.25606 5.74948 2.25606 4.87155 2.25366C4.80474 2.25366 4.78534 2.26803 4.78606 2.33772C4.78965 2.69694 4.78606 3.05616 4.78606 3.41537C4.78606 4.00449 4.36577 4.43196 3.78025 4.44274C3.58915 4.44274 3.39804 4.44274 3.2055 4.44274C2.6092 4.43627 2.18891 4.01168 2.18748 3.41609C2.18748 3.09208 2.18748 2.7695 2.18748 2.44405C2.18748 2.22852 2.18748 2.22852 1.97841 2.29318C1.56459 2.42465 1.32607 2.73932 1.32535 3.17542C1.32535 5.50746 1.32535 7.83974 1.32535 10.1723C1.32098 10.3184 1.3523 10.4634 1.41659 10.5947C1.59549 10.9388 1.8843 11.0976 2.2665 11.0976H10.1075C10.234 11.1028 10.3601 11.0815 10.4779 11.0351C10.5956 10.9887 10.7024 10.9181 10.7913 10.828C10.8802 10.7379 10.9492 10.6302 10.9941 10.5118C11.0389 10.3935 11.0585 10.267 11.0515 10.1407C11.0496 7.83016 11.0494 5.51848 11.0508 3.20559ZM9.90133 9.69235C9.90133 10.0034 9.75764 10.1486 9.44296 10.1486H2.9095C2.60704 10.1486 2.45761 9.99912 2.45761 9.69451C2.45761 8.52537 2.45761 7.356 2.45761 6.18638C2.45761 5.88248 2.60632 5.7352 2.91166 5.7352H9.44224C9.75261 5.7352 9.89773 5.87889 9.89773 6.19357C9.89821 7.35983 9.89845 8.52609 9.89845 9.69235H9.90133Z"
          fill="white"
        />
        <path
          d="M3.25674 7.3129C3.25674 7.50976 3.35158 7.60746 3.54915 7.61034C3.81712 7.61393 4.08582 7.61465 4.35451 7.61034C4.54131 7.61034 4.64189 7.51407 4.64189 7.33015C4.64763 7.05475 4.64763 6.77911 4.64189 6.50323C4.64189 6.31787 4.53987 6.22519 4.35451 6.22375C4.08246 6.22375 3.81042 6.22375 3.53837 6.22375C3.35445 6.22375 3.25962 6.32218 3.25674 6.51113C3.25674 6.64548 3.25674 6.77982 3.25674 6.91345C3.25674 7.04708 3.25531 7.17856 3.25674 7.3129Z"
          fill="white"
        />
        <path
          d="M7.94097 7.60886C8.2202 7.61556 8.49943 7.61556 8.77867 7.60886C8.96259 7.60455 9.05095 7.50325 9.05311 7.31645C9.05311 7.18211 9.05311 7.04776 9.05311 6.91413C9.05311 6.7805 9.05311 6.65334 9.05311 6.52258C9.05311 6.5154 9.05311 6.50821 9.05311 6.50103C9.04736 6.32214 8.95397 6.22515 8.77507 6.22371C8.49967 6.22084 8.22403 6.22084 7.94815 6.22371C7.77357 6.22371 7.67299 6.31567 7.66796 6.4881C7.66078 6.77044 7.6615 7.05423 7.66796 7.33657C7.66464 7.37322 7.66943 7.41016 7.68198 7.44475C7.69454 7.47934 7.71456 7.51075 7.74061 7.53674C7.76667 7.56273 7.79813 7.58266 7.83275 7.59512C7.86738 7.60759 7.90433 7.61228 7.94097 7.60886Z"
          fill="white"
        />
        <path
          d="M6.56845 6.22314C6.43123 6.22314 6.29329 6.22314 6.15535 6.22314C6.01741 6.22314 5.88019 6.22314 5.74225 6.22314C5.5612 6.22314 5.46134 6.32444 5.45919 6.51052C5.45775 6.77969 5.45703 7.05006 5.45703 7.32163C5.45703 7.49981 5.55115 7.60398 5.72716 7.60901C6.00927 7.61667 6.29161 7.61667 6.5742 7.60901C6.75094 7.6047 6.84433 7.50196 6.84649 7.32163C6.84649 7.04647 6.85008 6.77131 6.84649 6.49615C6.84649 6.32301 6.74447 6.22602 6.56845 6.22314Z"
          fill="white"
        />
        <path
          d="M4.36731 8.42717C4.08808 8.4219 3.80884 8.4219 3.52961 8.42717C3.35288 8.42717 3.2602 8.5335 3.25732 8.71454C3.25732 8.84889 3.25732 8.98324 3.25732 9.11687C3.25732 9.2505 3.25732 9.39275 3.25732 9.53069C3.25732 9.71533 3.35503 9.81806 3.5368 9.81806C3.81268 9.82141 4.08832 9.82141 4.36372 9.81806C4.53614 9.81806 4.63744 9.72251 4.64175 9.5508C4.64846 9.26822 4.64846 8.98539 4.64175 8.70233C4.6453 8.66538 4.64064 8.6281 4.62811 8.59315C4.61558 8.5582 4.59549 8.52646 4.56928 8.50017C4.54306 8.47389 4.51136 8.45371 4.47645 8.4411C4.44154 8.42848 4.40427 8.42372 4.36731 8.42717Z"
          fill="white"
        />
        <path
          d="M6.56727 8.42731C6.29235 8.42299 6.01719 8.42299 5.74179 8.42731C5.56218 8.42731 5.4616 8.53435 5.46016 8.71468C5.45729 8.98625 5.45729 9.25782 5.46016 9.52939C5.46016 9.71474 5.56577 9.81676 5.75329 9.81676C5.88691 9.81676 6.02126 9.81676 6.15489 9.81676C6.28852 9.81676 6.42287 9.81676 6.5565 9.81676C6.74401 9.81676 6.84387 9.71546 6.8489 9.52939C6.85177 9.25782 6.85177 8.98625 6.8489 8.71468C6.84746 8.53292 6.75047 8.43018 6.56727 8.42731Z"
          fill="white"
        />
        <path
          d="M9.05305 8.71958C9.05305 8.52919 8.95606 8.43221 8.76568 8.42646C8.49363 8.42263 8.22158 8.42263 7.94953 8.42646C7.77064 8.42646 7.67078 8.52345 7.66647 8.7009C7.66 8.98396 7.66 9.26703 7.66647 9.54937C7.67078 9.72036 7.77352 9.81232 7.94666 9.81448C8.22254 9.81735 8.49818 9.81735 8.77358 9.81448C8.95032 9.81448 9.0473 9.71246 9.05018 9.53572C9.05018 9.39778 9.05018 9.25984 9.05018 9.1219C9.05018 8.98396 9.05449 8.85393 9.05305 8.71958Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2013_6790">
          <rect
            width="11.375"
            height="11.375"
            fill="white"
            transform="translate(0.5 0.4375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIconWhite;
