import React from "react";

const CloseIcon = ({ color }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.4997" cy="10.5" r="6.66667" stroke={color} />
      <path
        d="M12.1663 8.83339L8.83301 12.1667M8.83299 8.83337L12.1663 12.1667"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
