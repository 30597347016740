import React from "react";

const PersonIconGreenOutline = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.99998"
        cy="6.49992"
        r="2.66667"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M15.3334 14.1667C15.3334 15.8236 15.3334 17.1667 10 17.1667C4.66669 17.1667 4.66669 15.8236 4.66669 14.1667C4.66669 12.5099 7.0545 11.1667 10 11.1667C12.9455 11.1667 15.3334 12.5099 15.3334 14.1667Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PersonIconGreenOutline;
