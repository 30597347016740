import React from "react";

const DoneIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 9.99992C16.6667 13.6818 13.6819 16.6666 10 16.6666C6.31814 16.6666 3.33337 13.6818 3.33337 9.99992C3.33337 6.31802 6.31814 3.33325 10 3.33325C13.6819 3.33325 16.6667 6.31802 16.6667 9.99992ZM12.6869 7.9797C12.8822 8.17496 12.8822 8.49154 12.6869 8.68681L9.35359 12.0201C9.15833 12.2154 8.84175 12.2154 8.64649 12.0201L7.31315 10.6868C7.11789 10.4915 7.11789 10.175 7.31315 9.9797C7.50842 9.78444 7.825 9.78444 8.02026 9.9797L9.00004 10.9595L10.4899 9.46959L11.9798 7.9797C12.1751 7.78444 12.4917 7.78444 12.6869 7.9797Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default DoneIcon;
