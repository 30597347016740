import React from "react";

const InstaLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2.73047H8C6.60807 2.73196 5.27358 3.28556 4.28933 4.2698C3.30509 5.25405 2.75149 6.58854 2.75 7.98047V16.9805C2.75149 18.3724 3.30509 19.7069 4.28933 20.6911C5.27358 21.6754 6.60807 22.229 8 22.2305H17C18.3919 22.229 19.7264 21.6754 20.7107 20.6911C21.6949 19.7069 22.2485 18.3724 22.25 16.9805V7.98047C22.2485 6.58854 21.6949 5.25405 20.7107 4.2698C19.7264 3.28556 18.3919 2.73196 17 2.73047ZM12.5 16.9805C11.61 16.9805 10.74 16.7165 9.99993 16.2221C9.25991 15.7276 8.68314 15.0248 8.34254 14.2025C8.00195 13.3803 7.91283 12.4755 8.08647 11.6026C8.2601 10.7296 8.68868 9.92782 9.31802 9.29849C9.94736 8.66915 10.7492 8.24057 11.6221 8.06694C12.495 7.8933 13.3998 7.98242 14.2221 8.32301C15.0443 8.66361 15.7471 9.24038 16.2416 9.9804C16.7361 10.7204 17 11.5905 17 12.4805C16.9988 13.6736 16.5243 14.8174 15.6806 15.6611C14.837 16.5047 13.6931 16.9792 12.5 16.9805ZM18.125 7.98047C17.9025 7.98047 17.685 7.91449 17.5 7.79087C17.315 7.66726 17.1708 7.49155 17.0856 7.28599C17.0005 7.08042 16.9782 6.85422 17.0216 6.63599C17.065 6.41776 17.1722 6.21731 17.3295 6.05997C17.4868 5.90264 17.6873 5.79549 17.9055 5.75209C18.1238 5.70868 18.35 5.73096 18.5555 5.8161C18.7611 5.90125 18.9368 6.04545 19.0604 6.23045C19.184 6.41546 19.25 6.63296 19.25 6.85547C19.25 7.15384 19.1315 7.43999 18.9205 7.65096C18.7095 7.86194 18.4234 7.98047 18.125 7.98047ZM15.5 12.4805C15.5 13.0738 15.3241 13.6538 14.9944 14.1472C14.6648 14.6405 14.1962 15.025 13.6481 15.2521C13.0999 15.4792 12.4967 15.5386 11.9147 15.4228C11.3328 15.3071 10.7982 15.0213 10.3787 14.6018C9.95912 14.1822 9.6734 13.6477 9.55764 13.0657C9.44189 12.4838 9.5013 11.8806 9.72836 11.3324C9.95542 10.7842 10.3399 10.3157 10.8333 9.98606C11.3266 9.65642 11.9067 9.48047 12.5 9.48047C13.2956 9.48047 14.0587 9.79654 14.6213 10.3591C15.1839 10.9218 15.5 11.6848 15.5 12.4805Z"
        fill="#808080"
      />
    </svg>
  );
};

export default InstaLogo;
