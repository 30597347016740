import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import JobDetails from "../sections/createJobPost/JobDetails";
import { CreateJobPostContext } from "../context/CreateJobPostContext";
import SelectedTaskers from "../sections/createJobPost/SelectedTaskers";
import JobPostSuccess from "../sections/createJobPost/JobPostSuccess";
import JobPostSummary from "../sections/createJobPost/JobPostSummary";

const CreateJobPost = () => {
  const { stepperCurrentSteps, jobDetailSteps, jobSummarySteps } =
    useContext(CreateJobPostContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-primaryLightest">
      <NavBar />
      <div className="content">
        {stepperCurrentSteps === 1 ? (
          <>{jobSummarySteps === 2 ? <JobPostSuccess /> : <JobPostSummary />}</>
        ) : (
          <>{jobDetailSteps === 2 ? <SelectedTaskers /> : <JobDetails />}</>
        )}
      </div>
    </div>
  );
};

export default CreateJobPost;
