import { useEffect } from "react";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import ForClient from "../sections/home/ForClient";
import ForWorkers from "../sections/home/ForWorkers";
import HeyNinja from "../sections/home/HeyNinja";
import InfoCardClient from "../sections/home/InfoCardClient";
import InfoCardWorkers from "../sections/home/InfoCardWorkers";
import OnDemandWorkers from "../sections/home/OnDemandWorkers";
import PopularJobs from "../sections/home/PopularJobs";
import PowerAt from "../sections/home/PowerAt";
import SearchWithSelector from "../sections/home/SearchWithSelector";
import ServiceCarousel from "../sections/home/ServiceCarousel";
import WorkersOffers from "../sections/home/WorkersOffers";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="mt-28 flex flex-col gap-8">
          <div className="mx-5">
            <ServiceCarousel />
          </div>
          <SearchWithSelector />
          <WorkersOffers />
        </div>

        <div className="mt-20 flex flex-col gap-8 md:gap-10 lg:gap-16">
          <OnDemandWorkers />
          <PopularJobs />
          <HeyNinja />
          <ForClient />
          <InfoCardClient />
          <ForWorkers />
          <InfoCardWorkers />
          <PowerAt />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
