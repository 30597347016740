import React from "react";

const PaymentIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9C3 6.17157 3 4.75736 3.87868 3.87868C4.75736 3 6.17157 3 9 3C11.8284 3 13.2426 3 14.1213 3.87868C15 4.75736 15 6.17157 15 9C15 11.8284 15 13.2426 14.1213 14.1213C13.2426 15 11.8284 15 9 15C6.17157 15 4.75736 15 3.87868 14.1213C3 13.2426 3 11.8284 3 9ZM9.4473 9.33297C9.2634 9.16579 9.24985 8.88119 9.41703 8.6973C9.58421 8.5134 9.86881 8.49985 10.0527 8.66703L11.7027 10.167C11.7965 10.2523 11.85 10.3732 11.85 10.5C11.85 10.6268 11.7965 10.7477 11.7027 10.833L10.0527 12.333C9.86881 12.5002 9.58421 12.4866 9.41703 12.3027C9.24985 12.1188 9.2634 11.8342 9.4473 11.667L10.236 10.95H6.6C6.35147 10.95 6.15 10.7485 6.15 10.5C6.15 10.2515 6.35147 10.05 6.6 10.05H10.236L9.4473 9.33297ZM8.58297 5.6973C8.75015 5.88119 8.7366 6.16579 8.5527 6.33297L7.76397 7.05H11.4C11.6485 7.05 11.85 7.25147 11.85 7.5C11.85 7.74853 11.6485 7.95 11.4 7.95H7.76397L8.5527 8.66703C8.7366 8.83421 8.75015 9.11881 8.58297 9.3027C8.4158 9.4866 8.13119 9.50015 7.9473 9.33297L6.2973 7.83297C6.20348 7.74769 6.15 7.62679 6.15 7.5C6.15 7.37321 6.20348 7.25231 6.2973 7.16703L7.9473 5.66703C8.13119 5.49985 8.4158 5.5134 8.58297 5.6973Z"
        fill={color}
      />
    </svg>
  );
};

export default PaymentIcon;
