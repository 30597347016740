import React from "react";
import { Card } from "antd";
import PhoneIcon from "../../assets/svg/PhoneIcon";
import LocationIcon from "../../assets/svg/LocationIcon";
import FavouriteIcon from "../../assets/svg/FavouriteIcon";

const InfoCardClient = () => {
  const cards = [
    {
      icon: <PhoneIcon />,
      title: "Contact Instantly",
      description:
        "Enjoy complete freedom by directly contacting workers even before they respond to the job.",
    },
    {
      icon: <LocationIcon />,
      title: "Workers near you",
      description:
        "Find the best talent and those willing to sweat it out just around your location.",
    },
    {
      icon: <FavouriteIcon />,
      title: "Favourite list",
      description:
        "Save the best workers in a smart list and contact them when you need.",
    },
  ];

  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl">
          {cards.map((card, index) => (
            <div
              key={index}
              className="rounded-lg shadow-lg bg-gradient-to-tl from-transparent to-gray-200 max-w-[330px]"
            >
              <div className="flex flex-col items-start p-8 md:py-6 md:px-3 lg:p-8">
                {card.icon}
                <h3 className="text-[24px] font-semibold">{card.title}</h3>
                <p className="text-base font-normal text-gray">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoCardClient;
