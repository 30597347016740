import { Col, Divider, Dropdown, Layout, Row } from "antd";
import React, { useContext, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import UserColorProfile from "../components/userColorProfile/UserColorProfile";
import CalendarIconWhite from "../assets/svg/CalendarIconWhite";
import { IoIosArrowForward } from "react-icons/io";
import { FaAngleRight, FaCaretDown } from "react-icons/fa";
import VerifyIcon from "../assets/svg/VerifyIcon";
import ViewsIcon from "../assets/svg/ViewsIcon";
import CompleteIcon from "../assets/svg/CompleteIcon";
import NewWorkerIcon from "../assets/svg/NewWorkerIcon";
import PremiumIcon from "../assets/svg/PremiumIcon";
import LocationIconFull from "../assets/svg/LocationIconFull";
import LanguageFullIcon from "../assets/svg/LanguageFullIcon";
import { decryptSecureData } from "../helpers/encryptHelpers/encryption";
import { getDateDifference } from "../utils/dateDifference";
import { useNavigate } from "react-router-dom";
import { CreateJobPostContext } from "../context/CreateJobPostContext";
import { NotificationContext } from "../context/NotificationContext";
import CreateJobPostServices from "../services/CreateJobPostServices";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import TaskerListCard from "../components/taskerCard/TaskerListCard";
import NotificationAlertBox from "../components/alertBox/NotificationAlertBox";

const MyJobsWorkerList = () => {
  let {
    selectedJobType,
    locationValues,
    jobValues,
    descriptionValues,

    setTaskerList,

    selectedCountryType,
  } = useContext(CreateJobPostContext);
  const { openNotification, handleError } = useContext(NotificationContext);
  const { getAllTakerList } = CreateJobPostServices();

  const [language, setLanguage] = useState("All");
  const [sortValue, setSortValue] = useState("Recommended");

  const [openJobDetails, setOpenJobDetails] = useState(false);

  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);

  const languageItems = [
    {
      label: "All",
      key: 1,
    },
    {
      label: "English",
      key: 2,
    },
    {
      label: "Sinhala",
      key: 3,
    },
    {
      label: "Tamil",
      key: 4,
    },
  ];
  const handleSelectLanguage = async ({ key }) => {
    setLanguage(
      languageItems?.find((item) => {
        return item?.key == key;
      })?.label
    );

    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: Number(key) === 1 ? [] : [Number(key)],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const sortItems = [
    {
      label: "Recommended",
      key: "0",
    },
    {
      label: "price (Lowest to Highest)",
      key: "1",
    },
    {
      label: "price (Highest to Lowest )",
      key: "2",
    },
    {
      label: "Positive Reviews",
      key: "3",
    },
    {
      label: "Number Of Completed Tasks",
      key: "4",
    },
  ];
  const handleSelectSort = ({ key }) => {
    setSortValue(
      sortItems?.find((item) => {
        return item?.key == key;
      })?.label
    );
  };

  const taskerList = [
    {
      user_id: 473,
      first_name: "mALtj4CVPTYkBLiRBReEMw==",
      work_title: null,
      package_id: 1,
      country_id: 2,
      state_id: 1,
      state: "Ampara",
      suberb_id: 1,
      suberb: "Akkaraipattu",
      postal_code: null,
      profile_picture: null,
      work_description: null,
      has_verified: 0,
      rate: 5,
      review_count: 1,
      job_count: 1,
      next_payment_date: "2024-10-05",
      language: "",
      portfolios: [],
      job_types: [
        {
          job_type_id: 3,
          job_type: "House Cleaning",
        },
        {
          job_type_id: 43,
          job_type: "Seasonal Cleanups",
        },
        {
          job_type_id: 85,
          job_type: "Architecture",
        },
        {
          job_type_id: 158,
          job_type: "Store Manager",
        },
      ],
      rates: [
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
        {
          job_id: 245,
          rate: 5,
          message: "good",
          customer_name: "mALtj4CVPTYkBLiRBReEMw==",
          profile_picture: null,
          rated_date_time: "2024-08-06 05:30:44",
        },
      ],
    },
  ];

  return (
    <>
      <Layout>
        <NavBar />
        <div className="content overflow-hidden bg-white">
          <div className="flex w-full justify-center items-center z-10">
            <div className="max-w-[1073px] xl:w-[1073px] w-full mx-5 mb-10">
              <div className="flex flex-col justify-center items-center pt-5 md:pt-10">
                <div className="flex flex-col w-full">
                  {/* Top two details cards */}
                  <Row className="mb-5" gutter={[16, 16]}>
                    <Col span={24} md={{ span: 12 }}>
                      <div className="bg-primaryDarkest rounded-xl p-4 h-full">
                        <div className="flex flex-col sm:flex-row gap-5 w-full">
                          <div className="flex flex-col justify-center items-center">
                            <UserColorProfile
                              name={selectedJobType}
                              color={"#3bb96e"}
                              size="50px"
                              textSize="20px"
                            />
                            <p className="text-white mt-3 text-xxs font-semibold flex flex-row items-center justify-center py-1 gap-2 bg-[#F3A64B] min-w-[100px] max-w-[100px] rounded-full">
                              <CalendarIconWhite />
                              {jobValues?.jobOne
                                ? "URGENT"
                                : jobValues?.jobTwo
                                ? "FEW DAYS"
                                : "FLEXIBLE"}
                            </p>
                          </div>

                          <div className="text-white w-full">
                            <p className="text-sm font-semibold">
                              Posted on 22 Sep 2023
                            </p>
                            <p className="text-sm font-bold">
                              {selectedJobType}
                            </p>
                            <p className="text-xs font-normal">
                              {selectedCountryType === 1
                                ? locationValues?.postalCodetype2
                                : locationValues?.districtName +
                                  ", " +
                                  locationValues?.cityName +
                                  ", " +
                                  locationValues?.countryName +
                                  "."}
                            </p>

                            <div className="flex flex-row justify-between items-center">
                              <p className="text-xs font-normal text-white">
                                Job ID - 123456XYZ
                              </p>
                              <button
                                className="flex flex-row justify-center items-center gap-2 text-xs font-semibold text-primaryLight cursor-pointer"
                                onClick={() => {
                                  setOpenCloseNotificationAlertBox(true);
                                }}
                              >
                                Details
                                <IoIosArrowForward />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} md={{ span: 12 }}>
                      <div className="bg-primaryLightest p-4 rounded-lg h-full">
                        <p className="text-xs md:text-sm font-semibold">
                          Log in via the Jobs Ninja mobile app to view{" "}
                          <span className="text-primaryDark">
                            tasker offers, chat, and access advanced features
                          </span>{" "}
                          to continue the job process.
                        </p>
                        <p className="text-xs md:text-sm font-normal text-primaryDark underline">
                          Learn more..
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <div>
                    {/* Language selection Dropdown */}
                    <div className="flex flex-col xsm:flex-row justify-between">
                      <p className="text-xs font-medium text-primaryColor">
                        {taskerList?.length} worker(s) found
                      </p>

                      <div className="flex flex-row gap-2 justify-end items-end">
                        <Dropdown
                          menu={{
                            items: languageItems,
                            onClick: handleSelectLanguage,
                          }}
                        >
                          <div className="flex flex-row gap-1 bg-primaryLightest py-1 px-2 justify-start items-center rounded-md">
                            <p className="text-xs font-normal text-textColorThree hidden md:block">
                              Preferred Language :
                            </p>
                            <div className="flex flex-row justify-between items-center gap-5 text-primaryDarkest">
                              <p className="text-xs font-semibold">
                                {language}
                              </p>
                              <FaCaretDown />
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    </div>

                    {/* Tasker list cards */}
                    {/* For this need to come backend data */}
                    <TaskerListCard taskerList={taskerList} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="View Job Details"
          content={
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <p className="text-sm md:text-base font-bold">
                  {selectedJobType}
                </p>
                <p className="text-white text-xxs font-semibold flex flex-row items-center justify-center py-1 gap-2 bg-[#F3A64B] min-w-[100px] max-w-[100px] rounded-full">
                  <CalendarIconWhite />
                  {jobValues?.jobOne
                    ? "URGENT"
                    : jobValues?.jobTwo
                    ? "FEW DAYS"
                    : "FLEXIBLE"}
                </p>
              </div>

              <div className="flex flex-row">
                <LocationIconFull />
                <p className="text-xs font-normal text-textColorOne">
                  {selectedCountryType === 1
                    ? locationValues?.postalCodetype2
                    : locationValues?.districtName +
                      ", " +
                      locationValues?.cityName +
                      ", " +
                      locationValues?.countryName +
                      "."}
                </p>
              </div>

              <Divider className="my-2" />
              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  Description
                </p>
                <p className="text-xs font-semibold text-textColorOne">
                  {descriptionValues}
                </p>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default MyJobsWorkerList;
