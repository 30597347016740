import React, { useContext, useEffect } from "react";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import { Button, Checkbox, Col, Divider, Row } from "antd";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import CreateJobPostHelpers from "../../helpers/apiHelpers/CreateJobPostHelpers";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const JobPostSummary = () => {
  let {
    selectedJobType,
    locationValues,
    jobValues,
    descriptionValues,
    selectedCountryType,
    setJobSummarySteps,
    taskerListCount,
    setCompletedStep,
    setStepperCurrentSteps,

    jobPostLoading,
  } = useContext(CreateJobPostContext);

  const { fetchAllTaskerListCount, postTheTaskerJobPost } =
    CreateJobPostHelpers();

  useEffect(() => {
    window.scrollTo(0, 0);

    //get tasker list count
    fetchAllTaskerListCount({
      job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
      postal_code:
        selectedCountryType === 1
          ? locationValues?.postalCodetype0
          : locationValues?.city,
      country_id: locationValues?.country,
    });

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <JobpostLayout>
      <p className="text-sm font-medium bg-primaryLightest text-center rounded-lg my-10 py-1 px-3">
        Review your job details and click 'Post Job.' Once you do, your job will
        be posted and visible to all available taskers.
      </p>

      <div className="bg-white p-5 rounded-xl">
        <Row className="flex flex-row w-full" gutter={16}>
          <Col span={24} md={{ span: 12 }}>
            <p className="text-sm font-medium text-primaryColor">Job Details</p>
            <div className="rounded-lg w-full mt-5 p-2">
              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  Job type & Location :
                </p>
                <p className="text-base md:text-lg font-semibold text-textColorOne">
                  {selectedJobType}
                </p>
                <div className="flex flex-row">
                  <LocationIconFull />
                  <p className="text-xs font-normal text-textColorOne">
                    {selectedCountryType === 1
                      ? locationValues?.postalCodetype2
                      : locationValues?.districtName +
                        ", " +
                        locationValues?.cityName +
                        ", " +
                        locationValues?.countryName +
                        "."}
                  </p>
                </div>
              </div>
              <Divider className="my-2 bg-black" />

              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  Job Urgency :
                </p>
                <p className="text-xs font-semibold text-textColorOne">
                  {jobValues?.jobOne
                    ? "Urgent"
                    : jobValues?.jobTwo
                    ? "Within few days"
                    : "I’m flexible"}
                </p>
              </div>
              <Divider className="my-2 bg-black" />

              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  Description
                </p>
                <p className="text-xs font-semibold text-textColorOne">
                  {descriptionValues}
                </p>
              </div>
            </div>
          </Col>

          <Col
            span={24}
            md={{ span: 12 }}
            className="flex justify-center items-center"
          >
            <div className="text-primaryDark flex flex-col justify-center items-center">
              <p className="text-xl font-semibold">Hooray!</p>

              <div className="flex flex-col justify-center items-center bg-primaryDark text-white rounded-full py-5 px-10 my-5">
                <p className="text-xs font-normal">We found</p>
                <p className="text-[52px] font-bold">{taskerListCount}</p>
                <p className="text-xs font-normal">workers for you</p>
              </div>

              <p className="text-base font-medium text-center">
                Post your job to view and receive offers from all available
                workers.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="flex flex-col justify-center items-center gap-7 my-5">
        {/* <div className="flex flex-row gap-5">
          <Checkbox />
          <p className="text-xs md:text-sm font-normal">
            I like to share my contact details with selected workers
          </p>
        </div> */}

        <div className="flex flex-row gap-5 mt-5">
          <Button
            className="border-primaryDark text-primaryDark text-sm font-medium px-12"
            onClick={() => {
              setCompletedStep(0);
              setStepperCurrentSteps(0);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={jobPostLoading}
            type="primary"
            htmlType="submit"
            className="bg-primaryDark text-sm font-medium px-10"
            onClick={async () => {
              const data = await new FormData();
              data.append("job_category_id", 0);
              data.append(
                "job_type_id",
                getLocalStoragedata("searchSelectedJobTypeId")
              );
              data.append("country_id", locationValues?.country);
              data.append(
                "state_id",
                selectedCountryType === 1
                  ? locationValues?.state
                  : locationValues?.district
              );
              data.append(
                "suburb_id",
                selectedCountryType === 1
                  ? locationValues?.suburb
                  : locationValues?.city
              );
              data.append(
                "job_time_id",
                jobValues?.jobOne ? 1 : jobValues?.jobTwo ? 3 : 5
              );
              data.append("job_title", selectedJobType);
              data.append("job_description", descriptionValues);

              data.append("audio", null);
              data.append("audio_ext", null);
              data.append("audio_duration", null);

              data.append(
                "first_name",
                decryptSecureData(getLocalStoragedata("userData")?.first_name)
              );
              data.append(
                "email_address",
                decryptSecureData(getLocalStoragedata("userData")?.email)
              );
              data.append(
                "mobile_number",
                decryptSecureData(
                  getLocalStoragedata("userData")?.mobile_number
                )
              );
              data.append(
                "dial_code",
                getLocalStoragedata("userData")?.dial_code
              );
              data.append(
                "country_code",
                getLocalStoragedata("userData")?.country_code
              );
              data.append("user_id", getLocalStoragedata("userData")?.user_id);

              data.append("is_draft", 0);

              postTheTaskerJobPost(data);
            }}
          >
            Post Job
          </Button>
        </div>
      </div>
    </JobpostLayout>
  );
};

export default JobPostSummary;
