import React from "react";

const PassCode = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 8.30029H5.5C4.94772 8.30029 4.5 8.74801 4.5 9.30029V16.3003C4.5 16.8526 4.94772 17.3003 5.5 17.3003H15.5C16.0523 17.3003 16.5 16.8526 16.5 16.3003V9.30029C16.5 8.74801 16.0523 8.30029 15.5 8.30029Z"
        stroke="#2C8B52"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 8.30029V5.30029C6.5 3.45934 8.06701 2.30029 10 2.30029C11.933 2.30029 13.5 3.45934 13.5 5.30029V5.80029"
        stroke="#2C8B52"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 12.3003V13.1336"
        stroke="#2C8B52"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PassCode;
