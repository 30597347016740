import React, { useContext, useState } from "react";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import { Button, Col, Divider, Dropdown, Row, Spin } from "antd";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import { FaAngleRight, FaCaretDown } from "react-icons/fa";
import VerifyIcon from "../../assets/svg/VerifyIcon";
import ViewsIcon from "../../assets/svg/ViewsIcon";
import CompleteIcon from "../../assets/svg/CompleteIcon";
import PremiumIcon from "../../assets/svg/PremiumIcon";
import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
import VIewTaskerProfile from "./VIewTaskerProfile";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import NewWorkerIcon from "../../assets/svg/NewWorkerIcon";
import UserColorProfile from "../../components/userColorProfile/UserColorProfile";
import { getDateDifference } from "../../utils/dateDifference";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";
import CalendarIconWhite from "../../assets/svg/CalendarIconWhite";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const SelectedTaskers = () => {
  const navigate = useNavigate();
  let {
    selectedJobType,
    locationValues,
    jobValues,
    descriptionValues,
    taskerList,
    setTaskerList,
    selectedCountryType,
    listLoading,
    setListLoading,
  } = useContext(CreateJobPostContext);
  const { getAllTakerList } = CreateJobPostServices();

  const { openNotification, handleError } = useContext(NotificationContext);
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);
  const [openCloseTaskerProfile, setOpenCloseTaskerProfile] = useState(false);
  const [selectedTaskerData, setSelectedTaskerData] = useState();
  const [language, setLanguage] = useState("All");

  const handleOpenCloseTaskerProfile = () => {
    setOpenCloseTaskerProfile(false);
  };

  const languageItems = [
    {
      label: "All",
      key: 1,
    },
    {
      label: "English",
      key: 2,
    },
    {
      label: "Sinhala",
      key: 3,
    },
    {
      label: "Tamil",
      key: 4,
    },
  ];

  const handleSelectLanguage = async ({ key }) => {
    setListLoading(true);
    setLanguage(
      languageItems?.find((item) => {
        return item?.key == key;
      })?.label
    );

    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: Number(key) === 1 ? [] : [Number(key)],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setListLoading(false);
  };

  return (
    <>
      <JobpostLayout>
        <div>
          <div className="flex flex-col justify-center items-center pt-5 md:pt-10">
            <div className="flex flex-col w-full mb-[150px] lg:mb-[10px]">
              <div className="bg-primaryDarkest rounded-xl mb-5 flex flex-row justify-between p-4">
                <div className="flex flex-col sm:flex-row gap-5 justify-center items-start sm:items-center">
                  <div className="flex flex-col justify-center items-center">
                    <UserColorProfile
                      // name={selectedJobType}
                      name="Test One"
                      color={"#3bb96e"}
                      size="50px"
                      textSize="20px"
                    />
                    <p className="text-white mt-3 text-xxs font-semibold flex flex-row items-center justify-center py-1 gap-2 bg-[#F3A64B] min-w-[100px] max-w-[100px] rounded-full">
                      <CalendarIconWhite />
                      {jobValues?.jobOne
                        ? "URGENT"
                        : jobValues?.jobTwo
                        ? "FEW DAYS"
                        : "FLEXIBLE"}
                    </p>
                  </div>

                  <div className="text-white">
                    <p className="text-sm font-bold">{selectedJobType}</p>
                    <p className="text-xs font-normal">
                      {selectedCountryType === 1
                        ? locationValues?.postalCodetype2
                        : locationValues?.districtName +
                          ", " +
                          locationValues?.cityName +
                          ", " +
                          locationValues?.countryName +
                          "."}
                    </p>
                  </div>
                </div>

                <button
                  className="flex flex-row justify-center items-center gap-2 text-xs font-semibold text-primaryLight cursor-pointer"
                  onClick={() => {
                    setOpenCloseNotificationAlertBox(true);
                  }}
                >
                  Details
                  <IoIosArrowForward />
                </button>
              </div>

              <div>
                <div className="flex flex-col xsm:flex-row justify-between">
                  <p className="text-xs font-medium text-primaryColor">
                    {taskerList?.length} worker(s) found
                  </p>

                  {/* <div className="flex flex-row gap-2 justify-end items-end">
                    <Dropdown
                      menu={{
                        items: languageItems,
                        onClick: handleSelectLanguage,
                      }}
                    >
                      <div className="flex flex-row gap-1 py-1 px-2 justify-start items-center rounded-md bg-white">
                        <p className="text-xs font-normal text-textColorThree hidden md:block">
                          Preferred Language :
                        </p>
                        <div className="flex flex-row justify-between items-center gap-5 text-primaryDarkest">
                          <p className="text-xs font-semibold">{language}</p>
                          <FaCaretDown />
                        </div>
                      </div>
                    </Dropdown>
                  </div> */}
                </div>

                {listLoading ? (
                  <div className="flex justify-center items-center mt-10">
                    <Spin />
                  </div>
                ) : (
                  <>
                    {taskerList?.length !== 0 ? (
                      <div className=" mb-10 lg:mb-32">
                        {taskerList?.map((item, index) => {
                          return (
                            <div
                              className="w-full mt-3 rounded-lg p-2 bg-white"
                              key={index}
                            >
                              <Row className="flex flex-row">
                                <Col
                                  span={24}
                                  md={{ span: 6 }}
                                  className="flex flex-col justify-start md:justify-center md:items-center pt-5 md:py-10"
                                >
                                  <div className="hidden md:block">
                                    <div className="flex flex-row items-center justify-center gap-1">
                                      {item?.profile_picture === null ? (
                                        <UserColorProfile
                                          name={
                                            decryptSecureData(
                                              item?.first_name
                                            ) || "N Z"
                                          }
                                          color={"#3bb96e"}
                                          size="100px"
                                          textSize="40px"
                                        />
                                      ) : (
                                        <img
                                          src={item?.profile_picture}
                                          alt=""
                                          className="flex w-[100px] h-[100px] rounded-full border-[2px]"
                                        />
                                      )}
                                    </div>

                                    <div className="flex flex-col gap-2 mt-2">
                                      {item?.has_verified === 1 && (
                                        <div className="flex flex-row gap-3 justify-start items-center">
                                          <VerifyIcon />
                                          <p className="text-sm font-medium text-textColorTwo">
                                            Verified Worker
                                          </p>
                                        </div>
                                      )}

                                      {item?.rate !== 0 && (
                                        <div className="flex flex-row gap-3 justify-start items-center">
                                          <ViewsIcon />
                                          <p className="text-sm font-medium text-textColorTwo">
                                            <span className="text-sm font-bold text-textColorOne">
                                              {" "}
                                              {item?.rate}
                                            </span>{" "}
                                            ({item?.review_count} Reviews)
                                          </p>
                                        </div>
                                      )}

                                      {item?.job_count !== 0 && (
                                        <div className="flex flex-row gap-3 justify-start items-center">
                                          <CompleteIcon />
                                          <p className="text-sm font-medium text-textColorTwo">
                                            <span className="text-sm font-bold text-textColorOne">
                                              {" "}
                                              {item?.job_count}+
                                            </span>{" "}
                                            Completed Tasks
                                          </p>
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="flex flex-row justify-center items-center gap-2 underline text-primaryDarkest mt-5 cursor-pointer"
                                      onClick={() => {
                                        setOpenCloseTaskerProfile(true);
                                        setSelectedTaskerData(item);
                                      }}
                                    >
                                      <p className="text-sm font-medium">
                                        View profile
                                      </p>
                                      <FaAngleRight className="text-primaryDarkest" />
                                    </div>
                                  </div>

                                  <div className="flex md:hidden flex-col sm:flex-row gap-10 w-full">
                                    <div className="flex flex-col justify-center items-center">
                                      {item?.profile_picture === null ? (
                                        <UserColorProfile
                                          name={
                                            decryptSecureData(
                                              item?.first_name
                                            ) || "N Z"
                                          }
                                          color={"#3bb96e"}
                                          size="100px"
                                          textSize="40px"
                                        />
                                      ) : (
                                        <img
                                          src={item?.profile_picture}
                                          alt=""
                                          className="flex max-w-[100px] h-[100px] rounded-full border-[2px]"
                                        />
                                      )}
                                    </div>

                                    <div className="flex flex-col sm:flex-row justify-between w-full">
                                      <div className="flex flex-col gap-2">
                                        {item?.has_verified === 1 && (
                                          <div className="flex flex-row gap-3 justify-start items-center">
                                            <VerifyIcon />
                                            <p className="text-sm font-medium text-textColorTwo">
                                              Verified Worker
                                            </p>
                                          </div>
                                        )}

                                        {item?.rate !== 0 && (
                                          <div className="flex flex-row gap-3 justify-start items-center">
                                            <ViewsIcon />
                                            <p className="text-sm font-medium text-textColorTwo">
                                              <span className="text-sm font-bold text-textColorOne">
                                                {" "}
                                                {item?.rate}
                                              </span>{" "}
                                              ({item?.review_count} Reviews)
                                            </p>
                                          </div>
                                        )}

                                        {item?.job_count !== 0 && (
                                          <div className="flex flex-row gap-3 justify-start items-center">
                                            <CompleteIcon />
                                            <p className="text-sm font-medium text-textColorTwo">
                                              <span className="text-sm font-bold text-textColorOne">
                                                {" "}
                                                {item?.job_count}+
                                              </span>{" "}
                                              Completed Tasks
                                            </p>
                                          </div>
                                        )}

                                        <div
                                          className="flex flex-row justify-start items-center gap-2 underline text-primaryDarkest cursor-pointer"
                                          onClick={() => {
                                            setOpenCloseTaskerProfile(true);
                                            setSelectedTaskerData(item);
                                          }}
                                        >
                                          <p className="text-sm font-medium">
                                            View profile
                                          </p>
                                          <FaAngleRight className="text-primaryDarkest" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  span={0}
                                  md={{ span: 1 }}
                                  className="flex justify-center items-center"
                                >
                                  <Divider
                                    type="vertical"
                                    className="bg-gray-300 h-[90%]"
                                  />
                                </Col>

                                <Col span={24} md={{ span: 17 }}>
                                  <div className="py-3">
                                    <div className="flex flex-row justify-between items-center">
                                      <div className="flex flex-row gap-3">
                                        <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                                          {decryptSecureData(item?.first_name)}
                                        </p>
                                        <div
                                          className={`border-[2px] ${
                                            item?.package_id === 1
                                              ? "border-[#E79721] bg-[#E7972133]"
                                              : "border-[#D659D9] bg-[#D659D933]"
                                          }  px-2 rounded-lg flex flex-row justify-center items-center gap-1`}
                                        >
                                          {item?.package_id === 1 ? (
                                            <NewWorkerIcon />
                                          ) : (
                                            <PremiumIcon />
                                          )}

                                          <p
                                            className={`text-xxs font-bold ${
                                              item?.package_id === 1
                                                ? "text-[#E79721]"
                                                : "text-[#D659D9]"
                                            }  hidden xsm:block`}
                                          >
                                            {item?.package_id === 1
                                              ? "NEW WORKER"
                                              : " PREMIUM"}
                                          </p>
                                        </div>
                                      </div>

                                      {/* <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                              25$ /Hr
                            </p> */}
                                    </div>
                                  </div>

                                  <div>
                                    <p className="text-xs font-normal text-textColorTwo">
                                      {item?.work_title}
                                    </p>
                                    <div className="flex flex-row">
                                      <LocationIconFull />
                                      <p className="text-xs font-semibold text-textColorOne">
                                        {[
                                          item?.state,
                                          item?.suberb,
                                          item?.postal_code,
                                        ]
                                          .filter(Boolean)
                                          .join(", ")}{" "}
                                      </p>
                                    </div>

                                    {item?.language !== "" ||
                                      item?.language !== null ||
                                      (item?.language !== undefined && (
                                        <div className="flex flex-row">
                                          <LanguageFullIcon />
                                          <p className="text-xs font-semibold text-textColorOne">
                                            {item?.language}
                                          </p>
                                        </div>
                                      ))}
                                  </div>

                                  <Row className="flex flex-row gap-1 mt-2">
                                    {item?.job_types?.map((item) => {
                                      return (
                                        <Col>
                                          <p className="border-[1px] border-gray-300 py-1 px-2 rounded-full text-xs font-normal text-primaryDarkest">
                                            {item?.job_type}
                                          </p>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                  <Divider className="my-3" />

                                  <div>
                                    <p className="text-sm font-bold text-textColorOne">
                                      Skills & Experience
                                    </p>
                                    {item?.work_description === null ||
                                    item?.work_description === "" ? (
                                      <p className="text-sm font-bold text-textColorTwo">
                                        _
                                      </p>
                                    ) : (
                                      <p className="text-xs font-normal text-textColorTwo">
                                        {item?.work_description}
                                      </p>
                                    )}
                                  </div>
                                  <Divider className="my-3" />

                                  <div>
                                    <p className="text-sm font-bold text-textColorOne">
                                      Latest reviews
                                    </p>

                                    {item?.rates?.length === 0 ? (
                                      <p className="text-sm font-bold text-textColorTwo">
                                        _
                                      </p>
                                    ) : (
                                      <div className="max-h-[200px] overflow-y-scroll scrollbar-hide">
                                        {item?.rates?.map((item) => {
                                          return (
                                            <div className="pt-3 flex flex-row gap-3 w-full">
                                              <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                                                {item?.profile_picture ===
                                                null ? (
                                                  <UserColorProfile
                                                    name={
                                                      decryptSecureData(
                                                        item?.customer_name
                                                      ) || "N Z"
                                                    }
                                                    color={"#3bb96e"}
                                                    size="40px"
                                                    textSize="16px"
                                                  />
                                                ) : (
                                                  <img
                                                    src={item?.profile_pictur}
                                                    alt=""
                                                    className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                                                  />
                                                )}
                                              </div>

                                              <div className="w-full">
                                                <div className="flex flex-row justify-between items-center w-full">
                                                  <p className="text-xs font-semibold">
                                                    {decryptSecureData(
                                                      item?.customer_name
                                                    )}{" "}
                                                    <span className="font-normal text-textColorTwo">
                                                      {getDateDifference(
                                                        item?.rated_date_time
                                                      )}
                                                    </span>
                                                  </p>

                                                  <div className="flex flex-row gap-2">
                                                    <p className="text-xs font-semibold">
                                                      {item?.rate}
                                                    </p>
                                                    <div className="flex flex-row gap-1">
                                                      {Array.from(
                                                        { length: item?.rate },
                                                        (_, i) => (
                                                          <ViewsIcon key={i} />
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <p className="text-xxs font-normal text-textColorTwo">
                                                  {item?.message}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="w-full mt-3 rounded-lg p-2 bg-white">
                        <p className="text-center text-sm font-bold text-textColorTwo">
                          No Workers
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="fixed -bottom-5 left-0 w-full my-5 px-4 bg-[#2C8B52]">
              <div className="flex w-full justify-center items-center">
                <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 py-8">
                  <div className="text-white flex flex-col sm:flex-row gap-3 justify-between items-center">
                    <p className="text-sm md:text-base font-medium w-full sm:max-w-[400px] md:max-w-[600px]">
                      We found {taskerList?.length} matching worker(s) for your
                      job. Please sign in to view the full list of worker(s) and
                      post a job.
                    </p>
                    <button
                      className="bg-primaryDarkest px-8 py-2 rounded-lg text-sm font-semibold"
                      onClick={() => {
                        navigate("../login", {
                          state: { role: 2, type: "asClient" },
                        });
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </JobpostLayout>

      {openCloseTaskerProfile && (
        <VIewTaskerProfile
          open={openCloseTaskerProfile}
          onClose={handleOpenCloseTaskerProfile}
          selectedTaskerData={selectedTaskerData}
        />
      )}

      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="View Job Details"
          content={
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <p className="text-sm md:text-base font-bold">
                  {selectedJobType}
                </p>
                <p className="text-white text-xxs font-semibold flex flex-row items-center justify-center py-1 gap-2 bg-[#F3A64B] min-w-[100px] max-w-[100px] rounded-full">
                  <CalendarIconWhite />
                  {jobValues?.jobOne
                    ? "URGENT"
                    : jobValues?.jobTwo
                    ? "FEW DAYS"
                    : "FLEXIBLE"}
                </p>
              </div>

              <div className="flex flex-row">
                <LocationIconFull />
                <p className="text-xs font-normal text-textColorOne">
                  {selectedCountryType === 1
                    ? locationValues?.postalCodetype2
                    : locationValues?.districtName +
                      ", " +
                      locationValues?.cityName +
                      ", " +
                      locationValues?.countryName +
                      "."}
                </p>
              </div>

              <Divider className="my-2" />
              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  Description
                </p>
                <p className="text-xs font-semibold text-textColorOne">
                  {descriptionValues}
                </p>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default SelectedTaskers;
