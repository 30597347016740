import React from "react";

const KebabMenu = () => {
  return (
    <svg
      width="5"
      height="24"
      viewBox="0 0 5 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.4" cy="2.4" r="2.4" fill="#525252" />
      <circle cx="2.4" cy="12.0001" r="2.4" fill="#525252" />
      <circle cx="2.4" cy="21.6" r="2.4" fill="#525252" />
    </svg>
  );
};

export default KebabMenu;
