import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1063 8.92057L18.6395 7.3874C19.6158 6.41109 21.1987 6.41108 22.175 7.38739L28.6395 13.8519C29.6158 14.8282 29.6158 16.4111 28.6395 17.3874L24.1584 21.8684C23.4112 22.6156 23.226 23.7571 23.6986 24.7023C26.4304 30.1661 30.8608 34.5964 36.3246 37.3283C37.2697 37.8009 38.4112 37.6156 39.1584 36.8684L43.6395 32.3874C44.6158 31.4111 46.1987 31.4111 47.175 32.3874L53.6395 38.8519C54.6158 39.8282 54.6158 41.4111 53.6395 42.3874L52.1063 43.9206C46.8279 49.199 38.4715 49.7928 32.4997 45.314L29.4787 43.0482C25.1198 39.7791 21.2478 35.907 17.9787 31.5482L15.7129 28.5272C11.234 22.5554 11.8279 14.199 17.1063 8.92057Z"
        fill="#3BB96E"
      />
    </svg>
  );
};

export default PhoneIcon;
