import React from "react";

const SubscriptionIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15 6.17157 15 4.75736 14.1213 3.87868C13.2426 3 11.8284 3 9 3C6.17157 3 4.75736 3 3.87868 3.87868C3 4.75736 3 6.17157 3 9C3 11.8284 3 13.2426 3.87868 14.1213C4.75736 15 6.17157 15 9 15C11.8284 15 13.2426 15 14.1213 14.1213C15 13.2426 15 11.8284 15 9ZM6.5818 7.5182C6.40607 7.34246 6.40607 7.05754 6.5818 6.8818C6.75754 6.70607 7.04246 6.70607 7.2182 6.8818L9.0182 8.6818C9.19393 8.85754 9.19393 9.14246 9.0182 9.3182L7.2182 11.1182C7.04246 11.2939 6.75754 11.2939 6.5818 11.1182C6.40607 10.9425 6.40607 10.6575 6.5818 10.4818L8.0636 9L6.5818 7.5182ZM8.9818 6.8818C8.80607 7.05754 8.80607 7.34246 8.9818 7.5182L10.4636 9L8.9818 10.4818C8.80607 10.6575 8.80607 10.9425 8.9818 11.1182C9.15754 11.2939 9.44246 11.2939 9.6182 11.1182L11.4182 9.3182C11.5939 9.14246 11.5939 8.85754 11.4182 8.6818L9.6182 6.8818C9.44246 6.70607 9.15754 6.70607 8.9818 6.8818Z"
        fill={color}
      />
    </svg>
  );
};

export default SubscriptionIcon;
