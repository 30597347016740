import React, { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import SupportForm from "../sections/support/SupportForm";

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="flex justify-center items-center mt-20">
          <div className="my-5 md:my-16 xl:w-[1073px]">
            <SupportForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
