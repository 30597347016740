import React, { useContext, useEffect, useState } from "react";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import ClientLogin from "../../assets/img/clientLogin.webp";
import WorkerLogin from "../../assets/img/workerLogin.webp";
import { Button, Form } from "antd";
import ContactIcon from "../../assets/svg/ContactIcon";
import { AuthContext } from "../../context/AuthContext";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { NotificationContext } from "../../context/NotificationContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginServices from "../../services/LoginServices";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";
import axios from "axios";

import LoginImageWorker from "../../assets/img/lWorker.webp";
import LoginImageClient from "../../assets/img/lClient.webp";

import jonninjaWorker from "../../assets/img/jobninjaWorker.png";
import jonninjaClient from "../../assets/img/jobninjaClient.png";

import AppleStore from "../../assets/img/appleStore.png";
import PlayStore from "../../assets/img/playStore.png";

const PhoneNumberCard = ({ role, type }) => {
  let {
    setAuthSteps,
    setEnteredPhoneNumber,

    enteredNotFormattedPhoneNumber,
    setEnteredNotFormattedPhoneNumber,

    setTryToLoginUserID,
  } = useContext(AuthContext);
  let { openNotification, handleError } = useContext(NotificationContext);
  const { getOtp } = LoginServices();

  const [loading, setLoading] = useState(false);
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);
  const [errorTypeID, setErrorTypeID] = useState(3);
  const [countryCode, setCountryCode] = useState("au");

  const handleSubmitPhoneNumber = async (e) => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": role,
      },
    };

    try {
      await getOtp(
        {
          mobile_number: encryptSecureData("+" + e?.phoneNumber),
          login_type: 1,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            setTryToLoginUserID(response?.data?.output?.user_id);
            setAuthSteps(2);
          } else {
            setErrorTypeID(response?.data?.output?.error_id);
            setOpenCloseNotificationAlertBox(true);
            // openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCountryCode();

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchCountryCode = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json/");
      if (data && data.country_code) {
        setCountryCode(data.country_code.toLowerCase());
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <div className="flex w-full justify-center items-center mt-28">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2">
          <div className="flex justify-center items-center">
            <div className="flex flex-col md:flex-row justify-center shadow-lg rounded-lg w-full md:w-[800px] my-10">
              <div className="flex flex-col justify-center items-center md:w-[57%] px-4 md:px-5">
                <NavbarLogo />
                <img
                  src={role === 1 ? WorkerLogin : ClientLogin}
                  width={211}
                  height={145}
                  className="mt-5"
                />
                <p className="text-sm md:text-lg font-bold mt-5">
                  {type === "asPayment"
                    ? "Let’s renew your package"
                    : `Lets's login to your ${
                        role === 1 ? "worker" : "client"
                      } account`}
                </p>

                <p className="text-xs font-normal text-textColorTwo mt-3 text-center">
                  {type === "asPayment"
                    ? "Enter your mobile number and confirm payment to get new tasks"
                    : "Enter your registered mobile number to continue"}
                </p>

                <Form
                  className="w-full mt-2"
                  onFinish={handleSubmitPhoneNumber}
                >
                  <div className="w-full mt-5">
                    <div className="flex flex-row gap-1 mb-3">
                      <ContactIcon />
                      <p className="text-sm font-medium">Phone Number *</p>
                    </div>

                    <Form.Item
                      name="phoneNumber"
                      className="text-start mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required!",
                        },
                        { min: 10, message: "Invalid phone number!" },
                      ]}
                    >
                      <PhoneInput
                        country={countryCode}
                        inputProps={{
                          autoFocus: true,
                        }}
                        onlyCountries={["au", "lk", "nz", "sg", "id"]}
                        inputClass="!w-full md:!h-[45px] !rounded-lg md:!text-lg"
                        searchStyle={{
                          width: "85%",
                        }}
                        dropdownClass="!w-[250px] sm:!w-[400px]"
                        buttonClass="!rounded-l-lg"
                        enableSearch
                        enableAreaCodes={false}
                        autoFormat={false}
                        placeholder="+0000000000"
                        onChange={(value) => {
                          setEnteredNotFormattedPhoneNumber(value);
                          setEnteredPhoneNumber("+" + value);
                        }}
                        value={enteredNotFormattedPhoneNumber}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      className="w-full mt-5 bg-primaryDarkest text-sm font-semibold"
                      loading={loading}
                      htmlType="submit"
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </div>

              <div className="bg-primaryLightest md:w-[43%] flex flex-col justify-center items-center pb-10 md:pb-0">
                <img
                  src={role === 1 ? LoginImageWorker : LoginImageClient}
                  alt="jobs-ninja"
                  className="max-w-[180px] hidden md:block"
                />
                <p className="text-base md:text-lg font-bold mt-3">
                  New to JobsNinja?
                </p>
                <p className="text-sm font-medium text-center">
                  Start by downloading our mobile app, sign up, and set up a PIN
                  to access the web portal !
                </p>
                <img
                  src={role === 1 ? jonninjaWorker : jonninjaClient}
                  alt="jobs-ninja"
                  className="max-w-[170px] mt-7"
                />
                <button
                  className="mt-2"
                  onClick={() => {
                    window.open(
                      role === 1
                        ? "https://apps.apple.com/app/jobsninja-work/id6451492994"
                        : "https://apps.apple.com/app/jobsninja/id6451492483",
                      "_blank"
                    );
                  }}
                >
                  <img src={AppleStore} className="max-w-[150px]" />
                </button>
                <button
                  className="mt-2"
                  onClick={() => {
                    window.open(
                      role === 1
                        ? "https://play.google.com/store/apps/details?id=au.jobninja.trady&hl=en"
                        : "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en",
                      "_blank"
                    );
                  }}
                >
                  <img src={PlayStore} className="max-w-[150px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="No account found"
          content={
            <>
              {errorTypeID === 1 ? (
                <div>
                  <p className="text-sm font-semibold text-warringColorOne text-center">
                    No “Worker” account is registered with this number.
                  </p>

                  <p className="text-xs font-medium mt-5 text-center">
                    Please register as a Worker via JobsNinja (worker) Mobile
                    app
                  </p>
                </div>
              ) : errorTypeID === 2 ? (
                <div>
                  <p className="text-sm font-semibold text-warringColorOne text-center">
                    No “Client” account is registered with this number.
                  </p>

                  <p className="text-xs font-medium mt-5 text-center">
                    Please register as a client via JobsNinja Mobile app
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-sm font-semibold text-warringColorOne text-center">
                    No any existing JobsNinja account registered with this
                    number
                  </p>

                  <p className="text-xs font-medium mt-5 text-center">
                    You can register as a “Client” or “ Worker” via JobsNinja
                    Mobile apps
                  </p>
                </div>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default PhoneNumberCard;
