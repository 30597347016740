import React, { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";

const UserDeletion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="flex justify-center items-center mt-18">
          <div className="my-5 md:my-16 xl:w-[1073px]">
            <header className="text-center">
              <h1 className="text-lg md:text-2xl font-bold">
                Jobs Ninja Data Deletion Request
              </h1>
            </header>

            <main className="p-8 text-justify">
              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">Introduction:</h2>
                <p>
                  Thank you for using Jobs Ninja! We understand that you may
                  want to delete your personal data associated with your
                  account. While we don't offer direct deletion through this
                  page, you can easily initiate the process by visiting our
                  support page.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">
                  Data Deletion Process:
                </h2>
                <ul className="list-disc pl-8">
                  <li>
                    Visit the Jobs Ninja support page at{" "}
                    <a
                      href="https://www.jobsninja.com.au/support"
                      className="text-blue-500 underline text-[9px] xsm:text-base"
                    >
                      https://www.jobsninja.com.au/support
                    </a>
                    .
                  </li>
                  <li>
                    Select the "Data Deletion" section in the drop down menu.
                  </li>
                  <li>
                    Follow the on-screen instructions to submit your request.
                    You may be required to provide your email address associated
                    with the account.
                  </li>
                  <li>
                    Our support team will review your request and initiate the
                    deletion process promptly.
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">Data Deletion:</h2>
                <p>
                  Upon confirmation, the following data will be permanently
                  deleted:
                </p>
                <p>
                  Your account profile information (name, contact numbers, etc.)
                </p>
                <p>Your app usage data (e.g. jobs posts, attachments)</p>
              </div>

              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">Please note:</h2>
                <p>
                  Anonymized usage data for analytics purposes may be retained
                  but will not be linked to your individual account.
                </p>
                <p>
                  Deletion might not be instantaneous but will be completed
                  within 48h time frame depending on internal procedures.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">Verification:</h2>
                <p>
                  You will receive a confirmation email from our support team
                  once your data deletion is complete.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-sm md:text-lg font-bold">
                  Support Contact:
                </h2>
                <p>
                  For any questions or concerns, please visit the Jobs Ninja
                  support page at{" "}
                  <a
                    href="mailto:privacy@JobsNinja.com.au"
                    className="text-blue-500 underline text-[9px] xsm:text-base"
                  >
                    https://www.jobsninja.com.au/support
                  </a>
                </p>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserDeletion;
