import React from "react";

const YoutubeLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4684 6.99797C22.3801 6.65236 22.2109 6.3327 21.9747 6.06538C21.7385 5.79805 21.4421 5.59071 21.11 5.46047C17.8962 4.21922 12.7812 4.23047 12.5 4.23047C12.2188 4.23047 7.10375 4.21922 3.89 5.46047C3.5579 5.59071 3.26153 5.79805 3.02534 6.06538C2.78915 6.3327 2.6199 6.65236 2.53156 6.99797C2.28875 7.9336 2 9.6436 2 12.4805C2 15.3173 2.28875 17.0273 2.53156 17.963C2.61977 18.3088 2.78895 18.6286 3.02515 18.8961C3.26136 19.1636 3.5578 19.3711 3.89 19.5014C6.96875 20.6892 11.7875 20.7305 12.4381 20.7305H12.5619C13.2125 20.7305 18.0341 20.6892 21.11 19.5014C21.4422 19.3711 21.7386 19.1636 21.9748 18.8961C22.211 18.6286 22.3802 18.3088 22.4684 17.963C22.7113 17.0255 23 15.3173 23 12.4805C23 9.6436 22.7113 7.9336 22.4684 6.99797ZM15.7081 12.7927L11.2081 15.7927C11.1516 15.8303 11.086 15.852 11.0181 15.8553C10.9503 15.8586 10.8829 15.8434 10.823 15.8113C10.7631 15.7793 10.7131 15.7316 10.6782 15.6733C10.6434 15.615 10.625 15.5484 10.625 15.4805V9.48047C10.625 9.41257 10.6434 9.34592 10.6782 9.28765C10.7131 9.22938 10.7631 9.18168 10.823 9.14963C10.8829 9.11758 10.9503 9.10238 11.0181 9.10567C11.086 9.10895 11.1516 9.1306 11.2081 9.16828L15.7081 12.1683C15.7596 12.2025 15.8017 12.2489 15.8309 12.3034C15.8601 12.3579 15.8754 12.4187 15.8754 12.4805C15.8754 12.5423 15.8601 12.6031 15.8309 12.6576C15.8017 12.712 15.7596 12.7584 15.7081 12.7927Z"
        fill="#808080"
      />
    </svg>
  );
};

export default YoutubeLogo;
