import React from "react";

const WorkFailIcon = () => {
  return (
    <svg
      width="61"
      height="54"
      viewBox="0 0 61 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30.4492" cy="30.9189" r="15.2929" fill="#FF4640" />
      <path
        d="M37.0471 24.2461L24.2139 37.0794M24.2138 24.2461L37.0471 37.0794"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M3.53639 18.3542L6.44797 20.1158L9.44211 18.2028L7.52915 21.1969L9.29068 24.1085L6.37909 22.347L3.38496 24.26L5.29792 21.2658L3.53639 18.3542Z"
        fill="#FF4640"
      />
      <path
        d="M13.1822 47.0292L15.1792 48.2374L17.2328 46.9254L15.9207 48.979L17.1289 50.976L15.132 49.7678L13.0783 51.0798L14.3904 49.0262L13.1822 47.0292Z"
        fill="#FF4640"
      />
      <path
        d="M30.7177 3.56692L34.1468 5.64154L37.6731 3.38858L35.4202 6.91489L37.4948 10.344L34.0657 8.26935L30.5394 10.5223L32.7924 6.996L30.7177 3.56692Z"
        fill="#FF4640"
      />
      <path
        d="M53.1213 26.3005L55.5649 27.7789L58.0778 26.1734L56.4723 28.6863L57.9507 31.1299L55.5071 29.6515L52.9943 31.257L54.5997 28.7441L53.1213 26.3005Z"
        fill="#FF4640"
      />
      <path
        d="M43.8202 44.2911L45.7639 45.4671L47.7628 44.1901L46.4857 46.1889L47.6617 48.1326L45.7179 46.9567L43.7191 48.2337L44.9962 46.2349L43.8202 44.2911Z"
        fill="#FF4640"
      />
    </svg>
  );
};

export default WorkFailIcon;
