import React, { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div className="content">
        <div>
          <div className="flex flex-col justify-center items-center bg-white w-full mt-20">
            <div className="flex flex-col items-center w-full max-w-[1073px] xl:w-[1073px] my-5 md:my-10 gap-6">
              <header className="text-center">
                <h1 className="text-lg md:text-2xl font-bold">
                  Privacy Policy
                </h1>
                <p>February 2024 (Version 1.0)</p>
              </header>

              <main className="p-8 text-justify">
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    Introduction:
                  </h2>
                  <p>
                    This Privacy Policy is applicable to the collection of all
                    personal information by Idia Corporation Pty Ltd, operating
                    as Jobsninja and its affiliated companies. By utilising
                    JobsNinja websites or mobile applications or engaging with
                    our products or services, you are deemed to consent to the
                    terms outlined in this policy, along with the JobsNinja
                    Terms & Conditions, and agree to abide by them.
                    Additionally, this policy is integrated by reference into
                    the JobsNinja Terms & Conditions governing the products and
                    services offered by JobsNinja.
                  </p>
                </div>
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    PROVISION OF CONFIDENTIAL INFORMATION:
                  </h2>
                  <p className="mb-2">
                    The JobsNinja acknowledges that the Confidential Information
                    has been supplied by the you in confidence, may have
                    considerable value and is of significant importance to the
                    you.
                  </p>
                  <p>
                    The JobsNinja further acknowledges that the you makes no
                    representation with respect to the accuracy or completeness
                    of the Confidential Information except to the extent agreed
                    by the You in writing.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    INFORMATION COLLECTION AND EXPLANATION:
                  </h2>
                  <p className="mb-2">
                    In this policy, we elucidate the methodology and purpose
                    behind the collection of your personal information, the
                    categories of personal information gathered, its
                    utilisation, and the controls available to you over its
                    usage. JobsNinja is steadfast in its commitment to adhere to
                    Commonwealth legislation governing the privacy of personal
                    information in businesses, prioritising the protection and
                    preservation of your privacy when engaging with us.
                  </p>
                  <p>
                    We encourage all JobsNinja users and customers to consult
                    the Office of the Australian Information Commissioner's
                    website at{" "}
                    <a
                      href="https://www.oaic.gov.au/"
                      className="text-blue-500 underline"
                    >
                      www.oaic.gov.au
                    </a>
                    , which provides crucial information on privacy laws in
                    Australia.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    TYPES OF PERSONAL INFORMATION:
                  </h2>
                  <p className="mb-2">
                    The term "Personal Information" refers to any data or
                    viewpoint about an individual, or otherwise pertaining to an
                    individual, where the person is identified or can be
                    identified. "Sensitive information" is a distinct
                    subcategory of Personal Information, encompassing details
                    about an individual's physical and mental health, criminal
                    record, sexual orientation, or other related matters. In
                    this Privacy Policy, any mentions of "Personal Information"
                    should be interpreted to include sensitive information.
                  </p>

                  <p className="font-bold mt-4">
                    The personal information we may collect and retain from you
                    can include:
                  </p>
                  <ul className="list-disc pl-8">
                    <li>
                      Identity information such as your name, age, date of
                      birth, contact details (address, email, phone numbers),
                      licence information, driver's licence or passport numbers,
                      usernames, or passwords.
                    </li>
                    <li>
                      Financial details, including credit card information
                      processed through our payment gateway.
                    </li>
                    <li>
                      Occupational details, work experience, resume,
                      qualifications, education, preferences for earning money,
                      skill set, interests, and other information pertinent to
                      your jobs suitability.
                    </li>
                    <li>
                      Location information, both your current location and the
                      location where you are interested in performing tasks.
                    </li>
                  </ul>

                  <p className="font-bold mt-4">
                    We may collect certain information automatically when you
                    use our App, including:
                  </p>
                  <ul className="list-disc pl-8">
                    <li>
                      <strong>Device Information:</strong>Type of mobile device,
                      Unique device identifiers, IP address, Operating system
                    </li>
                    <li>
                      <strong>Usage Information:</strong> Pages you view,
                      Features you use, Actions you take and application error
                      logs
                    </li>
                    <li>
                      <strong>Cookies and Similar Technologies:</strong>We may
                      use cookies or similar technologies to enhance your
                      experience and gather information about how the App is
                      used.
                    </li>
                  </ul>

                  <p className="mt-4">
                    Without these details, it may impede our ability to conduct
                    our business and provide you with the products or services
                    you require at the level of service we aspire to offer.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    COLLECTION AND STORAGE OF PERSONAL INFORMATION:
                  </h2>
                  <p className="mb-2">
                    We collect your personal information through various
                    channels, including phone calls, emails, devices or browsers
                    used to access our apps and web pages, web forms, IP
                    addresses, unique device IDs, GPS data, cookies, and other
                    technical means. Additionally, information about your use of
                    our services, such as page views, traffic statistics,
                    browsing history, and web log information, may also be
                    collected.
                  </p>
                  <p>
                    We may obtain personal information from affiliates,
                    advertising partners, clients, contractors, customers, third
                    parties, survey or competition websites, and other sources.
                    This information may also be sourced from share registries,
                    publicly available information, and when legally required or
                    authorised to do so.
                  </p>
                  <p>
                    Once collected, we may store your personal information
                    electronically or in paper files.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    VISIBILITY OF USER INFORMATION:
                  </h2>
                  <p>
                    When you log into our services or post any content,
                    including tasks, items, bids, comments, or feedback, your
                    user name and the associated material become visible and
                    searchable to us, other JobsNinja users, and the broader
                    internet community. We advise exercising caution and
                    discretion when posting, considering what and how you
                    disclose your identity on our services. JobsNinja disclaims
                    any control or responsibility for the disclosure or use of
                    voluntarily posted personal data in publicly accessible
                    areas of our services.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    CHILDREN'S PRIVACY:
                  </h2>
                  <p>
                    We do not knowingly collect, maintain, or use personal data
                    from children under 18 years of age, and none of our
                    services are directed toward children. If you become aware
                    that a child has provided us with personal data in violation
                    of this Privacy Policy, please notify us using the contact
                    information provided below.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    PURPOSES OF INFORMATION COLLECTION:
                  </h2>
                  <p className="mb-2">
                    We collect, hold, use, and disclose your personal
                    information for various purposes, including but not limited
                    to:
                  </p>
                  <ul className="list-disc pl-8">
                    <li>
                      Performing and managing business functions and activities,
                      including providing products or services.
                    </li>
                    <li>Managing invoicing and payment systems.</li>
                    <li>
                      Managing relationships with users, customers, and/or
                      shareholders.
                    </li>
                    <li>
                      Identifying and investigating illegal activities or
                      breaches of our Terms and Conditions.
                    </li>
                    <li>Reviewing, processing, and assessing applications.</li>
                    <li>
                      Marketing and advertising our services and those of
                      entities we have relationships with.
                    </li>
                    <li>
                      Assisting in gaining approval to connect with customers
                      for specific jobs categories.
                    </li>
                    <li>
                      Researching, designing, developing, managing, providing,
                      and improving our products and services.
                    </li>
                    <li>Contacting you for various purposes.</li>
                    <li>
                      Complying with laws and assisting government, licensing,
                      or law enforcement agencies.
                    </li>
                  </ul>
                  <p className="mt-4">
                    We may also collect, hold, use, and disclose your personal
                    information for other reasons permitted or required by law.
                    We retain the right to use or disclose personal information
                    for the promotion or delivery of JobsNinja services, such as
                    newsletters, promotional materials, or direct marketing
                    campaigns. If you wish to cease receiving direct marketing
                    communications, follow the provided Unsubscribe link or
                    statement in our communications, or inform us through the
                    contact details in the "Contacting Us" section below.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    DISCLOSURE OF PERSONAL INFORMATION:
                  </h2>
                  <p className="mb-2">
                    Your information may be disclosed to individuals or
                    companies within JobsNinja for the purposes mentioned in the
                    "Why we collect, hold, use, and disclose your personal
                    information" section above. Additionally, personal
                    information may be disclosed to external parties for the
                    specified purposes, including:
                  </p>
                  <ul className="list-disc pl-8">
                    <li>
                      Organisations managing some of our business activities and
                      functions, such as call centre services, marketing,
                      corporate administration, and corporate strategies.
                    </li>
                    <li>
                      Service providers, including share registry service
                      providers, IT service providers, and sponsors or
                      promoters.
                    </li>
                    <li>
                      Organisations with whom JobsNinja has a business
                      relationship, such as partners, joint venture entities,
                      agents, contractors, or external service providers.
                    </li>
                    <li>
                      Third-party payment processing providers or debt recovery
                      agencies in connection with billing or payment for
                      services to JobsNinja users or customers.
                    </li>
                    <li>
                      Police, relevant authorities, licensing or enforcement
                      bodies, government, and regulatory authorities, or
                      organisations, as required or authorised by law.
                    </li>
                    <li>
                      Internet service providers or network administrators, if
                      there is reason to suspect a breach of terms and
                      conditions or engagement in unlawful activity.
                    </li>
                  </ul>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    TRANSFER OF PERSONAL INFORMATION OVERSEAS:
                  </h2>
                  <p className="mb-2">
                    In order to provide you with the best products and services
                    possible, we may transfer or disclose personal information
                    we have collected about you to a person or organisation in a
                    foreign country including:
                  </p>
                  <ul className="list-disc pl-8">
                    <li>
                      to our software developers located outside of Australia
                    </li>
                    <li>
                      to service providers who store data or operate outside
                      Australia; and/or
                    </li>
                    <li>otherwise as required or authorised by law.</li>
                  </ul>
                  <p>
                    Where we do this, we take reasonable steps to ensure that
                    those parties do not breach the Australian Privacy Law.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    LINKS TO OTHER WEBSITES:
                  </h2>
                  <p>
                    Our website may contain links to other websites, and these
                    third-party websites may collect personal information about
                    you. We are not responsible for the privacy practices of
                    other businesses or the content of websites linked to our
                    website. Users are encouraged to be mindful when leaving the
                    JobsNinja site and to review the privacy statements of each
                    website collecting personally identifiable information.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    SECURITY AND STORAGE:
                  </h2>
                  <p className="mb-2">
                    JobsNinja prioritises the security of information associated
                    with our customers and contractors. Security measures are in
                    place to prevent misuse, interference, loss, unauthorised
                    access, modification, or disclosure of personal information
                    under our control. Information provided to us is retained,
                    including contact or bank account details, to verify
                    transactions,customer details and maintain records for legal
                    and accounting purposes. This information is securely held
                    on servers in controlled facilities.
                  </p>
                  <p className="mb-2">
                    We adopt appropriate data collection, storage, and
                    processing practices and security measures to protect
                    against unauthorized access, alteration, disclosure, or
                    destruction of your personal information, username,
                    password, transaction information, and data stored on our
                    App.
                  </p>
                  <p>
                    While we strive to protect users' personal information, we
                    cannot guarantee the security of information transmitted to
                    or from our online products or services over the Internet.
                    Users are responsible for safeguarding their passwords and
                    account information.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    ACCESS AND CORRECTION OF PERSONAL INFORMATION:
                  </h2>
                  <p>
                    JobsNinja is dedicated to maintaining accurate, timely,
                    relevant, and appropriate information about our customers
                    and website users. To access or correct your personal
                    information held by us, contact us using the details
                    provided in the "Contacting Us" section below. We will grant
                    access to your information if your request aligns with the
                    Australian Privacy Law. Inaccurate information will be
                    corrected upon request, provided we can verify its relation
                    to you. If access or correction is refused, reasons for the
                    refusal will be provided.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    FACEBOOK PLATFORM DATA:
                  </h2>
                  <p>
                    Unless required to retain Facebook Platform Data under
                    applicable laws or regulations, you may request JobsNinja to
                    delete your Facebook Platform Data.
                  </p>
                  <p>
                    <b>Facebook Platform Data</b> means “Platform Data” as
                    defined in the{" "}
                    <a
                      href="https://developers.facebook.com/terms/dfc_platform_terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      Facebook Meta Platform Terms
                    </a>
                    .
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    USE OF GOOGLE ANALYTICS :
                  </h2>
                  <p className="mb-2">
                    We use Google Analytics, a web analytics service provided by
                    Google, Inc. ("Google"). Google Analytics uses cookies to
                    analyse your use of the App. The information generated by
                    the cookie about your use of the App (including your IP
                    address) will be transmitted to and stored by Google on
                    servers in the United States.
                  </p>
                  <p className="mb-2">
                    We use this information to evaluate your use of the App,
                    compile reports on App activity, and provide other services
                    related to App activity and internet usage. Google may also
                    transfer this information to third parties where required to
                    do so by law, or where such third parties process the
                    information on Google's behalf.
                  </p>
                  <p>
                    By using the App, you consent to the processing of data
                    about you by Google in the manner and for the purposes set
                    out above.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    YOUR CHOICES:
                  </h2>
                  <p>
                    You can control cookies and similar technologies through
                    your device settings. Note that disabling cookies may limit
                    the functionality of the App.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    USE OF STRIPE SERVICES:
                  </h2>
                  <p className="mb-2">
                    We use the services of Stripe, a third-party payment
                    processor, to facilitate secure online transactions. When
                    you purchase our App, your payment information is securely
                    transmitted to Stripe for processing.
                  </p>
                  <p>
                    Stripe has its own Privacy Policy, which can be viewed on
                    their website{" "}
                    <a
                      href="https://stripe.com/au/unsupported-browser?location=%2Fau%2Fprivacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      [link to Stripe's Privacy Policy]
                    </a>
                    . By making a purchase through our App, you acknowledge and
                    agree to Stripe's Privacy Policy.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    USE OF FIREBASE SERVICES:
                  </h2>
                  <p className="mb-2">
                    We use Firebase, a mobile and web application development
                    platform provided by Google, Inc., to power our chat
                    feature. Firebase provides a secure, scalable infrastructure
                    for real-time communication.
                  </p>
                  <p>
                    Firebase has its own Privacy Policy, which can be viewed on
                    its website{" "}
                    <a
                      href="https://firebase.google.com/support/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      [link to Firebase Privacy Policy]
                    </a>
                    . By using our chat feature, you acknowledge and agree to
                    Firebase's Privacy Policy.
                  </p>
                </div>
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">
                    USE OF AMAZON PINPOINT SMS AND VOICE API RESOURCES:
                  </h2>
                  <p>
                    Our mobile apps leverage Amazon Pinpoint SMS and Voice API
                    resources to facilitate the delivery of SMS messages and
                    voice calls, serving various purposes including account
                    verification, notifications, and promotional communications.
                    Your use of our application implies consent to the
                    utilization of these services. For more information
                    regarding data protection guidelines concerning Amazon
                    Pinpoint SMS and Voice API resources, please refer to the
                    following link:{" "}
                    <a
                      href="https://docs.aws.amazon.com/pinpoint/latest/developerguide/security-data-protection.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      [Data protection guidelines for Amazon Pinpoint SMS and
                      Voice API Resources]
                    </a>
                    .
                  </p>
                </div>
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    SECURITY OF YOUR DATA:
                  </h2>
                  <p>
                    We take the security of your data seriously. Firebase
                    employs industry-standard security measures to protect your
                    chat data from unauthorised access, disclosure, alteration,
                    or destruction.
                  </p>
                </div>
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    YOUR CHOICES:
                  </h2>
                  <p>
                    You have the option to participate in the chat feature, and
                    by doing so, you agree to the terms outlined in this Privacy
                    Policy.
                  </p>
                </div>
                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    DATA RETENTION:
                  </h2>
                  <p>
                    We will retain your chat data for as long as necessary to
                    fulfil the purposes outlined in this Privacy Policy and as
                    required by applicable law.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">COMPLAINTS:</h2>
                  <p>
                    If you have a complaint regarding our Privacy Policy or the
                    collection, use, or disposal of your personal information,
                    contact us at{" "}
                    <a
                      href="mailto:privacy@JobsNinja.com.au"
                      className="text-blue-500 underline"
                    >
                      privacy@JobsNinja.com.au
                    </a>
                    . We will investigate and seek to resolve any breaches
                    within 30 days in accordance with Commonwealth Privacy
                    legislation. If you are not satisfied with the outcome of
                    this procedure then you may request that an independent
                    person (usually the Information Commissioner) investigate
                    your complaint.
                  </p>

                  <h2 className="text-sm md:text-lg font-bold mt-8">
                    CHANGES TO PRIVACY POLICY:
                  </h2>
                  <p className="mb-2">
                    Company has the discretion to update this Privacy Policy and
                    Terms of Service at any time. When we do, we will revise the
                    updated date at the bottom of this page. We encourage Users
                    to frequently check this page for any changes to stay
                    informed about how we are helping to protect the personal
                    information we collect. You acknowledge and agree that it is
                    your responsibility to review this Privacy Policy and Terms
                    of Service periodically and become aware of modifications
                  </p>
                  <p>
                    Any changes to our Privacy Policy will be posted on this
                    Privacy Policy page to keep users informed of information
                    collection, usage, and disclosure practices. Users are
                    encouraged to periodically review this Privacy Policy for
                    amendments. The privacy policy was last updated on the 5th
                    of February 2024.
                  </p>
                </div>

                <div className="mb-8">
                  <h2 className="text-sm md:text-lg font-bold">CONTACT US:</h2>
                  <p>
                    For further inquiries or information regarding our Privacy
                    Policy, contact us via email at{" "}
                    <a
                      href="mailto:privacy@JobsNinja.com.au"
                      className="text-blue-500 underline"
                    >
                      privacy@JobsNinja.com.au
                    </a>{" "}
                    or write to us at the provided address. For additional
                    information on privacy legislation or Australian Privacy
                    Principles, visit the Office of the Information
                    Commissioner's website at{" "}
                    <a
                      href="https://www.oaic.gov.au/"
                      className="text-blue-500 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.oaic.gov.au
                    </a>
                    .
                  </p>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
