import React, { useContext } from "react";
import { Col, Row } from "antd";
import Job1 from "../../assets/img/job1.webp";
import Job2 from "../../assets/img/job2.webp";
import Job3 from "../../assets/img/job3.webp";
import Job4 from "../../assets/img/job4.webp";
import Job5 from "../../assets/img/job5.webp";
import Job6 from "../../assets/img/job6.webp";
import Job7 from "../../assets/img/job7.webp";
import Job8 from "../../assets/img/job8.webp";
import Job9 from "../../assets/img/job9.webp";
import { useNavigate } from "react-router-dom";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";

const PopularJobs = () => {
  const navigate = useNavigate();
  const {
    setStepperCurrentSteps,
    setLocationValues,
    setjobValues,
    setdescriptionValues,
    setSelectedCountryType,
    setSelectedJobType,
    setPageEnterdType,
  } = useContext(CreateJobPostContext);
  const jobs = [
    { title: "Painting", imgSrc: Job1, id: 110 },
    { title: "Furniture Assembly", imgSrc: Job2, id: 13 },
    { title: "Help Moving", imgSrc: Job3, id: 205 },
    { title: "House Cleaning", imgSrc: Job4, id: 3 },
    { title: "Mounting Art", imgSrc: Job5, id: 196 },
    { title: "Lawn Mowing", imgSrc: Job6, id: 40 },
    { title: "Electrical Help", imgSrc: Job7, id: 59 },
    { title: "Mounting TV", imgSrc: Job8, id: 30 },
    { title: "Plumbing", imgSrc: Job9, id: 2 },
  ];

  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] overflow-hidden">
        <h2 className="title-level2 custom-font text-center">Popular Jobs</h2>
        <Row gutter={16} className="mt-8 md:mt-10">
          {jobs.map((job, index) => (
            <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} key={index}>
              <div
                className="shadow-lg mb-5 rounded-lg p-5 cursor-pointer hover:shadow-2xl"
                onClick={() => {
                  setStepperCurrentSteps(0);
                  setPageEnterdType(0);
                  setLocationValues({
                    country: undefined,
                    conuntryName: undefined,
                    state: undefined,
                    suburb: undefined,
                    postalCodetype0: undefined,
                    postalCodetype1: undefined,
                    postalCodetype2: undefined,
                    district: undefined,
                    districtName: undefined,
                    city: undefined,
                    cityName: undefined,
                  });

                  setjobValues({
                    jobOne: true,
                    jobTwo: false,
                    jobThree: false,
                  });
                  setdescriptionValues("");
                  setSelectedCountryType(1);

                  if (getLocalStoragedata("userTypeId") !== 6) {
                    setSelectedJobType(job?.title);
                    setLocalStorageData("searchSelectedJobTypeId", job?.id);
                    setLocalStorageData("searchSelectedJobTypeName", job.title);
                    navigate("./create-job-post");
                  }
                }}
              >
                <img src={job?.imgSrc} alt={job.title} height={100} />

                <p className="text-base lg:text-xl xl:text-[24px] font-semibold text-start mt-2">
                  {job.title}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PopularJobs;
