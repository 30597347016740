import React from "react";

const LinkLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 7.48047C4.5 5.82347 5.843 4.48047 7.5 4.48047H17.5C19.157 4.48047 20.5 5.82347 20.5 7.48047V17.4805C20.5 19.1375 19.157 20.4805 17.5 20.4805H7.5C5.843 20.4805 4.5 19.1375 4.5 17.4805V7.48047ZM9.35 17.8735V10.6495H6.934V17.8735H9.35ZM8.142 9.66247C8.984 9.66247 9.509 9.10847 9.509 8.41447C9.493 7.70547 8.984 7.16647 8.158 7.16647C7.331 7.16647 6.791 7.70547 6.791 8.41447C6.791 9.10847 7.315 9.66247 8.126 9.66247H8.142ZM13.104 17.8735V13.8395C13.104 13.6235 13.12 13.4085 13.183 13.2535C13.358 12.8225 13.756 12.3755 14.423 12.3755C15.298 12.3755 15.648 13.0375 15.648 14.0095V17.8735H18.064V13.7315C18.064 11.5125 16.872 10.4795 15.282 10.4795C14 10.4795 13.425 11.1795 13.104 11.6725V10.6495H10.687C10.719 11.3245 10.688 17.8325 10.687 17.8745L13.104 17.8735Z"
        fill="#808080"
      />
    </svg>
  );
};

export default LinkLogo;
