import React from "react";
import { Link } from "react-router-dom";
import XLogo from "../../assets/svg/XLogo";
import InstaLogo from "../../assets/svg/InstaLogo";
import PinLogo from "../../assets/svg/PinLogo";
import LinkLogo from "../../assets/svg/LinkLogo";
import TickLogo from "../../assets/svg/TickLogo";
import YoutubeLogo from "../../assets/svg/YoutubeLogo";
import FooterLogo from "../../assets/svg/FooterLogo";
import PlayStore from "../../assets/img/playStore.png";
import AppleStore from "../../assets/img/appleStore.png";
import JobninjaFooterClient from "../../assets/svg/JobninjaFooterClient";
import JobNinjaFooterWork from "../../assets/svg/JobNinjaFooterWork";

const socialLinks = [
  { logo: XLogo, url: "https://x.com/jobsNinjaApp" },
  { logo: InstaLogo, url: "https://www.instagram.com/jobsninjaau/" },
  { logo: PinLogo, url: "https://au.pinterest.com/JobsNinja/" },
  { logo: LinkLogo, url: "https://www.linkedin.com/company/jobsninjaapp" },
  { logo: TickLogo, url: "https://www.tiktok.com/@jobsninjaau" },
  { logo: YoutubeLogo, url: "https://www.youtube.com/@jobsNinjaApp" },
];

const appLinks = [
  {
    id: "client",
    playStore:
      "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en",
    appleStore: "https://apps.apple.com/app/jobsninja/id6451492483",
    logo: JobninjaFooterClient,
  },
  {
    id: "work",
    playStore:
      "https://play.google.com/store/apps/details?id=au.jobninja.trady&hl=en",
    appleStore: "https://apps.apple.com/app/jobsninja-work/id6451492994",
    logo: JobNinjaFooterWork,
  },
];

const Footer = () => {
  return (
    <div className="flex flex-row gap-2 w-full min-h-[319px] items-center justify-center bg-darkestColor px-5 mt-8 md:mt-16">
      <div className="max-w-[1280px] w-full my-20 md:my-0">
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          {/* Left section: Logo, Description, and Social Links */}
          <div className="flex flex-col justify-center items-center md:justify-start md:items-start w-[40%]">
            <FooterLogo />
            <p className="text-sm font-normal text-textColorZero pt-2">
              The ultimate on-demand job platform
            </p>
            <div className="flex flex-row gap-1 pt-10 md:pt-24">
              {socialLinks.map(({ logo: Logo, url }, index) => (
                <span
                  key={index}
                  className="cursor-pointer"
                  onClick={() => window.open(url, "_blank")}
                >
                  <Logo />
                </span>
              ))}
            </div>
          </div>

          {/* Right section: App Download and Links */}
          <div className="grid md:grid-cols-2 lg:gap-8 mt-8 md:mt-0 w-[60%]">
            {/* App Download Section */}
            <div className="flex flex-col gap-3 justify-center items-center md:justify-start md:items-start">
              <p className="text-sm font-bold text-white">Download our apps</p>
              <div className="flex flex-row md:flex-col lg:flex-row gap-5">
                {appLinks.map(
                  ({ logo: Logo, playStore, appleStore }, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center md:justify-start md:items-start flex-col gap-2"
                    >
                      <Logo />
                      <button onClick={() => window.open(playStore, "_blank")}>
                        <img
                          src={PlayStore}
                          className="w-[80px] xl:w-[100px]"
                        />
                      </button>
                      <button onClick={() => window.open(appleStore, "_blank")}>
                        <img
                          src={AppleStore}
                          className="w-[80px] xl:w-[100px]"
                        />
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Company and Legal Links */}
            <div className="flex flex-row justify-between gap-16 mt-8 md:mt-0">
              {/* Company Section */}
              <div className="flex flex-col gap-2">
                <Link to="">
                  <p className="text-sm font-bold text-white">Company</p>
                </Link>
                <Link to="/">
                  <p className="text-sm font-normal text-textColorZero">Home</p>
                </Link>
                <Link to="/">
                  <p className="text-sm font-normal text-textColorZero">
                    For Worker
                  </p>
                </Link>
                <p className="text-xs font-normal text-textColorZero">
                  <span className="font-semibold text-sm">
                    Idia Corporation Pty Ltd
                  </span>
                  <br />
                  Suite 112, Waterman Caribbean Park,
                  <br />
                  44 Lakeview Drive, Scoresby,
                  <br />
                  Victoria 3179, Australia
                  <br />
                  hello@idiacorp.com
                </p>
              </div>

              {/* Legal Section */}
              <div className="flex flex-col gap-2">
                <Link to="">
                  <p className="text-sm font-bold text-white">Legal</p>
                </Link>
                <Link to="/privacy">
                  <p className="text-sm font-normal text-textColorZero">
                    Privacy
                  </p>
                </Link>
                <Link to="/terms-condition">
                  <p className="text-sm font-normal text-textColorZero">
                    Terms
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
