import React from "react";

const CalendarGreenOutlineIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33331 10.0001C3.33331 7.48592 3.33331 6.22885 4.11436 5.4478C4.89541 4.66675 6.15249 4.66675 8.66665 4.66675H11.3333C13.8475 4.66675 15.1045 4.66675 15.8856 5.4478C16.6666 6.22885 16.6666 7.48592 16.6666 10.0001V11.3334C16.6666 13.8476 16.6666 15.1047 15.8856 15.8857C15.1045 16.6667 13.8475 16.6667 11.3333 16.6667H8.66665C6.15249 16.6667 4.89541 16.6667 4.11436 15.8857C3.33331 15.1047 3.33331 13.8476 3.33331 11.3334V10.0001Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M6.66669 4.66675V3.66675"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.3333 4.66675V3.66675"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.66669 8H16.3334"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 13.3333C14 13.7015 13.7015 14 13.3334 14C12.9652 14 12.6667 13.7015 12.6667 13.3333C12.6667 12.9651 12.9652 12.6667 13.3334 12.6667C13.7015 12.6667 14 12.9651 14 13.3333Z"
        fill="#2C8B52"
      />
      <path
        d="M14 10.6667C14 11.0349 13.7015 11.3333 13.3334 11.3333C12.9652 11.3333 12.6667 11.0349 12.6667 10.6667C12.6667 10.2985 12.9652 10 13.3334 10C13.7015 10 14 10.2985 14 10.6667Z"
        fill="#2C8B52"
      />
      <path
        d="M10.6666 13.3333C10.6666 13.7015 10.3682 14 9.99998 14C9.63179 14 9.33331 13.7015 9.33331 13.3333C9.33331 12.9651 9.63179 12.6667 9.99998 12.6667C10.3682 12.6667 10.6666 12.9651 10.6666 13.3333Z"
        fill="#2C8B52"
      />
      <path
        d="M10.6666 10.6667C10.6666 11.0349 10.3682 11.3333 9.99998 11.3333C9.63179 11.3333 9.33331 11.0349 9.33331 10.6667C9.33331 10.2985 9.63179 10 9.99998 10C10.3682 10 10.6666 10.2985 10.6666 10.6667Z"
        fill="#2C8B52"
      />
      <path
        d="M7.33333 13.3333C7.33333 13.7015 7.03486 14 6.66667 14C6.29848 14 6 13.7015 6 13.3333C6 12.9651 6.29848 12.6667 6.66667 12.6667C7.03486 12.6667 7.33333 12.9651 7.33333 13.3333Z"
        fill="#2C8B52"
      />
      <path
        d="M7.33333 10.6667C7.33333 11.0349 7.03486 11.3333 6.66667 11.3333C6.29848 11.3333 6 11.0349 6 10.6667C6 10.2985 6.29848 10 6.66667 10C7.03486 10 7.33333 10.2985 7.33333 10.6667Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default CalendarGreenOutlineIcon;
