import React from "react";

const WorkDoneIcon = () => {
  return (
    <svg
      width="57"
      height="50"
      viewBox="0 0 57 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3188" cy="28.7558" r="15.2929" fill="#2C8B52" />
      <path
        d="M26.0589 34.3006L20.8022 29.044L22.1164 27.7298L26.0589 31.6723L34.5203 23.2109L35.8344 24.5251L26.0589 34.3006Z"
        fill="white"
      />
      <path
        d="M4.28305 14.9995C4.78098 17.0228 6.38468 18.5878 8.41954 19.0361L8.5661 19.0684L8.41954 19.1007C6.38468 19.549 4.78098 21.114 4.28305 23.1373C3.78512 21.114 2.18142 19.549 0.146564 19.1007L0 19.0684L0.146564 19.0361C2.18142 18.5878 3.78512 17.0228 4.28305 14.9995Z"
        fill="#2C8B52"
      />
      <path
        d="M13.025 44.0488C13.3666 45.4366 14.4665 46.5099 15.8622 46.8174L15.9627 46.8396L15.8622 46.8617C14.4665 47.1692 13.3666 48.2426 13.025 49.6304C12.6835 48.2426 11.5836 47.1692 10.1879 46.8617L10.0874 46.8396L10.1879 46.8174C11.5836 46.5099 12.6835 45.4366 13.025 44.0488Z"
        fill="#2C8B52"
      />
      <path
        d="M31.9755 0C32.5619 2.3829 34.4506 4.22604 36.8472 4.75406L37.0198 4.79209L36.8472 4.83013C34.4506 5.35815 32.5619 7.20128 31.9755 9.58419C31.389 7.20129 29.5003 5.35815 27.1038 4.83013L26.9312 4.79209L27.1038 4.75406C29.5003 4.22604 31.389 2.3829 31.9755 0Z"
        fill="#2C8B52"
      />
      <path
        d="M53.4051 23.1372C53.823 24.8353 55.1689 26.1487 56.8767 26.525L56.9997 26.5521L56.8767 26.5792C55.1689 26.9554 53.823 28.2689 53.4051 29.9669C52.9872 28.2689 51.6413 26.9554 49.9336 26.5792L49.8105 26.5521L49.9336 26.525C51.6413 26.1487 52.9872 24.8353 53.4051 23.1372Z"
        fill="#2C8B52"
      />
      <path
        d="M43.6108 41.3325C43.9432 42.6832 45.0138 43.728 46.3723 44.0273L46.4701 44.0489L46.3723 44.0704C45.0138 44.3697 43.9432 45.4145 43.6108 46.7652C43.2784 45.4145 42.2078 44.3697 40.8493 44.0704L40.7515 44.0489L40.8493 44.0273C42.2078 43.728 43.2784 42.6832 43.6108 41.3325Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default WorkDoneIcon;
