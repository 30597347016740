import React, { useContext, useEffect } from "react";
import { Steps } from "antd";
import { CreateJobPostContext } from "../../../context/CreateJobPostContext";

const JobpostLayout = ({ children }) => {
  const {
    stepperCurrentSteps,
    setStepperCurrentSteps,
    completedStep,
    setCompletedStep,
  } = useContext(CreateJobPostContext);

  const handleStepClick = (step) => {
    if (completedStep >= step) {
      setStepperCurrentSteps(step);
    }
  };

  return (
    <>
      <div className="flex w-full justify-center items-center">
        <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 rounded-lg overflow-hidden mt-10">
          <div className="w-full flex justify-center items-center">
            <div className="w-full sm:max-w-[500px]">
              <Steps
                className="text-xs font-semibold"
                size="small"
                onChange={(e) => {
                  handleStepClick(e);
                }}
                current={stepperCurrentSteps}
                direction="horizontal"
                items={[
                  {
                    title: "Job details",
                  },
                  {
                    title: "Summary and post",
                  },
                ]}
              />
            </div>
          </div>

          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default JobpostLayout;
