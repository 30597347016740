import { createContext, useState } from "react";

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const [authSteps, setAuthSteps] = useState(1);
  const [otpAttemptCount, setOtpAttemptCount] = useState();
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState();
  const [enteredNotFormattedPhoneNumber, setEnteredNotFormattedPhoneNumber] =
    useState();
  const [token, setToken] = useState();
  const [currentUserData, setCurrentUserData] = useState();
  const [tryToLoginUserID, setTryToLoginUserID] = useState();

  return (
    <AuthContext.Provider
      value={{
        authSteps,
        setAuthSteps,
        otpAttemptCount,
        setOtpAttemptCount,
        enteredPhoneNumber,
        setEnteredPhoneNumber,

        enteredNotFormattedPhoneNumber,
        setEnteredNotFormattedPhoneNumber,

        token,
        setToken,

        currentUserData,
        setCurrentUserData,

        tryToLoginUserID,
        setTryToLoginUserID,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
