import React from "react";

const HintIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.0001"
        cy="11.0003"
        r="7.33333"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M11 7.33301V11.733"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="10.9999" cy="13.9335" r="0.733333" fill="#2C8B52" />
    </svg>
  );
};

export default HintIcon;
