import React from "react";

const LocationIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0742 22.8346C18.0742 16.0884 23.4468 10.6196 30.0742 10.6196C36.7016 10.6196 42.0742 16.0884 42.0742 22.8346C42.0742 29.5278 38.2442 37.3382 32.2686 40.1313C30.8756 40.7824 29.2728 40.7824 27.8798 40.1313C21.9042 37.3382 18.0742 29.5278 18.0742 22.8346ZM30.0742 26.6196C32.2834 26.6196 34.0742 24.8288 34.0742 22.6196C34.0742 20.4105 32.2834 18.6196 30.0742 18.6196C27.8651 18.6196 26.0742 20.4105 26.0742 22.6196C26.0742 24.8288 27.8651 26.6196 30.0742 26.6196Z"
        fill="#F2483E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3288 35.6882C13.8433 36.3375 13.734 37.2809 13.0847 37.7954C11.9374 38.7044 11.5742 39.5057 11.5742 40.1197C11.5742 40.6056 11.7948 41.1958 12.4555 41.8789C13.123 42.569 14.1659 43.27 15.5853 43.9138C18.0938 45.0515 21.5612 45.9129 25.5742 46.2882V45.3697C25.5742 44.7811 25.9184 44.2469 26.4544 44.0037C26.9904 43.7605 27.619 43.8533 28.062 44.2408L31.062 46.8658C31.3875 47.1507 31.5742 47.5621 31.5742 47.9947C31.5742 48.4272 31.3875 48.8387 31.062 49.1236L28.062 51.7486C27.619 52.1361 26.9904 52.2288 26.4544 51.9856C25.9184 51.7424 25.5742 51.2083 25.5742 50.6197V49.3005C21.2045 48.9171 17.2928 47.9823 14.3462 46.6459C12.7185 45.9077 11.3156 45.0155 10.2991 43.9645C9.27584 42.9066 8.57422 41.6077 8.57422 40.1197C8.57422 38.2192 9.70755 36.6436 11.2216 35.444C11.8709 34.9295 12.8144 35.0388 13.3288 35.6882ZM46.8196 35.6882C47.3341 35.0388 48.2775 34.9295 48.9268 35.444C50.4409 36.6436 51.5742 38.2192 51.5742 40.1197C51.5742 42.8759 49.2381 44.9409 46.481 46.3224C43.5976 47.7673 39.6563 48.7899 35.2255 49.2389C34.4013 49.3225 33.6654 48.722 33.5819 47.8978C33.4983 47.0736 34.0988 46.3378 34.923 46.2542C39.1183 45.8291 42.677 44.873 45.137 43.6403C47.7234 42.3443 48.5742 41.033 48.5742 40.1197C48.5742 39.5057 48.2111 38.7044 47.0638 37.7954C46.4144 37.2809 46.3051 36.3375 46.8196 35.6882Z"
        fill="#F2483E"
      />
    </svg>
  );
};

export default LocationIcon;
