import React, { useContext, useEffect, useState } from "react";
import { Input, Pagination, Spin } from "antd";
import { IoSearchOutline } from "react-icons/io5";
import DownloadIcon from "../../assets/svg/DownloadIcon";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { format } from "date-fns";
import PaymentInvoice from "./PaymentInvoice";

const PaymentHistory = () => {
  const { currentPackageDetails } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [openCloseDownloadInvoiceModal, setOpenCloseDownloadInvoiceModat] =
    useState(false);
  const [selectedHistoryInvoiceData, setSelectedHistoryInvoiceData] =
    useState();

  useEffect(() => {
    getPaymentHistoryList();
  }, []);

  const getPaymentHistoryList = () => {
    setLoading(true);

    let historyArray = [];
    currentPackageDetails?.payment_history?.forEach((item) => {
      if (item?.amount !== 0) {
        historyArray.push(item);
      }
    });

    setHistoryList(historyArray);
    setLoading(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredHistoryList = historyList.filter((item) => {
    const transactionIdMatch = item?.transactoin_id
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const paymentDateMatch = format(new Date(item?.payment_date), "yyyy MMM dd")
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return transactionIdMatch || paymentDateMatch;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredHistoryList.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full max-w-[1073px] min-h-screen flex flex-col relative">
      <div className="flex flex-col  items-center flex-grow">
        <div className="max-w-[400px] w-full mt-5">
          <Input
            placeholder="Search Invoice..."
            suffix={<IoSearchOutline />}
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="flex flex-col gap-2">
            {loading ? (
              <Spin />
            ) : (
              <>
                {currentItems?.length !== 0 ? (
                  <div className="mt-8 flex flex-col gap-2">
                    {currentItems.map((item) => (
                      <div
                        key={item?.transactoin_id}
                        className="border-[2px] p-2 rounded-lg flex flex-row w-full gap-3"
                      >
                        <div className="w-full">
                          <p className="flex flex-row justify-between items-center text-sm font-medium">
                            {format(
                              new Date(item?.payment_date),
                              "yyyy MMM dd"
                            )}
                            <div className="text-xs">
                              {item?.transactoin_id}
                            </div>
                          </p>

                          <p className="flex flex-row justify-between items-center text-xs font-medium mt-3 text-textColorTwo">
                            {currentPackageDetails?.package_name}
                            <div className="text-sm font-bold text-textColorOne">
                              {item?.amount}{" "}
                              {currentPackageDetails?.currency_label}
                            </div>
                          </p>
                        </div>

                        <div
                          className="flex justify-end items-end cursor-pointer"
                          onClick={() => {
                            setSelectedHistoryInvoiceData(item);
                            setOpenCloseDownloadInvoiceModat(true);
                          }}
                        >
                          <DownloadIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-sm font-bold text-center text-textColorTwo mt-8">
                    No data !
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end absolute bottom-5 md:bottom-10 lg:bottom-16 xl:bottom-40 right-0">
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={filteredHistoryList.length}
          onChange={handlePageChange}
          showSizeChanger={false}
          responsive={true}
        />
      </div>

      {openCloseDownloadInvoiceModal && (
        <PaymentInvoice
          invoiceModalOpen={openCloseDownloadInvoiceModal}
          modelClose={() => {
            setOpenCloseDownloadInvoiceModat(false);
          }}
          // billingData,
          historyData={selectedHistoryInvoiceData}
          // currentUserData={}
        />
      )}
    </div>
  );
};

export default PaymentHistory;
