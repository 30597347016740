import React from "react";

const SearchIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.891602"
        y="0.0541992"
        width="50"
        height="50"
        rx="25"
        fill="#3BB96E"
      />
      <circle
        cx="24.2787"
        cy="23.5924"
        r="10.2318"
        stroke="white"
        strokeWidth="2.92337"
      />
      <path
        d="M37.4339 36.7476L33.0488 32.3625"
        stroke="white"
        strokeWidth="2.92337"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SearchIcon;
