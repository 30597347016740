import { Button, Drawer, Form, Input } from "antd";
import React, { useContext } from "react";
import CloseIcon from "../../assets/svg/CloseIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import { AuthContext } from "../../context/AuthContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const ChangeBillingAddres = ({ open, onClose }) => {
  let { currentUserData } = useContext(AuthContext);
  let { setChangedBillingAddress, changedBillingAddres } =
    useContext(SubscriptionContext);

  const handleBillingAddressChange = (e) => {
    setChangedBillingAddress(e);
    onClose();
  };

  return (
    <Drawer open={open} onClose={onClose} width={"large"} closeIcon={false}>
      <div className="w-full min-w-[260px] sm:w-[500px] md:w-[700px]">
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon color="#4d5056" />
        </div>

        <p className="text-sm font-bold text-textColorOne text-center mb-5">
          Billing address
        </p>

        <Form
          layout="vertical"
          className="md:mx-20"
          requiredMark={false}
          fields={[
            {
              name: ["addressline01"],
              value: decryptSecureData(changedBillingAddres?.addressline01),
            },
            {
              name: ["addressline02"],
              value: decryptSecureData(changedBillingAddres?.addressline02),
            },
            { name: ["postalCode"], value: changedBillingAddres?.postalCode },
            {
              name: ["cityorsuburb"],
              value: changedBillingAddres?.cityorsuburb,
            },
            {
              name: ["stateorprovince"],
              value: changedBillingAddres?.stateorprovince,
            },
            { name: ["country"], value: changedBillingAddres?.country },
          ]}
          onFinish={handleBillingAddressChange}
        >
          <Form.Item
            name="addressline01"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">Address line 01 *</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Address line 01 is required",
              },
            ]}
          >
            <Input placeholder="Address line 01" />
          </Form.Item>

          <Form.Item
            name="addressline02"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">Address line 02</p>
              </div>
            }
          >
            <Input placeholder="Address line 02" />
          </Form.Item>

          <Form.Item
            name="postalCode"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">Postal Code *</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Postal Code is required",
              },
            ]}
          >
            <Input placeholder="Postal Code" />
          </Form.Item>

          <Form.Item
            name="cityorsuburb"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">City or suburb *</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "City or suburb is required",
              },
            ]}
          >
            <Input placeholder="City or suburb" />
          </Form.Item>

          <Form.Item
            name="stateorprovince"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">State or province *</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "State or province is required",
              },
            ]}
          >
            <Input placeholder="State or province" />
          </Form.Item>

          <Form.Item
            name="country"
            label={
              <div className="flex flex-row gap-1 justify-center items-center">
                <LocationIconCard />
                <p className="text-[13px] font-medium">Country *</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Country is required",
              },
            ]}
          >
            <Input placeholder="Country" />
          </Form.Item>

          <div className="flex flex-row justify-between items-center gap-5 mt-10">
            <Form.Item className="w-full">
              <Button
                type="primary"
                className="bg-primaryDark w-full"
                onClick={() => {
                  onClose();
                }}
              >
                Back
              </Button>
            </Form.Item>

            <Form.Item className="w-full">
              <Button
                type="primary"
                className="bg-primaryDarkest w-full"
                htmlType="submit"
              >
                Save address
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ChangeBillingAddres;
