import { Button, ConfigProvider, Divider, Modal } from "antd";
import React, { useContext } from "react";
import CloseIcon from "../../assets/svg/CloseIcon";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import HeaderConfig from "../../helpers/HeaderConfig";
import SubscriptionServices from "../../services/SubscriptionServices";

const CancelSubscriptionModal = ({
  open,
  onCancel,
  handleGetSubscriptionDetails,
}) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { configAuth } = HeaderConfig();
  const { cancelSubscription } = SubscriptionServices();

  const listArray = [
    "View new public leads.",
    "Apply to new public leads.",
    "Apply to invited leads / jobs.",
    "Your contact information will no longer be displayed on your public profile.",
  ];

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription(configAuth, {
        trady_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            handleGetSubscriptionDetails();
            onCancel();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <Modal
      title="Cancel Subscription"
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#4d5056" />}
      footer={false}
    >
      <Divider className="my-1" />

      <div className="flex flex-col justify-center items-center mx-5">
        <p className="text-sm font-semibold text-[#F53F3F] text-center mt-5">
          Are you sure you want to cancel JobsNinja Subscription?
        </p>

        <p className="text-sm font-medium text-textColorTwo text-center mt-5">
          When you cancel your subscription plan, you will no longer have access
          to these JobsNinja features.
        </p>

        <div className="mt-5 flex flex-col gap-2">
          {listArray?.map((item) => {
            return (
              <div className="flex flex-row gap-1">
                <CloseIcon color="#F53F3F" />
                <p className="text-xs font-medium text-textColorOne">{item}</p>
              </div>
            );
          })}
        </div>
        <Divider />

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#F53F3F",
              colorInfo: "#F53F3F",
            },
          }}
        >
          <div className="flex flex-row justify-between items-center w-full gap-5">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#0D7CFF",
                  colorInfo: "#0D7CFF",
                },
              }}
            >
              <Button
                type="primary"
                className="bg-[#0D7CFF] w-full"
                onClick={() => {
                  onCancel();
                }}
              >
                Not Now
              </Button>
            </ConfigProvider>

            <Button
              type="default"
              className="border-[#F53F3F] text-[#F53F3F] w-full"
              onClick={() => {
                handleCancelSubscription();
              }}
            >
              Cancel Subscription
            </Button>
          </div>
        </ConfigProvider>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
