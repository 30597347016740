import React from "react";
import { Divider, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import BackIcon from "../../assets/svg/BackIcon";

const DetailsBox = ({ open, onCancel, content, title }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closable={false}
      footer={false}
      width={800}
    >
      <button onClick={onCancel}>
        <BackIcon />
      </button>
      <div className="flex flex-row justify-center">
        <p className="text-lg md:text-2xl font-semibold text-center">{title}</p>
      </div>
      <Divider className="mt-2 mb-5 bg-gray-300" />
      {content}
    </Modal>
  );
};

export default DetailsBox;
