import React from "react";

const XLogo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.141 5.48047H19.595L14.235 11.4105L20.541 19.4805H15.603L11.736 14.5865L7.31102 19.4805H4.85602L10.59 13.1375L4.54102 5.48047H9.60302L13.099 9.95447L17.141 5.48047Z"
        fill="#808080"
      />
    </svg>
  );
};

export default XLogo;
