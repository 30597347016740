import React from "react";

const FlexibleIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4471 18.5492L16.7439 13.8123C16.6459 13.7291 16.5262 13.6759 16.3989 13.6588C16.2715 13.6417 16.142 13.6615 16.0256 13.7159C15.9092 13.7703 15.8109 13.8569 15.7423 13.9655C15.6737 14.0741 15.6377 14.2001 15.6386 14.3286V16.0086C15.6386 16.1866 15.5679 16.3573 15.442 16.4831C15.3162 16.609 15.1455 16.6797 14.9675 16.6797H4.31571C4.13773 16.6797 3.96705 16.7504 3.8412 16.8762C3.71535 17.0021 3.64465 17.1728 3.64465 17.3508V20.7771C3.64465 20.9551 3.71535 21.1258 3.8412 21.2517C3.96705 21.3775 4.13773 21.4482 4.31571 21.4482H14.9707C15.1487 21.4482 15.3193 21.5189 15.4452 21.6448C15.571 21.7706 15.6417 21.9413 15.6417 22.1193V23.8056C15.6408 23.9341 15.6768 24.0601 15.7454 24.1687C15.814 24.2773 15.9124 24.364 16.0288 24.4183C16.1452 24.4727 16.2747 24.4925 16.402 24.4754C16.5293 24.4584 16.6491 24.4051 16.747 24.322L22.4503 19.585C22.5265 19.5218 22.5878 19.4426 22.6298 19.3529C22.6718 19.2632 22.6934 19.1654 22.6931 19.0664C22.6928 18.9674 22.6706 18.8697 22.628 18.7802C22.5855 18.6908 22.5237 18.6119 22.4471 18.5492Z"
        fill="#2B2B2B"
      />
      <path
        d="M5.23626 9.74821H10.5258V10.9214C10.5242 11.1851 10.5986 11.4437 10.74 11.6663C10.8814 11.8889 11.0839 12.0661 11.3232 12.1767C11.5085 12.2639 11.7106 12.3092 11.9153 12.3093C12.2374 12.3105 12.5498 12.1988 12.798 11.9935L17.6155 7.98922C17.7719 7.85912 17.8977 7.69619 17.9841 7.512C18.0705 7.3278 18.1152 7.12685 18.1152 6.92341C18.1152 6.71997 18.0705 6.51902 17.9841 6.33483C17.8977 6.15063 17.7719 5.9877 17.6155 5.8576L12.798 1.84383C12.5955 1.67562 12.3493 1.56855 12.0881 1.53514C11.827 1.50173 11.5618 1.54335 11.3234 1.65515C11.0851 1.76695 10.8835 1.9443 10.7423 2.16645C10.601 2.38861 10.526 2.64639 10.5258 2.90964V4.08125H5.23626C4.86871 4.08166 4.51633 4.22786 4.25643 4.48776C3.99653 4.74766 3.85033 5.10004 3.84991 5.46759V8.36186C3.85033 8.72942 3.99653 9.0818 4.25643 9.3417C4.51633 9.60159 4.86871 9.74779 5.23626 9.74821ZM5.48732 5.71865H10.7722C11.1397 5.71823 11.4921 5.57204 11.752 5.31214C12.0119 5.05224 12.1581 4.69986 12.1585 4.3323V3.44808L16.3302 6.92183L12.1585 10.3956V9.50031C12.1587 9.31804 12.123 9.13752 12.0535 8.96905C11.9839 8.80058 11.8818 8.64746 11.7531 8.51844C11.6244 8.38941 11.4715 8.287 11.3032 8.21706C11.1349 8.14712 10.9544 8.11101 10.7722 8.11081H5.48732V5.71865Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};

export default FlexibleIcon;
