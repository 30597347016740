import React from "react";

const PersonIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30.4069" cy="24.2868" r="6.33268" fill="#F2483E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9141 38.8696C42.0354 39.0887 41.973 39.3617 41.7725 39.5116C38.6031 41.8815 34.6689 43.2849 30.4069 43.2849C26.1449 43.2849 22.2108 41.8815 19.0413 39.5116C18.8408 39.3617 18.7785 39.0887 18.8997 38.8696C20.9053 35.2457 25.3037 32.7305 30.4069 32.7305C35.5102 32.7305 39.9085 35.2457 41.9141 38.8696Z"
        fill="#F2483E"
      />
      <path
        d="M40.9617 13.7324H42.0214C44.5784 13.7324 45.857 13.7324 46.8371 14.2222C47.7367 14.6717 48.466 15.401 48.9155 16.3006C49.4053 17.2807 49.4053 18.5593 49.4053 21.1163V22.176M40.9617 47.5067H42.0214C44.5784 47.5067 45.857 47.5067 46.8371 47.017C47.7367 46.5675 48.466 45.8381 48.9155 44.9385C49.4053 43.9584 49.4053 42.6799 49.4053 40.1228V39.0632M19.8528 13.7324H18.7931C16.236 13.7324 14.9575 13.7324 13.9774 14.2222C13.0778 14.6717 12.3484 15.401 11.8989 16.3006C11.4092 17.2807 11.4092 18.5593 11.4092 21.1163V22.176M19.8528 47.5067H18.7931C16.236 47.5067 14.9575 47.5067 13.9774 47.017C13.0778 46.5675 12.3484 45.8381 11.8989 44.9385C11.4092 43.9584 11.4092 42.6799 11.4092 40.1228V39.0632"
        stroke="#F2483E"
        strokeWidth="4.22179"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PersonIcon;
