import { AuthContextProvider } from "./context/AuthContext";
import { CreateJobPostContextProvider } from "./context/CreateJobPostContext";
import { NotificationProvider } from "./context/NotificationContext";
import { SubscriptionContextProvider } from "./context/SubscriptionContext";
import RouterSet from "./routers/RouterSet";

function App() {
  return (
    <NotificationProvider>
      <AuthContextProvider>
        <CreateJobPostContextProvider>
          <SubscriptionContextProvider>
            <RouterSet />
          </SubscriptionContextProvider>
        </CreateJobPostContextProvider>
      </AuthContextProvider>
    </NotificationProvider>
  );
}

export default App;
