import React from "react";

const UrgentIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_509_7951)">
        <path
          d="M3.48999 11.7201C3.48889 11.4578 3.38473 11.2064 3.19999 11.0201C3.10629 10.9321 2.99801 10.8611 2.87999 10.8101C2.75733 10.77 2.62905 10.7497 2.49999 10.7501H1.15999C0.90013 10.7002 0.631081 10.7553 0.411781 10.9034C0.192481 11.0515 0.0408089 11.2804 -0.0100098 11.5401V11.9401C0.0706293 12.1915 0.239764 12.4051 0.465981 12.5412C0.692197 12.6773 0.960121 12.7267 1.21999 12.6801C1.63999 12.6801 2.06999 12.6801 2.49999 12.6801C2.626 12.6836 2.75154 12.6633 2.86999 12.6201C2.98918 12.5714 3.09784 12.5001 3.18999 12.4101C3.28341 12.3208 3.35523 12.2114 3.39999 12.0901C3.45575 11.9745 3.48642 11.8484 3.48999 11.7201Z"
          fill="#2F2F2F"
        />
        <path
          d="M18.36 17.7801C18.15 17.7801 18.11 17.6901 18.12 17.5001C18.18 15.5001 18.23 13.5801 18.23 11.6201C18.2318 11.1323 18.1781 10.6458 18.07 10.1701C17.6909 8.56133 16.7347 7.14756 15.3827 6.1968C14.0307 5.24605 12.3768 4.82441 10.7346 5.0118C9.09245 5.1992 7.5761 5.98261 6.47305 7.21352C5.36999 8.44443 4.7569 10.0373 4.75 11.6901C4.75 13.6901 4.75 15.5801 4.86 17.5301C4.86 17.7101 4.86 17.7601 4.64 17.7801C2.46 17.7801 1.97 21.9401 5.09 21.9401C7.22 21.9401 17.18 21.9401 18.28 21.9401C20.85 21.9001 20.19 17.7801 18.36 17.7801ZM10.46 6.78011C11.1199 6.64541 11.8001 6.64541 12.46 6.78011C13.1163 6.91893 13.7405 7.18016 14.3 7.55011C14.8583 7.9267 15.3374 8.40914 15.71 8.97011C16.0765 9.5365 16.3311 10.1679 16.46 10.8301C16.4998 11.0647 16.5199 11.3022 16.52 11.5401C16.52 13.5401 16.47 15.4801 16.42 17.4401C16.42 17.6001 16.37 17.6401 16.21 17.6301C14.63 17.5301 13.06 17.4501 11.34 17.4601C9.91 17.4601 8.34 17.5401 6.76 17.6301C6.59 17.6301 6.56 17.6301 6.56 17.4401C6.51 15.5301 6.48 13.6301 6.46 11.7201C6.4505 10.5606 6.84429 9.43384 7.57397 8.53268C8.30366 7.63151 9.32388 7.01198 10.46 6.78011Z"
          fill="#2F2F2F"
        />
        <path
          d="M4.35001 5.92005C4.45385 6.03634 4.5894 6.11976 4.74001 6.16005C4.89076 6.19989 5.04927 6.19989 5.20001 6.16005C5.34871 6.14535 5.49223 6.09751 5.62001 6.02005C5.73743 5.91927 5.82985 5.79261 5.89001 5.65005C5.98746 5.48069 6.02282 5.28266 5.99001 5.09005C5.95852 4.90798 5.87115 4.74022 5.74001 4.61005C5.35334 4.21671 4.96668 3.83671 4.58001 3.47005C4.49546 3.38023 4.39335 3.30875 4.28001 3.26005C4.16266 3.212 4.0368 3.18819 3.91001 3.19005C3.78645 3.18814 3.66385 3.21198 3.55001 3.26005C3.30186 3.35241 3.10047 3.53941 2.99001 3.78005C2.96549 3.8988 2.96549 4.0213 2.99001 4.14005C2.9655 4.26216 2.9655 4.38793 2.99001 4.51005C3.03871 4.62338 3.11019 4.7255 3.20001 4.81005C3.60001 5.19005 3.99001 5.56005 4.35001 5.92005Z"
          fill="#2F2F2F"
        />
        <path
          d="M17.58 6.12993C17.7521 6.20803 17.9438 6.23243 18.13 6.19993C18.3164 6.16637 18.4877 6.07548 18.62 5.93993C19 5.57993 19.37 5.19993 19.74 4.81993C19.8422 4.73332 19.927 4.62813 19.99 4.50993C20.0098 4.38739 20.0098 4.26247 19.99 4.13993C20.0096 4.02073 20.0096 3.89913 19.99 3.77993C19.9402 3.6643 19.8689 3.55912 19.78 3.46993C19.6912 3.38302 19.5858 3.31498 19.47 3.26993C19.3546 3.22069 19.2305 3.19531 19.105 3.19531C18.9796 3.19531 18.8554 3.22069 18.74 3.26993C18.6242 3.31498 18.5188 3.38302 18.43 3.46993C18.06 3.82993 17.7 4.19993 17.34 4.56993C17.2433 4.66242 17.1682 4.7751 17.12 4.89993C17.0709 5.02725 17.0504 5.16381 17.06 5.29993C17.0607 5.476 17.1166 5.6474 17.22 5.78993C17.3054 5.93504 17.4303 6.05294 17.58 6.12993Z"
          fill="#2F2F2F"
        />
        <path
          d="M11.5 3.53002C11.6225 3.53177 11.7441 3.50875 11.8575 3.46236C11.971 3.41596 12.0738 3.34714 12.16 3.26002C12.339 3.09038 12.4433 2.85657 12.45 2.61002V1.02002C12.4518 0.895101 12.4279 0.771148 12.3797 0.655879C12.3315 0.54061 12.2602 0.436495 12.17 0.350018C12.0825 0.261126 11.9782 0.19053 11.8632 0.142341C11.7482 0.0941529 11.6247 0.0693359 11.5 0.0693359C11.3753 0.0693359 11.2518 0.0941529 11.1368 0.142341C11.0218 0.19053 10.9175 0.261126 10.83 0.350018C10.7398 0.436495 10.6684 0.54061 10.6203 0.655879C10.5721 0.771148 10.5482 0.895101 10.55 1.02002C10.55 1.56002 10.55 2.11002 10.55 2.65002C10.5566 2.89657 10.661 3.13038 10.84 3.30002C10.9294 3.37996 11.0338 3.44144 11.147 3.48091C11.2603 3.52038 11.3803 3.53707 11.5 3.53002Z"
          fill="#2F2F2F"
        />
        <path
          d="M22.06 10.77H20.42C20.1764 10.781 19.9453 10.8806 19.77 11.05C19.602 11.2294 19.5058 11.4643 19.5 11.71C19.4944 11.9541 19.5841 12.1908 19.75 12.37C19.9238 12.5431 20.1551 12.6463 20.4 12.66H22.08C22.3278 12.6497 22.5614 12.5418 22.73 12.36C22.815 12.2732 22.881 12.1698 22.9241 12.0562C22.9671 11.9426 22.9861 11.8213 22.98 11.7C22.9786 11.4563 22.8817 11.2229 22.71 11.05C22.5347 10.8806 22.3036 10.781 22.06 10.77Z"
          fill="#2F2F2F"
        />
        <path
          d="M11.48 12.8201C11.7452 12.8201 11.9996 12.7147 12.1871 12.5272C12.3747 12.3396 12.48 12.0853 12.48 11.8201V8.87006C12.517 8.7226 12.5198 8.56866 12.4883 8.41994C12.4569 8.27121 12.3919 8.13162 12.2984 8.01177C12.2049 7.89192 12.0853 7.79497 11.9487 7.72829C11.812 7.66161 11.662 7.62695 11.51 7.62695C11.358 7.62695 11.208 7.66161 11.0714 7.72829C10.9348 7.79497 10.8151 7.89192 10.7216 8.01177C10.6281 8.13162 10.5631 8.27121 10.5317 8.41994C10.5002 8.56866 10.5031 8.7226 10.54 8.87006V11.7801C10.5291 12.042 10.6214 12.2978 10.7973 12.4923C10.9731 12.6869 11.2183 12.8046 11.48 12.8201Z"
          stroke="black"
        />
        <path
          d="M11.49 16.8201C11.6412 16.8201 11.7909 16.7895 11.93 16.7301C12.0697 16.6786 12.1961 16.5966 12.3 16.4901C12.407 16.3807 12.4919 16.2517 12.55 16.1101C12.6015 15.9691 12.6286 15.8203 12.63 15.6701C12.6323 15.5219 12.6015 15.375 12.54 15.2401C12.4842 15.1003 12.3989 14.9741 12.29 14.8701C12.1334 14.7071 11.9307 14.5959 11.7091 14.5512C11.4875 14.5065 11.2576 14.5306 11.05 14.6201C10.9129 14.6809 10.7876 14.7656 10.68 14.8701C10.4743 15.0864 10.3567 15.3717 10.35 15.6701C10.3515 15.8203 10.3785 15.9691 10.43 16.1101C10.4881 16.2517 10.573 16.3807 10.68 16.4901C10.8989 16.6981 11.1881 16.8159 11.49 16.8201Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_509_7951">
          <rect
            width="22.98"
            height="21.94"
            fill="white"
            transform="translate(0 0.0300293)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UrgentIcon;
