import React from "react";

const SearchAI = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.52 9.57727C20.2176 9.57727 20.8307 10.0397 21.0224 10.7105L22.7166 16.6401C23.4581 19.2355 25.4868 21.2642 28.0822 22.0057L34.0118 23.6999C34.6826 23.8915 35.145 24.5046 35.145 25.2023C35.145 25.8999 34.6826 26.513 34.0118 26.7047L28.0822 28.3988C25.4868 29.1403 23.4581 31.1691 22.7166 33.7645L21.0224 39.694C20.8307 40.3648 20.2176 40.8273 19.52 40.8273C18.8224 40.8273 18.2093 40.3648 18.0176 39.694L16.3235 33.7645C15.5819 31.1691 13.5532 29.1403 10.9578 28.3988L5.02827 26.7047C4.35748 26.513 3.89502 25.8999 3.89502 25.2023C3.89502 24.5046 4.35748 23.8915 5.02827 23.6999L10.9578 22.0057C13.5532 21.2642 15.5819 19.2355 16.3235 16.6401L18.0176 10.7105C18.2093 10.0397 18.8224 9.57727 19.52 9.57727Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.27 3.32727C38.987 3.32727 39.612 3.81524 39.7859 4.51081L40.3252 6.66802C40.815 8.62739 42.3449 10.1573 44.3043 10.6471L46.4615 11.1864C47.1571 11.3603 47.645 11.9853 47.645 12.7023C47.645 13.4193 47.1571 14.0442 46.4615 14.2181L44.3043 14.7574C42.3449 15.2473 40.815 16.7771 40.3252 18.7365L39.7859 20.8937C39.612 21.5893 38.987 22.0773 38.27 22.0773C37.553 22.0773 36.9281 21.5893 36.7542 20.8937L36.2149 18.7365C35.725 16.7771 34.1951 15.2473 32.2358 14.7574L30.0786 14.2181C29.383 14.0442 28.895 13.4193 28.895 12.7023C28.895 11.9853 29.383 11.3603 30.0786 11.1864L32.2358 10.6471C34.1951 10.1573 35.725 8.62739 36.2149 6.66802L36.7542 4.51081C36.9281 3.81524 37.553 3.32727 38.27 3.32727Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.145 31.4523C35.8176 31.4523 36.4147 31.8826 36.6273 32.5207L37.4487 34.9847C37.7597 35.9178 38.492 36.6501 39.4251 36.9611L41.8891 37.7825C42.5272 37.9951 42.9575 38.5922 42.9575 39.2648C42.9575 39.9373 42.5272 40.5344 41.8891 40.7471L39.4251 41.5684C38.492 41.8795 37.7597 42.6117 37.4487 43.5449L36.6273 46.0089C36.4147 46.6469 35.8176 47.0773 35.145 47.0773C34.4725 47.0773 33.8754 46.6469 33.6627 46.0089L32.8414 43.5449C32.5303 42.6117 31.7981 41.8795 30.8649 41.5684L28.4009 40.7471C27.7629 40.5344 27.3325 39.9373 27.3325 39.2648C27.3325 38.5922 27.7629 37.9951 28.4009 37.7825L30.8649 36.9611C31.7981 36.6501 32.5303 35.9178 32.8414 34.9847L33.6627 32.5207C33.8754 31.8826 34.4725 31.4523 35.145 31.4523Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchAI;
