import axios from "axios";
import HeaderConfig from "../helpers/HeaderConfig";

const SubscriptionServices = () => {
  const { baseUrl } = HeaderConfig();

  const getSubscriptionDetails = async (config, data) => {
    return await axios.post(baseUrl + "subscription/status", data, config);
  };

  const getSubscriptionDetailsWeb = async (config, data) => {
    return await axios.post(baseUrl + "subscription/web/status", data, config);
  };

  const cancelSubscription = async (config, data) => {
    return await axios.post(baseUrl + "payment/unsubscribe", data, config);
  };

  const getKeysByPaymentInitiating = async (config, data) => {
    return await axios.post(baseUrl + "payment/initialize", data, config);
  };

  const getCheckoutPayment = async (config, data) => {
    return await axios.post(baseUrl + "payment/make", data, config);
  };

  return {
    getSubscriptionDetails,
    getSubscriptionDetailsWeb,
    cancelSubscription,
    getKeysByPaymentInitiating,
    getCheckoutPayment,
  };
};

export default SubscriptionServices;
