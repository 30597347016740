import React, { useEffect } from "react";
import { Button, Col, Row } from "antd";
import SuccessLady from "../../assets/img/successLady.webp";
import WorkDoneIcon from "../../assets/svg/WorkDoneIcon";
import DotIcon from "../../assets/svg/DotIcon";
import { useNavigate } from "react-router-dom";

const JobPostSuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const navigate = useNavigate();
  const appFeatures = [
    "Chat with taskers",
    "Create your favorite Workers list",
    "Get customized offers",
    "AI Search assistant & many more...",
    "Job complete & Review",
  ];
  return (
    <div className="flex w-full justify-center items-center">
      <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 rounded-lg overflow-hidden my-20">
        <div className="flex flex-col gap-5 justify-center items-center">
          <WorkDoneIcon />
          <p className="text-sm font-medium">Your job posted successfully!</p>
          {/* <Button
            type="primary"
            className="bg-primaryDark"
            onClick={() => {
              navigate("../my-jobs");
            }}
          >
            View in my Jobs
          </Button> */}
          <Button
            type="primary"
            htmlType="submit"
            className="bg-primaryDark"
            onClick={() => {
              navigate("../");
            }}
          >
            Back To Home
          </Button>
        </div>

        <Row gutter={32} className="mt-10">
          <Col
            span={24}
            sm={{ span: 12 }}
            className="flex justify-center sm:justify-end items-center mb-5"
          >
            <img src={SuccessLady} alt="Woman success" width={324} />
          </Col>

          <Col span={24} sm={{ span: 12 }}>
            <p className="text-base md:text-lg font-semibold">
              You can track and manage your posted jobs using the Job Ninja
              mobile app. Open the app to explore its advanced features.
            </p>

            <div className="mt-3">
              <p className="text-xs md:text-sm font-medium text-primaryColor">
                Exclusive on Job ninja app
              </p>

              <div className="flex flex-col mt-1">
                {appFeatures?.map((item) => {
                  return (
                    <p className="text-xs md:text-sm font-medium flex flex-row gap-2 justify-start items-center">
                      <DotIcon color="#2C8B52" width={8} />
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default JobPostSuccess;
