import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import PhoneNumberCard from "../sections/login/PhoneNumberCard";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import PinValidateCard from "../sections/login/PinValidateCard";

const Login = () => {
  let { authSteps, setAuthSteps } = useContext(AuthContext);
  const location = useLocation();
  const { role, type } = location.state;

  useEffect(() => {
    setAuthSteps(1);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <div className="content">
        {authSteps === 1 ? (
          <PhoneNumberCard role={role} type={type} />
        ) : (
          <PinValidateCard role={role} type={type} />
        )}
      </div>
    </div>
  );
};

export default Login;
