import React from "react";

const NavbarLogout = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0017 10C11.0138 7.82497 11.1103 6.64706 11.8787 5.87868C12.7574 5 14.1716 5 17 5H18C20.8284 5 22.2426 5 23.1213 5.87868C24 6.75736 24 8.17157 24 11V19C24 21.8284 24 23.2426 23.1213 24.1213C22.2426 25 20.8284 25 18 25H17C14.1716 25 12.7574 25 11.8787 24.1213C11.1103 23.3529 11.0138 22.175 11.0017 20"
        stroke="#707070"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11 22.5C8.64298 22.5 7.46447 22.5 6.73223 21.7678C6 21.0355 6 19.857 6 17.5V12.5C6 10.143 6 8.96447 6.73223 8.23223C7.46447 7.5 8.64298 7.5 11 7.5"
        stroke="#707070"
        stroke-width="1.5"
      />
      <path
        d="M18 15L9 15M9 15L11 17M9 15L11 13"
        stroke="#707070"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NavbarLogout;
