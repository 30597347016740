import React from "react";
import { Link } from "react-router-dom";

const CommonHeaderMenu = ({ menuItems, locationPathname }) => {
  return (
    <>
      {menuItems?.map((item, index) => {
        return (
          <Link to={item?.url} key={index}>
            <p
              className={`text-base  ${
                "/" + locationPathname.split("/")[1] === item?.url
                  ? "font-bold text-primaryColor"
                  : "font-normal"
              }`}
            >
              {item?.name}
            </p>
          </Link>
        );
      })}
    </>
  );
};

export default CommonHeaderMenu;
