import React from "react";

const NewWorkerIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54678 10.5501C5.71983 11.0398 5.30635 11.2847 5.00269 11.1109C4.69902 10.9372 4.70628 10.4599 4.72079 9.50537L4.72454 9.25841C4.72866 8.98716 4.73072 8.85153 4.68017 8.73234C4.62962 8.61316 4.53218 8.5239 4.3373 8.34539L4.15988 8.18287C3.47409 7.55468 3.1312 7.24059 3.21158 6.88787C3.29197 6.53515 3.74239 6.37741 4.64323 6.06191L4.87629 5.98029C5.13228 5.89063 5.26028 5.84581 5.35847 5.75593C5.45667 5.66606 5.51394 5.54131 5.62849 5.29182L5.73278 5.06469C6.13589 4.18672 6.33745 3.74773 6.69079 3.70348C7.04413 3.65922 7.31526 4.03901 7.8575 4.79857L7.99778 4.99508C8.15187 5.21092 8.22892 5.31885 8.34016 5.38249C8.45139 5.44613 8.58423 5.45829 8.84991 5.48261L9.09178 5.50475C10.0267 5.59032 10.4942 5.63311 10.6322 5.95847C10.7702 6.28384 10.4873 6.67631 9.92158 7.46124L9.77522 7.66431C9.61446 7.88736 9.53408 7.99889 9.50464 8.1281C9.47519 8.25731 9.50001 8.38957 9.54966 8.65408L9.59486 8.89491C9.76956 9.82576 9.85691 10.2912 9.58886 10.5365C9.3208 10.7819 8.87486 10.6446 7.98298 10.3702L7.75224 10.2992C7.4988 10.2212 7.37208 10.1822 7.24264 10.1984C7.11321 10.2146 6.99571 10.2842 6.76072 10.4234L6.54678 10.5501Z"
        fill="#E79721"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61624 13.8495C9.46003 14.0057 9.20677 14.0057 9.05056 13.8495L7.98389 12.7829C7.82768 12.6266 7.82768 12.3734 7.98389 12.2172C8.1401 12.061 8.39337 12.061 8.54957 12.2172L9.61624 13.2838C9.77245 13.44 9.77245 13.6933 9.61624 13.8495ZM12.5496 13.0495C12.3934 13.2057 12.1401 13.2057 11.9839 13.0495L10.6506 11.7162C10.4943 11.56 10.4943 11.3067 10.6506 11.1505C10.8068 10.9943 11.06 10.9943 11.2162 11.1505L12.5496 12.4838C12.7058 12.64 12.7058 12.8933 12.5496 13.0495ZM7.74957 11.9829C7.59336 12.1391 7.3401 12.1391 7.18389 11.9829L6.91722 11.7162C6.76101 11.56 6.76101 11.3067 6.91722 11.1505C7.07343 10.9943 7.3267 10.9943 7.48291 11.1505L7.74957 11.4172C7.90578 11.5734 7.90578 11.8266 7.74957 11.9829ZM13.6162 10.9162C13.46 11.0724 13.2068 11.0724 13.0506 10.9162L12.7839 10.6495C12.6277 10.4933 12.6277 10.24 12.7839 10.0838C12.9401 9.92762 13.1934 9.92762 13.3496 10.0838L13.6162 10.3505C13.7725 10.5067 13.7725 10.76 13.6162 10.9162ZM12.2829 9.58285C12.1267 9.73906 11.8734 9.73906 11.7172 9.58285L10.9172 8.78285C10.761 8.62664 10.761 8.37338 10.9172 8.21717C11.0734 8.06096 11.3267 8.06096 11.4829 8.21717L12.2829 9.01717C12.4391 9.17338 12.4391 9.42664 12.2829 9.58285Z"
        fill="#E79721"
      />
    </svg>
  );
};

export default NewWorkerIcon;
