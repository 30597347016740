export const getDateDifference = (dateValue) => {
  const ratedDate = new Date(dateValue);

  const today = new Date();

  const diffInMilliseconds = today - ratedDate;

  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  let output;
  if (diffInDays === 0) {
    output = "Today";
  } else if (diffInDays === 1) {
    output = "1 day ago";
  } else if (diffInDays > 1) {
    output = `${diffInDays} days ago`;
  } else {
    output = `${Math.abs(diffInDays)} days from now`;
  }

  return output;
};

export const getBillingDate = (dateValue) => {
  const today = new Date();

  today.setDate(today.getDate() + dateValue);

  const day = today.getDate();
  const suffix = getDaySuffix(day);

  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();

  return `${day}${suffix} ${month} ${year}`;
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
