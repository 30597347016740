import axios from "axios";
import HeaderConfig from "../helpers/HeaderConfig";

const LoginServices = () => {
  const { configHeaderForOTP, baseUrl, configHeaderDiff } = HeaderConfig();

  const getOtp = async (data, config) => {
    return await axios.post(baseUrl + "otp/firebase/send", data, config);
  };

  const pinValidate = async (data, config) => {
    return await axios.post(baseUrl + "auth/pin/verify", data, config);
  };

  const otpValidate = async (data) => {
    return await axios.post(
      baseUrl + "otp/firebase/validate",
      data,
      configHeaderDiff
    );
  };

  const getAllAuthUserData = async (config) => {
    return await axios.post(baseUrl + "auth/web/user", {}, config);
  };

  const userLogOut = async (data, config) => {
    return await axios.post(baseUrl + "logout", data, config);
  };

  return { getOtp, otpValidate, getAllAuthUserData, userLogOut, pinValidate };
};

export default LoginServices;
