import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 9.00016V10.0002C16.6668 13.1429 16.6668 14.7142 15.6905 15.6905C14.7142 16.6668 13.1429 16.6668 10.0002 16.6668C6.85747 16.6668 5.28612 16.6668 4.30981 15.6905C3.3335 14.7142 3.3335 13.1429 3.3335 10.0002C3.3335 6.85747 3.3335 5.28612 4.30981 4.30981C5.28612 3.3335 6.85747 3.3335 10.0002 3.3335H11.0002"
        stroke="#484848"
        strokeLinecap="round"
      />
      <path
        d="M13.1015 4.30354L13.5341 3.87099C14.2507 3.15433 15.4127 3.15433 16.1293 3.87099C16.846 4.58765 16.846 5.74959 16.1293 6.46625L15.6968 6.8988M13.1015 4.30354C13.1015 4.30354 13.1556 5.22269 13.9666 6.03371C14.7776 6.84473 15.6968 6.8988 15.6968 6.8988M13.1015 4.30354L9.12495 8.28012C8.8556 8.54946 8.72093 8.68413 8.60512 8.83262C8.46849 9.00778 8.35136 9.19731 8.25579 9.39784C8.17477 9.56784 8.11454 9.74852 7.99409 10.1099L7.60844 11.2668M15.6968 6.8988L11.7202 10.8754C11.4509 11.1447 11.3162 11.2794 11.1677 11.3952C10.9925 11.5318 10.803 11.649 10.6025 11.7445C10.4325 11.8256 10.2518 11.8858 9.89045 12.0062L8.7335 12.3919M8.7335 12.3919L7.98489 12.6414C7.80706 12.7007 7.611 12.6544 7.47846 12.5219C7.34591 12.3893 7.29963 12.1933 7.35891 12.0154L7.60844 11.2668M8.7335 12.3919L7.60844 11.2668"
        stroke="#484848"
      />
    </svg>
  );
};

export default EditIcon;
