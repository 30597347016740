import React, { useContext, useEffect, useState } from "react";
import { Select, Input, Button, Form } from "antd";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { NotificationContext } from "../../context/NotificationContext";
import HeaderConfig from "../../helpers/HeaderConfig";
const { TextArea } = Input;

const SupportForm = () => {
  let { openNotification, handleError } = useContext(NotificationContext);

  const { baseUrl } = HeaderConfig();

  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(null);
  const recaptchaRef = React.useRef();

  const [recapValue, setRecapValue] = useState(null);

  const options = [
    { value: 2, label: "Client" },
    { value: 6, label: "Worker" },
  ];

  const [supportForm] = Form.useForm();

  const handleSubmit = (e) => {
    const data = {
      support_title_id: e.topic,
      user_type_id: e.type,
      description: e.description,
      user_name: encryptSecureData(e.name),
      email_address: encryptSecureData(e.email),
    };

    axios
      .post(baseUrl + "support/add", data)
      .then((response) => {
        if (response.data.success) {
          supportForm.resetFields();
          setRecapValue(null);
          recaptchaRef.current.reset();
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    loadTopics();
  }, []);

  const loadTopics = () => {
    const data = {
      status: 1,
    };

    axios
      .post(baseUrl + "support/title/all", data)
      .then((response) => {
        if (response.data.success) {
          let array = [];

          response.data.output.map((topic) => {
            let obj = {
              value: topic.id,
              label: topic.title,
            };
            array.push(obj);
          });
          setTopics(array);
          setError(null);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const fieldValidateField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter your email!"));
    }

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return Promise.reject(new Error("Please enter valid email!"));
    }

    return Promise.resolve();
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-center items-center">
        <div className="xl:w-[1073px] flex flex-col justify-center items-center mx-5">
          <h1 className="w-full text-xl md:text-2xl font-medium text-center">
            Still Need Help?
          </h1>
          <p className="w-full text-base font-medium text-center">
            Submit your question and get help from us
          </p>
          <Form
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            form={supportForm}
            autoComplete="off"
            className="flex flex-col mt-5 w-full md:max-w-[600px]"
          >
            <div className="flex flex-col w-full">
              <label className="w-full">Select Topic *</label>
              <Form.Item
                name="topic"
                rules={[
                  {
                    required: true,
                    message: "Please select a topic!",
                  },
                ]}
                className="flex flex-col w-full"
              >
                <Select
                  className="w-full h-10"
                  placeholder="Select a Topic"
                  options={topics}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <label className="flex w-full">Select User Type *</label>
              <label className="w-full text-xs font-normal text-gray-500">
                Are you a Client or Worker ?
              </label>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select user type!",
                  },
                ]}
                className="flex flex-col w-full"
              >
                <Select
                  className="w-full h-10 mt-2"
                  placeholder="Select user type"
                  options={options}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <label className="w-full">Full Name *</label>
              <Form.Item
                name="name"
                className="flex flex-col w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
              >
                <Input className="w-full h-10" placeholder="Your Full Name" />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <label className="w-full">Contact Details *</label>
              <Form.Item
                name="email"
                required
                rules={[{ validator: fieldValidateField }]}
                className="flex flex-col w-full"
              >
                <Input
                  className="w-full h-10"
                  type="email"
                  placeholder="Your Email Address"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <label className="w-full">Write about your question *</label>
              <Form.Item
                name="description"
                className="flex flex-col w-full"
                rules={[
                  {
                    required: true,
                    message: "Please write your question!",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  className="w-full mt-2"
                  placeholder="Write about your question"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <ReCAPTCHA
                sitekey="6LfTsZ0pAAAAAAxMg1aJCfkAaRJmSfklH8xXWfL3"
                onChange={(e) => {
                  setRecapValue(e);
                }}
                className="flex justify-center"
                ref={recaptchaRef}
              />
            </div>

            <Form.Item className="flex flex-col w-full mt-4 text-center">
              <Button
                htmlType="submit"
                className="bg-black text-white h-10 text-base w-[70%]"
                disabled={!recapValue}
              >
                Submit Request
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SupportForm;
