import React from "react";
import {
  Layout,
  Input,
  Button,
  Avatar,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import NavBar from "../components/navbar/NavBar";
import SearchButton from "../assets/svg/SearchButton";
import LocationIconFull from "../assets/svg/LocationIconFull";
import KebabMenu from "../assets/svg/KebabMenu";
import NinjaGirl from "../assets/img/ninjaGirl.webp";
import ForClient from "../assets/img/forClient.webp";
import { useNavigate } from "react-router-dom";

const MyJobs = () => {
  const navigate = useNavigate();
  const jobData = [
    {
      id: "1234567899",
      title: "House cleaning",
      date: "24th Jan 2024",
      location: "Melbourne, Victoria. 30202",
      availableWorkers: 48,
      workers: [NinjaGirl, ForClient, NinjaGirl, ForClient, NinjaGirl],
    },
    {
      id: "1234567899",
      title: "House cleaning",
      date: "24th Jan 2024",
      location: "Melbourne, Victoria. 30202",
      availableWorkers: 32,
      workers: [NinjaGirl, ForClient, NinjaGirl, ForClient, NinjaGirl],
    },
    {
      id: "1234567899",
      title: "House cleaning",
      date: "24th Jan 2024",
      location: "Melbourne, Victoria. 30202",
      availableWorkers: 28,
      workers: [NinjaGirl, ForClient, NinjaGirl, ForClient, NinjaGirl],
    },
  ];

  return (
    <Layout>
      <NavBar />
      <div className="relative content overflow-hidden bg-white">
        <div className="mt-10 flex flex-col gap-8 md:gap-10 lg:gap-16">
          <div className="flex w-full justify-center items-center">
            <div className="max-w-[1073px] xl:w-[1073px] mx-5">
              <Row className="flex flex-row mb-5" gutter={32}>
                <Col span={12} className="flex flex-row gap-5">
                  <Input placeholder="Search Job..." />
                  <button>
                    <SearchButton />
                  </button>
                </Col>

                <Col span={6}>
                  <Select placeholder="All job types" className="w-full" />
                </Col>

                <Col span={6}>
                  <DatePicker className="w-full" />
                </Col>
              </Row>

              <div>
                {jobData.map((job, index) => (
                  <div
                    key={index}
                    className="border-[1px] rounded-lg p-2 mb-3 flex flex-row justify-between items-center gap-2"
                  >
                    <Row gutter={32} className="w-full">
                      <Col
                        span={24}
                        sm={{ span: 6 }}
                        lg={{ span: 10 }}
                        className="flex flex-col lg:flex-row gap-5 justify-between border-r-[1px]"
                      >
                        <div>
                          <p className="text-sm font-bold">Job ID : {job.id}</p>
                          <p className="text-xs font-medium">
                            Posted: {job.date}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-bold text-textColorThree">
                            {job.title}
                          </p>

                          <p className="flex flex-row gap-1 text-xs font-normal">
                            <LocationIconFull />
                            {job.location}
                          </p>
                        </div>
                      </Col>

                      <Col
                        span={24}
                        sm={{ span: 18 }}
                        lg={{ span: 14 }}
                        className="flex justify-center items-center"
                      >
                        <Row gutter={32}>
                          <Col
                            span={24}
                            md={{ span: 8 }}
                            className="flex flex-row gap-2 justify-start items-center"
                          >
                            <p className="text-4xl font-bold text-primaryColor">
                              {job.availableWorkers}
                            </p>
                            <p className="text-sm font-medium text-primaryColor">
                              Available workers for this job
                            </p>
                          </Col>

                          <Col
                            span={24}
                            md={{ span: 8 }}
                            className="flex md:justify-center items-center"
                          >
                            <Avatar.Group maxCount={5}>
                              {job.workers.map((worker, i) => (
                                <Avatar src={worker} key={i} />
                              ))}
                            </Avatar.Group>
                          </Col>

                          <Col
                            span={24}
                            md={{ span: 8 }}
                            className="flex flex-row gap-3 md:justify-end items-center"
                          >
                            <Button
                              type="primary"
                              className="bg-primaryDark mt-3 md:mt-0"
                              onClick={() => {
                                navigate("../my-jobs/worker-list");
                              }}
                            >
                              View all workers
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <KebabMenu />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyJobs;
