import { notification } from "antd";
import { createContext } from "react";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const placement = "top";

  const openNotification = (type, title, description) => {
    api[type]({
      message: title,
      description,
      placement,
    });
  };
  const handleError = (error) => {
    if (error.code === "ERR_NETWORK") {
      openNotification("error", "Network Error!", error.message);
    } else if (error.response) {
      if (error.response.status === 401) {
        openNotification("error", "Error!", error.response.data.message);
        // localStorage.clear();
        // window.location.replace("/login");
      } else if (error.response.status === 500) {
        openNotification("error", "Internal Server Error!", error.message);
      } else {
        openNotification("error", "Error!", error.message);
      }
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        openNotification,
        handleError,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
